import { Exclude, Transform, Type } from 'class-transformer'
import { BaseModelWrapper } from './BaseModel.model'
import { pickId } from './helpers'
import { User } from './User.model'

export type ArticleType = 'article' | 'tutorial'

export type ArticleParams = {
  page_number: number | string
  content_type?: ArticleType
  items_per_page?: number
}

export class Article extends BaseModelWrapper<Article>() {
  changed: Date
  created: Date
  contentType: ArticleType
  fullText = ''
  shortText = ''
  title = ''

  @Type(() => User)
  @Transform(pickId, { toPlainOnly: true })
  creatorAccount: User

  reset() {
    this.fullText = ''
    this.shortText = ''
    this.title = ''
  }

  @Exclude({ toPlainOnly: true })
  get isValid() {
    return Boolean(this.fullText && this.title && this.shortText)
  }
}
