
import { Article } from '@/core/models'
import { defineComponent, PropType } from '@vue/runtime-core'
import { ARTICLES_PAGE_SIZE, confirmDeletion } from '@/common/util'
import services from '@/core/services'

export default defineComponent({
  name: 'ArticlesList',
  props: {
    articles: {
      type: Array as PropType<Article[]>,
      required: true
    },
    totalArticlesCount: {
      type: Number,
      required: true
    },
    pageSize: {
      type: Number,
      default: ARTICLES_PAGE_SIZE
    },
    currentPage: {
      type: [Number, String],
      required: true
    }
  },

  emits: ['onPageChange', 'onDelete'],

  methods: {
    handlePageChange(page: number) {
      this.$router.push({ query: { ...this.$route.query, page } })
      this.$emit('onPageChange', page)
    },

    onDelete(id: string) {
      confirmDeletion(this.$t('messages.willPermanentlyDeleteArticle')).then(async () => {
        await services.articles.delete(id as string)
        this.$emit('onDelete', id)
      })
    },

    onEdit(id: string) {
      this.$router.push({ name: 'ArticleEdit', params: { id } })
    }
  }
})
