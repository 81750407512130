<template>
  <el-table
    :data="assets.tableData"
    style="width: 100%"
    show-summary
    :cell-class-name="({ rowIndex }) => (rowIndex === 0 ? 'customHeader' : '')"
    :span-method="customHeaderRowSpan"
    :summary-method="assets.summaryFn"
  >
    <el-table-column
      v-for="col in assets.columns"
      :key="col"
      :label="forFutureYearsPrependF(col)"
      :prop="col"
      :min-width="col === 'name' ? 200 : 0"
    />
  </el-table>

  <el-table
    :data="liabilities.tableData"
    style="width: 100%"
    show-summary
    :show-header="false"
    :cell-class-name="({ rowIndex }) => (rowIndex === 0 ? 'customHeader' : '')"
    :span-method="customHeaderRowSpan"
    :summary-method="liabilities.summaryFn"
  >
    <el-table-column
      v-for="col in liabilities.columns"
      :key="col"
      :label="forFutureYearsPrependF(col)"
      :prop="col"
      :min-width="col === 'name' ? 200 : 0"
    />
  </el-table>
</template>

<script lang="ts">
import { i18n } from '@/locales'
import services from '@/core/services'
import { pick } from 'lodash'
import { defineComponent } from 'vue'
import { getFutureYearLabel, forFutureYearsPrependF, normalizeTableData } from '../utils'

export default defineComponent({
  props: ['reports'],
  data() {
    return {
      assets: {
        tableData: [],
        columns: [],
        summaryFn: null
      },

      liabilities: {
        tableData: [],
        columns: [],
        summaryFn: null
      }
    }
  },

  async mounted() {
    const reports = this.reports

    this.setAssets(
      pick(reports, [
        'operatingCashNet',
        'accountsReceivable',
        'totalCurrentAssets',
        'landAndBuildings',
        'propertyPlantEquipmentNet',
        'totalAssets',
        'years'
      ])
    )

    this.setLiabilities(
      pick(reports, [
        'accountsPayable',
        'mortgage',
        'ownersEquity',
        'totalCurrentLiabilities',
        'totalLiabilities',
        'totalSeniorLiabilities',
        'totalLiabilitiesAndOwnersEquity',
        'years'
      ])
    )
  },

  methods: {
    getFutureYearLabel,

    setAssets(reports: { [reportName: string]: any[] }) {
      const { totalAssets, ...dataWithoutTolal } = reports

      const { tableData, columns } = normalizeTableData(dataWithoutTolal)

      const tableCustomHeader = { name: i18n.global.t('finances.assets') }
      dataWithoutTolal.years.forEach(year => {
        tableCustomHeader[year] = 0
      })

      this.assets = {
        summaryFn: () => [this.$t('finances.totalAssets')].concat(totalAssets),
        tableData: [tableCustomHeader, ...tableData],
        columns
      }
    },

    setLiabilities(reports: { [reportName: string]: any[] }) {
      const { totalLiabilitiesAndOwnersEquity, ...dataWithoutTolal } = reports

      const { tableData, columns } = normalizeTableData(dataWithoutTolal)

      const tableCustomHeader = { name: i18n.global.t('finances.totalLiabilitiesAndOwnersEquity') }
      dataWithoutTolal.years.forEach(year => {
        tableCustomHeader[year] = 0
      })

      this.liabilities = {
        summaryFn: () =>
          [this.$t('finances.totalLiabilitiesAndOwnersEquity')].concat(
            totalLiabilitiesAndOwnersEquity
          ),
        tableData: [tableCustomHeader, ...tableData],
        columns
      }
    },

    customHeaderRowSpan({ rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return columnIndex === 0 ? [1, this.assets.columns.length] : [0, 0]
      }
    },

    forFutureYearsPrependF
  }
})
</script>

<style lang="scss" scoped>
:deep(.customHeader) {
  position: sticky;
  left: 0;
  right: 0;
  text-align: center;
  background: #ffffff;
  font-weight: 450;
  font-size: 20px;
  line-height: 18px;

  &:hover {
    background: #ffffff !important;
  }
}
</style>
