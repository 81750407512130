import { Address } from '@/core/models'
import { isEmpty } from 'lodash'
import { useI18n } from 'vue-i18n'

export const getValidationRules = (model: Readonly<{ address: Address }>) => {
  const { t } = useI18n()

  return {
    region: [
      {
        required: !isEmpty(model.address?.region),
        validator: (rule, _value, callback) => {
          const error = t('validations.mustBeSelected', [t('common.region')])

          !isEmpty(model.address?.region) || !rule.required ? callback() : callback(error)
        }
      }
    ],
    city: [
      {
        required: !isEmpty(model.address?.city),
        validator: (rule, _value, callback) => {
          const error = t('validations.mustBeSelected', [t('common.city')])

          isEmpty(model.address?.city) && rule.required ? callback(error) : callback()
        }
      }
    ]
  }
}

export const getFormIsValid = (
  model: Readonly<{ address: Address }>,
  validationRules = getValidationRules(model)
): boolean => {
  if (isEmpty(model.address.country)) return true

  let formIsValid = true

  if (validationRules.region[0].required) {
    formIsValid = !isEmpty(model.address.region)
  }

  if (validationRules.city[0].required) {
    formIsValid = !isEmpty(model.address.city)
  }

  return formIsValid
}
