import { RouteRecordRaw } from 'vue-router'
import AddressBook from '@/web-app/pages/AddressBook/AddressBook.vue'

import List from '@/web-app/pages/AddressBook/List.vue'

import Item from '@/web-app/pages/AddressBook/Item/Item.vue'
import UserInfo from '@/web-app/pages/AddressBook/Item/UserInfo.vue'
import Invoices from '@/web-app/pages/AddressBook/Item/Invoices.vue'

export const routes: RouteRecordRaw[] = [
  {
    path: '/address-book',
    name: 'AddressBook',
    component: AddressBook,
    redirect: { name: 'AddressBookList' },
    children: [
      {
        path: 'list',
        name: 'AddressBookList',
        component: List
      },

      {
        path: 'contact/:id',
        name: 'AddressBookItem',
        props: true,
        component: Item,
        redirect: { name: 'AddressBookUserInfo' },
        children: [
          {
            path: 'user-info',
            name: 'AddressBookUserInfo',
            component: UserInfo
          },
          {
            path: 'invoices',
            name: 'AddressBookInvoices',
            component: Invoices
          }
        ]
      }
    ]
  }
]

export default routes
