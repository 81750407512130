<template>
  <div id="reports">
    <div class="flex-between mb-20">
      <div class="text-left">
        <template v-for="({ name, label, reportType }, $index) in routes" :key="name">
          <el-button
            :class="{ active: name === $route.name }"
            type="text"
            @click="
              () => {
                $router.push({ name, query: $route.query })
                getReports(reportType)
              }
            "
          >
            {{ label }}
          </el-button>
          <span v-if="$index !== routes.length - 1" class="ml-10 mr-10 main-color">|</span>
        </template>
      </div>
      <el-select
        clearable
        :model-value="estates.length ? $route.query.estate || selectedEstateId : null"
        :placeholder="$t('common.estate')"
        @change="onEstateSelect"
      >
        <el-option
          v-for="estate in estates"
          :key="estate.id"
          :label="estate.name"
          :value="estate.id"
        ></el-option>
      </el-select>
    </div>

    <el-table
      v-if="loading"
      v-loading="loading"
      style="height: 100px;"
      :empty-text="' '"
    ></el-table>
    <router-view v-else-if="!loading && reportsToShow" :reports="reportsToShow" />
  </div>
</template>

<script lang="ts">
import { Estate } from '@/core/models'
import services from '@/core/services'
import { find, get, set } from 'lodash'

import { defineComponent } from 'vue'

type Report = {
  [key: string]: any[]
}

type ReportsMap = Record<
  ReportType,
  {
    all: Report[]
    [estateId: string]: Report[]
  }
>

export default defineComponent({
  data() {
    return {
      loading: false,
      estates: [] as Estate[],
      reportsMap: {} as ReportsMap,
      reportsToShow: null as Report,
      selectedEstateId: null as string,
      routes: [
        {
          name: 'ReportsProfitAndLoss',
          reportType: 'profit_and_loss',
          label: this.$t('finances.profitLoss')
        },
        {
          name: 'ReportsBalance',
          reportType: 'balance_sheet',
          label: this.$t('finances.balanceSheet ')
        },
        {
          name: 'ReportsCashFlow',
          reportType: 'cash_flow',
          label: this.$t('finances.cashFlow')
        },
        {
          name: 'ReportsFreeCashFlow',
          reportType: 'fcf',
          label: this.$t('finances.FCF ')
        },
        {
          name: 'ReportsDiscountedCashFlow',
          reportType: 'dcf',
          label: this.$t('finances.DCF')
        }
      ]
    }
  },

  computed: {
    activeReportType(): string {
      const { reportType }: any = find(this.routes, { name: this.$route.name }) || {}
      return reportType
    }
  },

  async mounted() {
    services.users.getEstates().then(({ data }) => (this.estates = data))
    await this.getReports(this.activeReportType)
  },

  methods: {
    async getReports(reportType: string) {
      this.loading = true
      const path = `${reportType}.${this.selectedEstateId || 'all'}`
      let reports = get(this.reportsMap, path)

      if (reports) {
        this.reportsToShow = get(this.reportsMap, path)
      } else {
        this.reportsMap
        reports = await services.users.getReports({
          report: reportType as ReportType,
          estate_id: this.selectedEstateId
        })

        const newReports = set(this.reportsMap, path, reports)
        this.reportsToShow = get(newReports, path)
      }

      this.loading = false
    },

    onEstateSelect(estateId: string) {
      estateId
        ? this.$router.push({ query: { estate: estateId } })
        : this.$router.push({ query: {} })

      this.selectedEstateId = estateId || undefined

      this.getReports(this.activeReportType)
    }
  }
})
</script>

<style lang="scss">
#reports {
  .el-table .warning-row {
    background: oldlace;
  }

  .active {
    text-decoration: underline;
  }

  .el-table {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
  .tableText {
    font-weight: 450;
    font-size: 14px;
    line-height: 14px;
    text-align: start;
    color: rgba(0, 0, 0, 0.5);
  }
  .el-table {
    border-radius: 15px;
    margin-bottom: 15px;
  }

  :deep(.el-table__footer) {
    td {
      background: rgba(51, 70, 211, 0.25) !important;
    }
  }
  :deep(.el-table__header-wrapper) {
    th {
      background: rgba(0, 0, 0, 0.1) !important;
      font-weight: 450;
      color: #000000;
    }
  }
}
</style>
