<template>
  <nav class="navbar">
    <el-input
      v-model="query"
      class="searchInput"
      :placeholder="$t('form.typeToSearch')"
      prefix-icon="el-icon-fa-search fas"
    />
    <div class="menu">
      <div class="iconsGroup">
        <i
          v-for="{ iconClass } in sections"
          :key="iconClass"
          class="iconsGroup__item"
          :class="iconClass"
        />
      </div>
      <div class="userInfo">
        <el-dropdown>
          <span class="userInfo__avatar">
            <Avatar :size="46" :src="$store.getters.user.photo" />
            <i class="el-icon-fa-angle-down fas" />
          </span>
          <template #dropdown>
            <el-dropdown-menu class="userInfo__dropdown">
              <el-dropdown-item>{{ $store.getters.user.name }}</el-dropdown-item>
              <router-link :to="{ name: 'AccountDetails' }">
                <el-dropdown-item>
                  <i class="el-icon-fa-user fas" />
                  {{ $t('common.profile') }}
                </el-dropdown-item>
              </router-link>
              <router-link :to="{ name: 'AccountDetails' }">
                <el-dropdown-item>
                  <i class="el-icon-fa-language fas" />
                  {{ $t('header.changeLanguage') }}
                </el-dropdown-item>
              </router-link>
              <el-dropdown-item @click="$auth.signOut()">
                <i class="el-icon-fa-sign-out-alt fas" />
                {{ $t('header.logOut') }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent, ref } from '@vue/runtime-core'
import Avatar from '@/common/components/Avatar.vue'

export default defineComponent({
  components: { Avatar },
  setup() {
    const sections = [
      { iconClass: 'el-icon-fa-bell fas' },
      { iconClass: 'el-icon-fa-envelope fas has-notification' },
      { iconClass: 'el-icon-fa-home fas' },
      { iconClass: 'el-icon-fa-cog fas' }
    ]

    return {
      query: ref(''),
      sections
    }
  }
})
</script>

<style lang="scss" scoped>
.navbar {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.searchInput {
  flex: 1;

  :deep(.el-input__icon) {
    font-size: 24px;
  }

  :deep(input) {
    border: none;
    padding-left: 46px;
  }
}

.menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 400px;
}

.iconsGroup {
  display: flex;

  &__item {
    font-size: 24px;
    margin-right: 36px;
    color: #909399;
    cursor: pointer;
    position: relative;

    &:hover {
      opacity: 0.8;
    }

    &.has-notification {
      &::after {
        content: ' ';
        position: absolute;
        z-index: 1;
        top: -1px;
        right: -5px;
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #3346d3;
      }
    }
  }
}

.userInfo {
  height: 46px;
  cursor: pointer;

  &__avatar {
    align-items: center;
    display: flex;
  }

  &__dropdown {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    padding: 5px;
  }
}

.el-icon {
  &-fa-angle-down {
    margin-left: 15px;
  }
}
</style>
