<template>
  <div class="d-flex p-20">
    <div class="w-40">
      <div class="shadow">
        <AvatarWithUpload :src="contactClone.photo" @uploaded="onPhotoUpload" />
        <div class="mt-20 mb-15">
          <el-input v-model="contactClone.name" :placeholder="$t('common.name')" />

          <el-input v-model="contactClone.surname" class="mt-5" :placeholder="$t('form.surname')" />

          <el-input
            v-model="contactClone.description"
            :resize="'none'"
            type="textarea"
            class="mt-5"
            :placeholder="$t('common.description')"
          ></el-input>
        </div>
        <div class="mb-15">
          <el-rate v-model="contactClone.rate" />
        </div>
      </div>
      <div class="icons-group shadow">
        <div class="ml-20 mr-20">
          <div :title="$t('common.tenant')" class="mb-20">
            <i class="el-icon-fa-dollar-sign fas" />
          </div>
          <el-checkbox v-model="contactClone.isTenant" />
        </div>
        <div class="ml-20 mr-20 ">
          <div :title="$t('common.managingCompany')" class="mb-20">
            <i class="el-icon-fa-globe-americas fas" />
          </div>
          <el-checkbox v-model="contactClone.isManagingCompany" />
        </div>
        <div class="ml-20 mr-20">
          <div :title="$t('common.vendor')" class="mb-20">
            <i class="fas el-icon-fa-wrench" />
          </div>
          <el-checkbox v-model="contactClone.isVendor" />
        </div>
      </div>
    </div>

    <div class="contact-info">
      <el-form class="ml-20">
        <el-form-item :label="$t('common.isCompany')" class="switcher">
          <el-switch
            v-model="contactClone.isCompany"
            @change="contactClone.companyName = ''"
          ></el-switch>
        </el-form-item>

        <el-form-item v-if="contactClone.isCompany" :label="$t('form.companyName')">
          <el-input v-model="contactClone.companyName" :placeholder="$t('form.companyName')" />
        </el-form-item>

        <el-form-item :label="$t('form.email')">
          <el-input v-model="contactClone.email" :placeholder="$t('form.email')" />
        </el-form-item>

        <AddressForm v-model="contactClone.address" />
      </el-form>
    </div>
  </div>
  <div class="flex-between mt-20">
    <el-button type="danger" @click="onDeleteContact">
      {{ $t('common.deleteContact') }}
    </el-button>

    <el-button type="primary" @click="onSave"> {{ $t('common.save') }} </el-button>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import AddressForm from '@/common/components/AddressForm.vue'
import AvatarWithUpload from '@/common/components/AvatarWithUpload.vue'
import services from '@/core/services'
import { omit } from 'lodash'
import { confirmDeletion } from '@/common/util'
import { Contact } from '@/core/models'

export default defineComponent({
  components: { AddressForm, AvatarWithUpload },
  props: {
    contact: { type: Object as PropType<Contact>, required: true }
  },

  emits: ['onShouldChangeTab', 'reFetch'],

  data() {
    return {
      uploadedAvatar: null as null | IUploadedFile,
      contactClone: (this.contact as Contact).clone()
    }
  },

  methods: {
    async onSave() {
      const payload = omit(this.contactClone.transformToDbFormat(), 'photo') as Contact
      const { address } = payload

      if (this.uploadedAvatar) {
        payload.photo = await services.awsImageUploader.upload(
          this.uploadedAvatar.name,
          this.uploadedAvatar.raw
        )
      }

      await services.contacts.update(this.contact['id'], {
        ...payload,
        address
      })

      this.$emit('reFetch')
    },

    async onPhotoUpload(file: IUploadedFile) {
      this.contactClone.photo = file.url
      this.uploadedAvatar = {
        ...file,
        name: `${this.contact['id']}/${file.name}`
      }
    },

    async onDeleteContact() {
      try {
        await confirmDeletion(this.$t('messages.willPermanentlyDeleteContact'))
        await services.contacts.delete(this.contact['id'])
        this.$router.push({ name: 'AddressBookList' })
      } catch (error) {
        /*  */
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.shadow {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 20px;
}

.icons-group {
  display: flex;
  justify-content: center;
  margin-top: 40px;

  i[class*='el-icon'] {
    font-size: 30px;
  }

  .el-icon-fa-dollar-sign {
    color: green;
  }

  .el-icon-fa-globe-americas {
    color: rgb(0, 183, 255);
  }

  .el-icon-fa-wrench {
    color: rgb(160, 129, 218);
  }
}

.contact-info {
  flex-grow: 1;
}

.switcher {
  :deep(.el-form-item__content) {
    width: fit-content !important;
  }
}
:deep(.el-form-item__content) {
  margin-left: 30% !important;
}
</style>
