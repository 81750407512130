import AccountsEntity from './AccountsEntity'
import EstatesEntity from './EstatesEntity'
import ManagingCompaniesEntity from './ManagingCompaniesEntity'
import InvoicesEntity from './InvoicesEntity'
import ContactsEntity from './ContactsEntity'
import apiConfig from './apiConfig.json'
import { CRUD } from './CRUD'
import {
  PaidPlan,
  Group,
  City,
  Cost,
  Country,
  Currency,
  Language,
  Region,
  Article,
  Invoice,
  AdminInvoice
} from '@/core/models'

export const BASE_URL = apiConfig.host + '/api/private/'

export default {
  accounts: new AccountsEntity(BASE_URL + 'account'),
  estates: new EstatesEntity(BASE_URL + 'estate'),
  managingCompanies: new ManagingCompaniesEntity(BASE_URL + 'managing_company'),
  invoices: new InvoicesEntity<Invoice>(BASE_URL + 'invoice', Invoice),
  adminInvoices: new InvoicesEntity<AdminInvoice>(BASE_URL + 'invoice_internal', AdminInvoice),
  contacts: new ContactsEntity(BASE_URL + 'contact'),
  articles: new CRUD<Article, Paginated<Article[]>>(BASE_URL + 'article', Article),
  paidPlans: new CRUD<PaidPlan>(BASE_URL + 'paid_plan', PaidPlan),
  groups: new CRUD<Group>(BASE_URL + 'group', Group),
  colorThemes: new CRUD<any>(BASE_URL + 'color_theme'),
  currencies: new CRUD<Currency>(BASE_URL + 'currency', Currency),
  languages: new CRUD<Language>(BASE_URL + 'language', Language),
  countries: new CRUD<Country, Paginated<Country[]>>(BASE_URL + 'country'),
  cities: new CRUD<City, Paginated<City[]>>(BASE_URL + 'city'),
  regions: new CRUD<Region, Paginated<Region[]>>(BASE_URL + 'region'),
  costs: new CRUD<Cost, Paginated<Cost[]>>(BASE_URL + 'cost', Cost)
}
