<template>
  <div id="dcf">
    <el-table :data="mainTable" style="width: 100%">
      <el-table-column prop="years" label="Year"> </el-table-column>

      <el-table-column prop="nopat" :label="$t('finances.nopat')">
        <template #default="{row}">
          <el-popover
            v-if="row.nopat?.hint"
            placement="top-start"
            :width="300"
            trigger="hover"
            :content="row.nopat?.hint"
          >
            <template #reference>
              <span class="pointer">{{ row.nopat?.value }} <i class="el-icon-info"> </i></span>
            </template>
          </el-popover>
          <span v-else>{{ row.nopat?.value }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="investment" :label="$t('finances.investment')"> </el-table-column>

      <el-table-column prop="fcf" :label="$t('finances.fcfNopat')"> </el-table-column>

      <el-table-column prop="pvFactor">
        <template #header>
          <el-popover
            placement="top-start"
            :width="300"
            trigger="hover"
            :content="$t('messages.cashFlowsDiscountedFromMidYear')"
          >
            <template #reference>
              <span class="pointer"
                >{{ $t('finances.pvFactor') }} <i class="el-icon-info"></i
              ></span>
            </template>
          </el-popover>
        </template>
        <template #default="{row}">
          <el-popover
            v-if="row.pvFactor?.hint"
            placement="top-start"
            :width="300"
            trigger="hover"
            :content="row.pvFactor?.hint"
          >
            <template #reference>
              <span class="pointer">{{ row.pvFactor?.value }} <i class="el-icon-info"> </i></span>
            </template>
          </el-popover>
          <span v-else>{{ row.pvFactor?.value }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="presentValueOfFcf" :label="$t('finances.presentValueOfFcf')">
        <template #default="{row}">
          <el-popover
            v-if="row.presentValueOfFcf?.hint"
            placement="top-start"
            :width="200"
            trigger="hover"
            :content="row.presentValueOfFcf?.hint"
          >
            <template #reference>
              <span class="pointer"
                >{{ row.presentValueOfFcf?.value }} <i class="el-icon-info"> </i
              ></span>
            </template>
          </el-popover>
          <span v-else>{{ row.presentValueOfFcf?.value }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="pl-30 pr-30 mt-40">
      <div class="tableBoxes">
        <el-table show-summary :summary-method="amountTableSummaryFn" :data="amountTable">
          <el-table-column prop="name" :label="$t('finances.wacc')"> </el-table-column>
          <el-table-column prop="amount" :label="$t('finances.amount')"> </el-table-column>
          <el-table-column :label="$t('finances.costOfCapital')">
            <template #default="{row}">{{ row.costOfCapital }} %</template>
          </el-table-column>
        </el-table>

        <div class="ml-20 mr-20"></div>

        <el-table :data="scenarioTable">
          <el-table-column prop="scenarioWacc" :label="$t('finances.scenarioWacc')" />
          <el-table-column prop="intrinsicValue" :label="$t('finances.intrinsicValue')" />
        </el-table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { i18n } from '@/locales'
import services from '@/core/services'
import { each, pick, set } from 'lodash'
import { defineComponent } from 'vue'
import { forFutureYearsPrependF, REPORTS_YEARS_RANGE } from '../utils'

export default defineComponent({
  props: ['reports'],
  data() {
    return {
      mainTable: [],
      amountTable: [],
      amountTableSummaryFn: null,
      scenarioTable: []
    }
  },

  async mounted() {
    const t = i18n.global.t
    const reports = this.reports

    this.mainTable = this.getMainTableData(reports)

    this.scenarioTable = reports.scenarioWacc.map((amount, index) => {
      return {
        scenarioWacc: amount + ' %',
        intrinsicValue: reports.intrinsicValue[index]
      }
    })

    this.amountTable = [
      { ...reports.mortgage, name: t(`finances.mortgage`) },
      { ...reports.equity, name: t(`finances.equity`) },
      { costOfCapital: reports.totalWacc, name: t(`finances.totalWacc`) }
    ]
    this.amountTableSummaryFn = () => [t('common.round'), '', Math.round(reports.totalWacc) + ' %']
  },

  methods: {
    getMainTableData(reports: Readonly<{ [key: string]: any }>) {
      const table: any[] = []
      const yearsCount = reports.years.length

      const partialReports = pick(reports, [
        'years',
        'nopat',
        'investment',
        'fcf',
        'pvFactor',
        'presentValueOfFcf'
      ])

      each(partialReports, (colValuesList, colName) => {
        for (let i = 0; i < yearsCount; i++) {
          const value = colValuesList[i]

          if (colName === 'years') {
            set(table, `${i}.${colName}`, forFutureYearsPrependF(value))
          } else if (colName === 'pvFactor') {
            const data = {
              value,
              hint: this.isLastYear(partialReports.years[i])
                ? i18n.global.t('messages.presentValueOfEuroInPerpetuityBeginningIn2026f')
                : null
            }

            set(table, `${i}.${colName}`, data)
          } else if (colName === 'presentValueOfFcf') {
            const data = {
              value,
              hint: this.isLastYear(partialReports.years[i])
                ? i18n.global.t('messages.infiniteGrowthOfFcfAfterYear2025fIs2')
                : null
            }

            set(table, `${i}.${colName}`, data)
          } else if (colName === 'nopat') {
            const data = {
              value,
              hint: this.isLastYear(partialReports.years[i])
                ? i18n.global.t(
                    'messages.nopatIncreasesBy348BasedOnReturnOf419On2025fInvestmentOf83215'
                  )
                : null
            }

            set(table, `${i}.${colName}`, data)
          } else {
            set(table, `${i}.${colName}`, value)
          }
        }
      })
      table.push(
        {
          years: i18n.global.t('finances.intrinsicTotalValue'),
          presentValueOfFcf: { value: reports.intrinsicTotalValue }
        },
        {
          years: i18n.global.t('finances.intrinsicCommonEquityValue'),
          presentValueOfFcf: { value: reports.intrinsicCommonEquityValue }
        },
        {
          years: i18n.global.t('finances.numberOfSharesOutstanding'),
          presentValueOfFcf: { value: reports.numberOfSharesOutstanding }
        },
        {
          years: i18n.global.t('finances.intrinsicShareValue'),
          presentValueOfFcf: { value: reports.intrinsicShareValue }
        }
      )

      return table
    },

    isLastYear(year) {
      const lastFutureYear = new Date().getFullYear() - 1 + REPORTS_YEARS_RANGE
      year = parseInt(year)

      return typeof +year === 'number' && !isNaN(+year) && +year === lastFutureYear
    }
  }
})
</script>

<style lang="scss" scoped>
.tableBoxes {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 15px;

  .second {
    margin-left: 30px;
  }
}

@media (max-width: 1280px) {
  .tableBoxes {
    flex-direction: column;
    align-items: center;
  }
  :deep(.tableBoxes) {
    .el-table {
      width: auto !important;
    }
  }
}

.before {
  background: red;

  cursor: pointer;
}
</style>
