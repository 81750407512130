
import { defineComponent } from 'vue'
import DocumentPreviewSkeleton from './DocumentPreviewSkeleton.vue'

export default defineComponent({
  name: 'DocumentPreviewItemsList',
  components: { DocumentPreviewSkeleton },
  props: {
    header: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    pageSize: {
      type: Number,
      default: 4
    },
    totalDocumentsCount: {
      type: Number,
      default: 0
    }
  },
  emits: ['checked', 'click', 'onPageChange', 'onCreate']
})
