
import { Invoice } from '@/core/models'
import services from '@/core/services'
import { find, last } from 'lodash'
import { defineComponent } from 'vue'
import Document from '@/common/components/Accounting/Document.vue'
import DocumentPreviewItem from '@/common/components/Accounting/DocumentPreviewItem.vue'
import DocumentSkeleton from '@/common/components/Accounting/DocumentSkeleton.vue'
import api from '@/core/api'
import Toolbar from '@/common/components/Accounting/Toolbar.vue'
import DocumentPreviewItemsList from '@/common/components/Accounting/DocumentPreviewItemsList.vue'
import { download } from '@/common/util'

export default defineComponent({
  components: {
    Document,
    DocumentPreviewItem,
    DocumentSkeleton,
    Toolbar,
    DocumentPreviewItemsList
  },
  data() {
    return {
      loading: null,
      totalInvociesCount: null,
      PAGE_SIZE: 4,
      invoices: [] as Invoice[],
      selectedInvoice: null as Invoice
    }
  },

  async mounted() {
    await this.getInvoices()
  },

  methods: {
    async getInvoices(page = 1) {
      this.loading = true
      const { data, metadata } = await services.users.getInvoices({
        page_number: page,
        items_per_page: this.PAGE_SIZE
      })
      this.loading = false

      this.totalInvociesCount = metadata.count
      this.invoices = data

      this.selectInvoice(last(this.invoices)?.id)
    },

    selectInvoice(id: string) {
      if (id) {
        this.selectedInvoice = find(this.invoices, { id })
      }
    },

    goToEditInvoicePage() {
      if (this.selectedInvoice?.id) {
        this.$router.push({
          name: 'AccountingInvoiceEdit',
          params: { id: this.selectedInvoice.id }
        })
      }
    },

    async downloadPdf() {
      if (this.selectedInvoice?.id) {
        const url = await api.invoices.getPdfUrl(this.selectedInvoice.id)

        download(url, `invoice-${this.selectedInvoice.details.number}`)
      }
    }
  }
})
