import { Contact, Invoice } from '@/core/models'
import api from '.'
import { CRUD } from './CRUD'
import http from './http'

export type ContactType = 'vendor' | 'tenant' | 'managing_company' | 'company'
type ContactSortOption = 'name' | 'surname' | 'company_name' | 'rate'

export interface ContactGetParams extends IPagination {
  type?: ContactType
  sort?: ContactSortOption | ContactSortOption[]
}

export default class ContactsEntity extends CRUD<Contact, Paginated<Contact[]>> {
  protected Model = Contact

  getManagingCompanies() {
    return api.managingCompanies.get()
  }

  getInvoices(contactId: string) {
    return http
      .get(`${this.url}/${contactId}/invoice`)
      .then(this.createModelInstance<Paginated<Invoice[]>>(Invoice))
  }
}
