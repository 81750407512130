import { nextTick, onMounted, onUnmounted, ref } from 'vue'

interface IOptions {
  durationMs: number
  events?: (keyof WindowEventMap)[]
  onCountEnd: () => unknown
}

export const useIdle = ({
  durationMs,
  events = ['mousemove', 'keypress', 'click', 'scroll'],
  onCountEnd
}: IOptions) => {
  const countEndIntervalId = ref<number>()
  const start = ref(0)

  function setTimer() {
    countEndIntervalId.value = window.setInterval(onCountEnd, durationMs)
  }

  function clearTimer() {
    clearInterval(countEndIntervalId.value)
    start.value = Date.now()
    setTimer()
  }

  onMounted(() => {
    start.value = Date.now()
    nextTick(() => {
      setTimer()
      events.forEach(event => {
        window.addEventListener(event, clearTimer)
      })
    })
  })

  onUnmounted(() => {
    clearInterval(countEndIntervalId.value)
    events.forEach(event => {
      window.removeEventListener(event, clearTimer)
    })
  })
}
