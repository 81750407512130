<template>
  <div
    :style="{ width: `${size}px`, height: `${size}px` }"
    :class="`position-relative ${$props.containerClass}`"
  >
    <BaseChart
      :type="'doughnut'"
      :data="$props.data"
      :options="{
        cutoutPercentage: $props.cutoutPercentage,
        legend: { display: false }
      }"
    />
    <div class="absolute-center text-center">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import BaseChart from '@/common/components/Chart.vue'
import Chart from 'chart.js'

export default defineComponent({
  components: { BaseChart },
  props: {
    size: { type: Number, default: 160 },
    cutoutPercentage: { type: Number, default: 55 },
    containerClass: { type: String, default: '' },
    data: {
      type: Object as PropType<Chart.ChartData>,
      required: true
    }
  }
})
</script>
