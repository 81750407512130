<template>
  <div class="container">
    <div class="accounting-charts">
      <div
        v-for="{ label, data, percentage, amount } in accountingData"
        :key="label"
        class="accounting-charts__card"
      >
        <el-card :header="label">
          <div class="card__content">
            <div class="card__amount">
              <i class=" el-icon-fa-euro-sign fas" /> <b>{{ amount }}</b>
            </div>
            <div class="card__chart">
              <DoughnutChart container-class="resizable" :data="data" :cutout-percentage="75">
                <b>{{ percentage }}</b>
              </DoughnutChart>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import DoughnutChart from '@/common/components/DoughnutChart.vue'

export default defineComponent({
  name: 'Dashboard',
  components: { DoughnutChart },
  data() {
    const accountingData = [
      {
        label: this.$t('common.revenue'),
        percentage: '+20%',
        amount: 5000,
        data: {
          labels: ['label1, label2'],
          datasets: [
            {
              data: [20, 80],
              backgroundColor: ['#3347d3', '#cdd2f4']
            }
          ]
        }
      },
      {
        label: this.$t('common.cost'),
        percentage: '+5%',
        amount: 3200,
        data: {
          labels: ['label1, label2'],
          datasets: [
            {
              data: [5, 95],
              backgroundColor: ['#05af34', '#c0ebcd']
            }
          ]
        }
      },
      {
        label: this.$t('common.profit'),
        percentage: '+10%',
        amount: 1800,
        data: {
          labels: ['label1, label2'],
          datasets: [
            {
              data: [10, 90],
              backgroundColor: ['#fe0202', '#ffbfbf']
            }
          ]
        }
      }
    ]

    return { accountingData }
  }
})
</script>

<style lang="scss" scoped>
.accounting-charts {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &__card {
    width: 32%;
  }
}

.card {
  &__content {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}

@media (max-width: 1400px) {
  .resizable {
    width: 120px !important;
    height: 120px !important;
  }
}
</style>
