import 'reflect-metadata'
import { createApp } from 'vue'
import App from './App.vue'
import webAppRouter from './web-app/router'
import adminRouter from '@/admin/routes'
import store from './core/store'
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import { i18n, setLocale } from './locales'
import client from '@/core/auth'
import services from '@/core/services'
import { formatters } from './common/plugins'
import moment from 'moment'
import { CalculatorService } from './core/services/CalculatorService'
import { DATE_FORMAT } from './common/util'

const IS_ADMIN_APP_FOR_DEVELOPMENT =
  !!new URLSearchParams(location.search).get('admin') && process.env.NODE_ENV === 'development'

export const app = createApp(App)

app.use(client.authPlugin)
app.use(store)

app.config.errorHandler = function(err, vm, info) {
  console.group(info)
  console.error(err)
  console.log({ vm })
  console.groupEnd()
}

async function initializeApp() {
  await services.auth.setHeaders()
  await client.auth.init()

  if (!store.getters.user.id) {
    return
  }

  setLocale(store.getters.user.language.short)
}

initializeApp()
  .then(() => {
    if (
      (location.host.includes('admin.') || IS_ADMIN_APP_FOR_DEVELOPMENT) &&
      !store.getters.user?.isAdmin
    ) {
      const webAppUrl = location.href.replace('admin', 'app')

      return location.replace(webAppUrl)
    }
  })
  .finally(() => {
    app.use(getRoutes())
    app.use(i18n)
    app.use(ElementPlus)
    app.use(formatters)

    app.mount('#app')

    app.config.globalProperties.$moment = moment
    app.config.globalProperties.$calculator = new CalculatorService()
    app.config.globalProperties.$DATE_FORMAT = DATE_FORMAT
  })

function getRoutes() {
  if (IS_ADMIN_APP_FOR_DEVELOPMENT || location.host.includes('admin.')) {
    store.commit('set', { isWebApp: false })
    return adminRouter
  } else return webAppRouter
}
