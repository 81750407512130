<template>
  <div class="form">
    <div class="form-left">
      <div class="inputField">
        <span class="label">{{ $t('common.company') }}</span>
        <el-select
          :model-value="newFee?.managingCompany?.getFullName()"
          :disabled="!managingCompanyContacts.length"
          filterable
          :placeholder="$t('common.company')"
          @change="
            $event =>
              (newFee.managingCompany = managingCompanyContacts.find(({ id }) => id === $event))
          "
        >
          <el-option
            v-for="company in managingCompanyContacts"
            :key="company.id"
            :value="company.id"
            :label="company.getFullName()"
          />
        </el-select>
      </div>
      <div class="inputField">
        <span class="label">{{ $t('finances.remuneration') }}</span>
        <el-input
          :model-value="newFee.remuneration"
          type="number"
          :placeholder="$t('finances.remuneration')"
          @input="onInputRemuneration"
        />
      </div>
    </div>
    <div class="form-right">
      <div class="inputField">
        <span class="label">{{ $t('common.from') }}</span>
        <DatePicker
          v-model="newFee.start"
          :placeholder="$t('common.pickDay')"
          @change="ensureEndDateToBeAfterStart"
        />
      </div>
      <div class="inputField">
        <span class="label">{{ $t('common.to') }}</span>
        <DatePicker
          v-model="newFee.end"
          :disabled="!newFee.start"
          :disabled-date="disableDates"
          :placeholder="$t('common.pickDay')"
        />
      </div>
    </div>
  </div>
  <div class="form-footer">
    <el-button :disabled="!newFee.isValid" type="primary" @click="onSave">
      {{ $t('common.save') }}
    </el-button>
  </div>

  <el-table :data="managingCompanyFees" style="width: 100%">
    <el-table-column width="250" :label="$t('common.name')">
      <template #default="{row}">
        {{ row?.managingCompany?.getFullName() }}
      </template>
    </el-table-column>

    <el-table-column :label="$t('common.from')">
      <template #default="{row}">
        {{ $moment(row.start).format($DATE_FORMAT) }}
      </template>
    </el-table-column>

    <el-table-column :label="$t('common.till')">
      <template #default="{row}">
        {{ $moment(row.end).format($DATE_FORMAT) }}
      </template>
    </el-table-column>

    <el-table-column :label="$t('finances.remuneration')">
      <template #default="{row}">{{ row.remuneration }}%</template>
    </el-table-column>

    <el-table-column width="40">
      <template #default="{ row: fee }">
        <i class="el-icon-fa-trash-alt fas" @click.stop="removeFee(fee.id)" />
      </template>
    </el-table-column>
  </el-table>
</template>

<script lang="ts">
import DatePicker from '@/common/components/DatePicker.vue'
import { useTabChangeConfirm } from '@/common/hooks'
import { defineComponent, PropType } from 'vue'
import moment from 'moment'
import services from '@/core/services'
import { Contact, Estate, ManagingCompanyFee } from '@/core/models'
import { confirmDeletion } from '@/common/util'
import { reject } from 'lodash'

export default defineComponent({
  components: {
    DatePicker
  },
  props: {
    estate: { type: Object as PropType<Estate>, required: true }
  },
  emits: ['onShouldChangeTab', 'reFetch'],

  data() {
    return {
      newFee: ManagingCompanyFee.create({}),

      managingCompanyContacts: [] as Contact[],
      managingCompanyFees: [...this.$props.estate['managingCompanyFees']]
    }
  },

  async mounted() {
    const { data } = await services.users.getContacts({
      type: 'managing_company'
    })
    this.managingCompanyContacts = data
  },

  async created() {
    useTabChangeConfirm({
      shouldConfirm: () => false,
      onNoConfirm: () => this.$emit('onShouldChangeTab', true),
      onConfirm: () => {
        this.onSave()
        this.$emit('onShouldChangeTab', true)
      },
      onCancel: () => this.$emit('onShouldChangeTab', true)
    })
  },

  methods: {
    async onSave() {
      const payload = this.newFee.transformToDbFormat()

      const { id } = await services.estates.feeCreate(this.estate['id'], payload)

      this.$emit('reFetch')

      this.newFee.id = id
      this.managingCompanyFees.push(this.newFee)

      this.newFee = ManagingCompanyFee.create({})
    },

    async removeFee(id: string) {
      await confirmDeletion(this.$t('messages.willPermanentlyDeleteFee'))

      await services.estates.feeRemove(id, this.estate['id'])
      this.managingCompanyFees = reject(this.managingCompanyFees, { id })
    },

    disableDates(date: string) {
      return moment(date).isSameOrBefore(this.newFee.start)
    },

    ensureEndDateToBeAfterStart(startDate: string) {
      if (this.newFee.end && moment(startDate).isSameOrAfter(this.newFee.end)) {
        this.newFee.end = ''
      }
    },

    onInputRemuneration(value: string) {
      if (+value > 100) {
        return
      }
      this.newFee.remuneration = +value
    }
  }
})
</script>

<style lang="scss" scoped>
.form {
  color: #909399;
  display: flex;
  padding: 25px 0;

  &-left,
  &-right {
    flex: 1;
  }

  .el-select {
    flex: 1;
  }
}

.form-footer {
  padding: 0 30px;
  text-align: left;
}

.el-table {
  margin-top: 30px;
}
</style>
