
import { Cost } from '@/core/models'
import services from '@/core/services'
import { find, last } from 'lodash'
import { defineComponent } from 'vue'
import Document from '@/common/components/Accounting/Document.vue'
import DocumentPreview from '@/common/components/Accounting/DocumentPreviewItem.vue'
import DocumentSkeleton from '@/common/components/Accounting/DocumentSkeleton.vue'
import DocumentPreviewSkeleton from '@/common/components/Accounting/DocumentPreviewSkeleton.vue'

export default defineComponent({
  components: { Document, DocumentPreview, DocumentSkeleton, DocumentPreviewSkeleton },
  data() {
    return {
      loading: true,
      totalCostsCount: null,
      PAGE_SIZE: 4,
      costs: [] as Cost[],
      selectedCost: null as Cost
    }
  },

  async mounted() {
    await this.getCosts()
    this.loading = false
  },

  methods: {
    selectCost(id: string) {
      if (id) {
        this.selectedCost = find(this.costs, { id })
      }
    },

    async getCosts(page = 1) {
      this.loading = true
      const { data, metadata } = await services.users.getCosts({
        page_number: page,
        items_per_page: this.PAGE_SIZE
      })
      this.loading = false

      this.totalCostsCount = metadata.count
      this.costs = data

      this.loading = false
      this.selectCost(last(this.costs)?.id)
    },

    goToEditCostsPage() {
      if (this.selectedCost?.id) {
        this.$router.push({
          name: 'AccountingCostsEdit',
          params: { id: this.selectedCost.id }
        })
      }
    }
  }
})
