<template>
  <el-form class="container">
    <div class="details-wrapper">
      <el-card class="details" :header="$t('form.personalDetails')" :dark-shadow="true">
        <div class="user-info">
          <div class="user-info__avatar">
            <AvatarWithUpload :src="user.photo" @uploaded="onPhotoUpload" />
          </div>
          <div class="user-info__form">
            <div class="inputField">
              <span class="label">{{ $t('form.name') }}</span>
              <el-input v-model="user.name" @change="saveForm" />
            </div>

            <div class="inputField">
              <span class="label">{{ $t('form.surname') }}</span>
              <el-input v-model="user.surname" @change="saveForm" />
            </div>

            <div class="inputField">
              <span class="label">{{ $t('form.nickname') }}</span>
              <el-input v-model="user.nickname" @change="saveForm" />
            </div>

            <div class="inputField">
              <span class="label">{{ $t('form.email') }}</span>
              <el-input v-model="user.email" @change="saveForm" />
            </div>

            <div class="inputField">
              <span class="label">{{ $t('form.phone') }}</span>
              <TelephoneInput v-model="user.phone" @change="saveForm" />
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <div class="settings-wrapper">
      <el-card :header="$t('form.settings')">
        <div class="settings">
          <div class="inputField">
            <span class="label">{{ $t('form.language') }}</span>
            <el-select
              :no-match-text="$t('form.language')"
              :model-value="languages.length ? user.language.id : null"
              :disabled="!languages.length"
              class="language-dropdown"
              @change="
                event => {
                  user.language = languages.find(({ id }) => id === event)
                  changeLanguage(event)
                  saveForm()
                }
              "
            >
              <el-option v-for="{ name, id } in languages" :key="id" :value="id" :label="name" />
            </el-select>
          </div>
          <div class="inputField">
            <span class="label">{{ $t('common.currency') }}</span>
            <el-select
              :model-value="currencies.length ? user.currency.id : null"
              class="language-dropdown"
              :disabled="!currencies.length"
              @change="
                e => {
                  user.currency = currencies.find(({ id }) => id === e)
                  saveForm()
                }
              "
            >
              <el-option v-for="{ short, id } in currencies" :key="id" :value="id" :label="short" />
            </el-select>
          </div>
        </div>
      </el-card>
    </div>
  </el-form>
</template>

<script lang="ts">
import AvatarWithUpload from '@/common/components/AvatarWithUpload.vue'
import TelephoneInput from '@/common/components/TelephoneInput.vue'
import { defineComponent } from 'vue'
import { setLocale } from '@/locales'
import services from '@/core/services'
import api from '@/core/api'
import { find, isEmpty, pick } from 'lodash'
import { mapGetters } from 'vuex'
import { User } from '@/core/models'

export default defineComponent({
  components: {
    AvatarWithUpload,
    TelephoneInput
  },

  data() {
    const user: User = User.create(this.$store.getters.user)

    return { user }
  },
  computed: mapGetters(['languages', 'currencies']),

  async mounted() {
    if (isEmpty(this.languages) || isEmpty(this.currencies)) {
      const [currencies, languages] = await Promise.all([api.currencies.get(), api.languages.get()])
      this.currencies = currencies
      this.languages = languages

      this.$store.commit('setLanguages', languages)
      this.$store.commit('setCurrencies', currencies)
    }
  },

  methods: {
    async saveForm() {
      const payloadKeys = [
        'photo',
        'name',
        'surname',
        'nickname',
        'email',
        'phone',
        'language',
        'currency'
      ]

      const payload = pick(this.user.transformToDbFormat(), payloadKeys)

      await services.users.update(payload, this.user.clone())
    },

    changeLanguage(languageId: string) {
      const shortName = find(this.languages, { id: languageId }).short
      setLocale(shortName)
    },

    async onPhotoUpload(file: IUploadedFile) {
      if (this.user.photo) {
        await services.awsImageUploader.remove(this.user.photo)
      }

      const photoName = `${this.$store.getters.user.id}/${Date.now()}${file.name}`

      this.user.photo = await services.awsImageUploader.upload(photoName, file.raw)

      this.saveForm()
    }
  }
})
</script>

<style lang="scss" scoped>
.avatar {
  @media (max-width: 1400px) {
    width: 80px;
    height: 80px;
  }
}

.container {
  display: flex;
}

.details-wrapper {
  flex: 2.8;

  .details {
    margin-right: 40px;

    @media (max-width: 1400px) {
      margin-right: 20px;
    }
  }
}

.settings-wrapper {
  flex: 1;

  .settings {
    .language-dropdown {
      flex: 0.85;

      :deep(.el-input__inner) {
        padding: 0px 8px;
      }
    }
  }
}

.user-info {
  padding: 30px 40px;
  display: flex;
  flex-wrap: nowrap;

  &__avatar {
    margin-right: 20px;
  }

  &__form {
    flex: 1;
  }
}

.el-select-dropdown__item {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
}
</style>
