import { PERIODS } from '@/common/util'
import { Exclude, Expose, Transform } from 'class-transformer'
import { camelCase, omit, snakeCase } from 'lodash'
import { BaseModelWrapper } from './BaseModel.model'

export class Reminder extends BaseModelWrapper<Reminder>() {
  date = ''
  description = ''

  @Transform(({ value }) => (value ? camelCase(value) : value), { toClassOnly: true })
  @Transform(({ value }) => (value ? snakeCase(value) : value), { toPlainOnly: true })
  period: 'weekly' | 'monthly' | 'three_months' | 'yearly' | 'never' = 'never'

  @Expose()
  get periodical() {
    return PERIODS.includes(this.period)
  }

  @Exclude({ toPlainOnly: true })
  get isValid() {
    return Boolean(this.date && this.description)
  }

  transformToDbFormat() {
    const reminder = super.transformToDbFormat()

    return PERIODS.includes(reminder.period) ? reminder : (omit(reminder, 'period') as Reminder)
  }
}
