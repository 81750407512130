<template>
  <div class="form">
    <div class="form-left">
      <div class="inputField">
        <span class="label">{{ $t('finances.purchasePrice') }}</span>
        <el-input v-model.number="purchase.price" type="number" :placeholder="$t('common.price')" />
      </div>

      <div v-for="cost in purchase.otherFee" :key="cost.id" class="inputField">
        <span class="label">{{ $t('form.otherCost') }}</span>
        <div class="input-wrapper">
          <el-input
            v-model.number.lazy="cost.value"
            :clearable="purchase.otherFee.length === 1"
            type="number"
            :placeholder="$t('common.cost')"
          />
          <i
            v-if="purchase.otherFee.length > 1"
            class="el-icon-circle-close"
            @click="purchase.removeOtherFee(cost.id)"
          />
        </div>
      </div>

      <el-button
        class="add-cost-btn"
        type="success"
        size="mini"
        icon="el-icon-fa-plus fas"
        circle
        @click="purchase.addOtherFee()"
      />

      <el-divider />
      <div class="inputField">
        <span class="label">{{ $t('finances.totalCost') }}</span>
        <el-input readonly :model-value="totalCosts" :placeholder="$t('common.cost')" />
      </div>
    </div>
    <div class="form-right">
      <div class="inputField">
        <span class="label">{{ $t('dates.purchaseDate') }}</span>
        <DatePicker v-model="purchase.date" :placeholder="$t('common.date')" />
      </div>
      <div class="inputField">
        <span class="label">{{ $t('dates.deprecationPeriod') }}</span>
        <el-select
          v-model="purchase.deprecationPeriod"
          :placeholder="$t('dates.deprecationPeriod')"
        >
          <el-option
            v-for="period in deprecationPeriods"
            :key="period"
            :label="`${period} years`"
            :value="period"
          >
          </el-option>
        </el-select>
      </div>
      <div class="inputField">
        <span class="label">{{ $t('dates.dateOfConstruction') }}</span>
        <DatePicker v-model="purchase.dateOfConstruction" :placeholder="$t('common.date')" />
      </div>
      <div class="inputField">
        <span class="label">{{ $t('dates.dateOfMajorRepair') }}</span>
        <DatePicker v-model="purchase.dateOfMajorRepair" :placeholder="$t('common.date')" />
      </div>
    </div>
  </div>
  <div class="footer">
    <el-button type="primary" @click="onSave">{{ $t('common.save') }}</el-button>
  </div>

  <el-divider />
  <div class="charts">
    <div class="overview-charts">
      <div v-if="accountingData.income" class="chart">
        <div class="chart__label">{{ $t(accountingData.income.label) }}</div>
        <DoughnutChart
          :cutout-percentage="accountingData.income.cutoutPercentage"
          :data="accountingData.income.data"
        >
          <div class="chart__text-amount">
            {{ $f.currency(accountingData.income.amount) }}
          </div>
          <div class="chart__text-other">{{ $t('dates.monthly') }}</div>
        </DoughnutChart>
      </div>

      <div v-if="accountingData.expenses" class="chart">
        <div class="chart__label">{{ $t(accountingData.expenses.label) }}</div>
        <DoughnutChart
          :cutout-percentage="accountingData.expenses.cutoutPercentage"
          :data="accountingData.expenses.data"
        >
          <div class="chart__text-amount">
            {{ $f.currency(accountingData.expenses.amount) }}
          </div>
          <div class="chart__text-other">{{ $t('dates.monthly') }}</div>
        </DoughnutChart>
      </div>

      <div v-if="accountingData.internalReturnRate" class="chart">
        <div class="chart__label">
          {{ $t(accountingData.internalReturnRate.label) }}
        </div>
        <DoughnutChart
          :cutout-percentage="accountingData.internalReturnRate.cutoutPercentage"
          :data="accountingData.internalReturnRate.data"
        >
          <div class="chart__text-amount">{{ accountingData.internalReturnRate.amount }} %</div>
        </DoughnutChart>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import DatePicker from '@/common/components/DatePicker.vue'
import { defineComponent, PropType } from 'vue'
import DoughnutChart from '@/common/components/DoughnutChart.vue'
import services from '@/core/services'
import { useTabChangeConfirm } from '@/common/hooks'
import { Estate, Purchase } from '@/core/models'
import { get, pick } from 'lodash'

export default defineComponent({
  components: {
    DatePicker,
    DoughnutChart
  },
  props: {
    estate: { type: Object as PropType<Estate>, required: true }
  },
  emits: ['update:modelValue', 'reFetch', 'onShouldChangeTab'],

  data() {
    const purchase = get(this.estate, 'purchases[0]', Purchase.create({}))

    return {
      deprecationPeriods: [20, 30, 40],
      purchase: purchase.clone(),
      accountingData: {
        income: null,
        expenses: null,
        internalReturnRate: null
      }
    }
  },

  computed: {
    totalCosts(): number {
      return this.purchase.totalCosts
    }
  },

  watch: {
    totalCosts() {
      const amount = this.purchase.getRoundedIRR({ monthlyIncome: 650, monthlyExpenses: 200 })

      this.accountingData.internalReturnRate = services.finances.getInternalReturnRateChartData(
        amount
      )
    }
  },

  created() {
    useTabChangeConfirm({
      shouldConfirm: () => false,
      onNoConfirm: () => this.$emit('onShouldChangeTab', true),
      onConfirm: () => this.$emit('onShouldChangeTab', true),
      onCancel: () => this.$emit('onShouldChangeTab', true)
    })
  },

  mounted() {
    this.accountingData.income = services.finances.getIncomeChartData()
    this.accountingData.expenses = services.finances.getExpensesChartData()
    this.accountingData.internalReturnRate = services.finances.getInternalReturnRateChartData(
      this.purchase.getRoundedIRR({ monthlyIncome: 650, monthlyExpenses: 200 })
    )
  },

  methods: {
    async onSave() {
      const payload = pick(this.purchase.transformToDbFormat(), [
        'date',
        'price',
        'otherFee',
        'id'
      ] as (keyof Purchase)[])

      await services.estates.update(this.$props.estate['id'], {
        purchases: [payload]
      })

      this.$emit('reFetch')
    }
  }
})
</script>

<style lang="scss" scoped>
.form {
  color: #909399;
  display: flex;
  padding: 25px 0;

  &-left {
    flex-basis: 40%;
    text-align: left;
    margin-right: 20px;

    .label {
      width: 40%;
    }

    .add-cost-btn {
      width: 28px;
      height: 28px;
    }
  }

  &-right {
    flex-basis: 60%;
    text-align: right;

    .inputField {
      justify-content: space-evenly;
    }

    .label {
      width: 34%;
    }
  }

  .el-divider {
    margin: 20px 0;
  }

  .input-wrapper {
    position: relative;
    flex: 1.1;

    .el-icon-circle-close {
      position: absolute;
      top: 50%;
      right: 7px;
      transform: translateY(-50%);
      visibility: hidden;
      cursor: pointer;
    }

    &:hover .el-icon-circle-close {
      visibility: visible;
    }
  }
}

.footer {
  padding: 0 30px;
  text-align: left;
  margin-bottom: 20px;
}

.overview-charts {
  margin: auto;
  width: 80%;
  display: flex;
  justify-content: space-between;

  .chart {
    &__label {
      font-size: 16px;
      line-height: 16px;
      font-weight: bolder;
      margin: 15px 0;
    }

    &__text-amount {
      font-size: 14px;
      font-weight: bolder;
    }

    &__text-other {
      font-size: 12px;
      font-weight: 600;
    }
  }
}
</style>
