import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { cloneDeep } from 'lodash'

export type IConfig = AxiosRequestConfig

class Http {
  private axios: AxiosInstance = axios.create()

  constructor() {
    this.axios.interceptors.request.use(this.decamelizeKeys)
    this.axios.interceptors.response.use(this.camelizeKeys)
  }

  setHeader(header: string, value: string) {
    this.axios.defaults.headers.common[header] = value
    return this
  }

  async post<T = any>(url: string, body?: any, config?: IConfig) {
    const { data } = await this.axios.post<T>(url, body, config)
    return data
  }

  async put<T = any>(url: string, body?: any, config?: IConfig) {
    const { data } = await this.axios.put<T>(url, body, config)
    return data
  }

  async get<T = any>(url: string, config?: IConfig) {
    const { data } = await this.axios.get<T>(url, config)
    return data
  }

  async delete<T = any>(url: string, config?: IConfig) {
    const { data } = await this.axios.delete<T>(url, config)
    return data
  }

  private decamelizeKeys(config: AxiosRequestConfig) {
    const camelCaseToSnakeCase = key => {
      return Array.from(key.matchAll(/[A-Z]?[a-z]+|\d+[a-z]*/g), m => m[0].toLowerCase()).join('_')
    }

    const newConfig = cloneDeep(config)

    if (config.params) {
      newConfig.params = decamelizeKeys(config.params, camelCaseToSnakeCase)
    }

    if (config.data) {
      newConfig.data = decamelizeKeys(config.data, camelCaseToSnakeCase)
    }

    return newConfig
  }

  private camelizeKeys(res: AxiosResponse) {
    if (res.data && res.headers['content-type'] === 'application/json') {
      res.data = camelizeKeys(res.data)
    }

    return res
  }
}

export default new Http()
