
import { defineComponent } from 'vue'

export default defineComponent({
  emits: ['hide'],

  computed: {
    instructionText(): string {
      const sectionRoute = this.$route.matched[0]

      if (!sectionRoute?.name) {
        return
      }

      const instructions: { [sectionRouteName: string]: string } = {
        SignIn: 'SignIn',
        SignOut: 'SignOut',
        Dashboard: 'Dashboard',
        AccountDetails: 'AccountDetails',
        RealEstate: 'RealEstate',
        Accounting: 'Accounting',
        AddressBook: 'AddressBook'
      }

      return instructions[sectionRoute.name as string]
    }
  }
})
