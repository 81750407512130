
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    modelValue: { type: String, required: true }
  },

  emits: {
    'update:modelValue': String,
    change: String
  },

  computed: {
    phone: {
      get(): string {
        return this.modelValue as string
      },

      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
})
