<template>
  <div class="container">
    <div id="loginContainer" class="card">
      <!-- Custom -->
      <!-- <div class="title">Log in and get to work</div>
      <div class="section">
        <el-input v-model="username" placeholder="Username or Email"></el-input>
        <el-button type="primary" @click="loginWithGoogle">Continue</el-button>
      </div>
      <el-divider><b>or</b></el-divider>
      <div class="section">
        <el-button icon="el-icon-fa-google fab" @click="loginWithGoogle">
          Sign in with <b>Google</b>
        </el-button>
        <el-button
          icon="el-icon-fa-facebook-square fab"
          @click="loginWithGoogle"
        >
          Sign in with <b>Facebook</b>
        </el-button>
        <el-button icon="el-icon-fa-apple fab" @click="loginWithGoogle">
          Sign in with <b>Apple</b>
        </el-button>
      </div>
      <el-divider><b>New to Us?</b></el-divider>
      <div class="section">
        <el-button type="success">Sign Up</el-button>
      </div> -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'SignIn',
  setup: () => {
    return {
      username: ref('')
    }
  },
  mounted() {
    this.$auth.show()
  },
  methods: {}
})
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 700px;

  .card {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    background: white;
    width: 500px;
    padding: 44px;
    border-radius: 10px;

    :deep(.auth0-lock-widget) {
      margin: 0;
    }

    .title {
      font-size: 30px;
      font-weight: bolder;
      margin: 10px 0;
    }

    .section {
      width: 200px;
      display: flex;
      flex-direction: column;
      margin: 0 auto;

      & > * {
        margin: 10px 0;
      }
    }
  }
}
</style>
