
import { i18n } from '@/locales'
import services from '@/core/services'
import { each, pick, set } from 'lodash'
import { defineComponent } from 'vue'
import { forFutureYearsPrependF, REPORTS_YEARS_RANGE } from '../utils'

export default defineComponent({
  props: ['reports'],
  data() {
    return {
      mainTable: [],
      amountTable: [],
      amountTableSummaryFn: null,
      scenarioTable: []
    }
  },

  async mounted() {
    const t = i18n.global.t
    const reports = this.reports

    this.mainTable = this.getMainTableData(reports)

    this.scenarioTable = reports.scenarioWacc.map((amount, index) => {
      return {
        scenarioWacc: amount + ' %',
        intrinsicValue: reports.intrinsicValue[index]
      }
    })

    this.amountTable = [
      { ...reports.mortgage, name: t(`finances.mortgage`) },
      { ...reports.equity, name: t(`finances.equity`) },
      { costOfCapital: reports.totalWacc, name: t(`finances.totalWacc`) }
    ]
    this.amountTableSummaryFn = () => [t('common.round'), '', Math.round(reports.totalWacc) + ' %']
  },

  methods: {
    getMainTableData(reports: Readonly<{ [key: string]: any }>) {
      const table: any[] = []
      const yearsCount = reports.years.length

      const partialReports = pick(reports, [
        'years',
        'nopat',
        'investment',
        'fcf',
        'pvFactor',
        'presentValueOfFcf'
      ])

      each(partialReports, (colValuesList, colName) => {
        for (let i = 0; i < yearsCount; i++) {
          const value = colValuesList[i]

          if (colName === 'years') {
            set(table, `${i}.${colName}`, forFutureYearsPrependF(value))
          } else if (colName === 'pvFactor') {
            const data = {
              value,
              hint: this.isLastYear(partialReports.years[i])
                ? i18n.global.t('messages.presentValueOfEuroInPerpetuityBeginningIn2026f')
                : null
            }

            set(table, `${i}.${colName}`, data)
          } else if (colName === 'presentValueOfFcf') {
            const data = {
              value,
              hint: this.isLastYear(partialReports.years[i])
                ? i18n.global.t('messages.infiniteGrowthOfFcfAfterYear2025fIs2')
                : null
            }

            set(table, `${i}.${colName}`, data)
          } else if (colName === 'nopat') {
            const data = {
              value,
              hint: this.isLastYear(partialReports.years[i])
                ? i18n.global.t(
                    'messages.nopatIncreasesBy348BasedOnReturnOf419On2025fInvestmentOf83215'
                  )
                : null
            }

            set(table, `${i}.${colName}`, data)
          } else {
            set(table, `${i}.${colName}`, value)
          }
        }
      })
      table.push(
        {
          years: i18n.global.t('finances.intrinsicTotalValue'),
          presentValueOfFcf: { value: reports.intrinsicTotalValue }
        },
        {
          years: i18n.global.t('finances.intrinsicCommonEquityValue'),
          presentValueOfFcf: { value: reports.intrinsicCommonEquityValue }
        },
        {
          years: i18n.global.t('finances.numberOfSharesOutstanding'),
          presentValueOfFcf: { value: reports.numberOfSharesOutstanding }
        },
        {
          years: i18n.global.t('finances.intrinsicShareValue'),
          presentValueOfFcf: { value: reports.intrinsicShareValue }
        }
      )

      return table
    },

    isLastYear(year) {
      const lastFutureYear = new Date().getFullYear() - 1 + REPORTS_YEARS_RANGE
      year = parseInt(year)

      return typeof +year === 'number' && !isNaN(+year) && +year === lastFutureYear
    }
  }
})
