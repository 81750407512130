
import { Address, Estate } from '@/core/models'
import { DocumentDetail } from '@/core/models/Documents/DocumentDetail.model'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'Document',
  props: {
    label: {
      type: String,
      required: true
    },
    from: {
      type: Object as PropType<{
        address: Address
        name: string
      }>,
      required: true
    },
    to: {
      type: Object as PropType<{
        address: Address
        id: string
        getFullName(): string
      }>,
      required: true
    },
    estate: {
      type: Object as PropType<Estate>,
      required: false,
      default: () => null
    },
    details: {
      type: Object as PropType<DocumentDetail>,
      required: true
    }
  }
})
