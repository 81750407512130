import { RouteRecordRaw } from 'vue-router'

export const articlesRoutes: RouteRecordRaw[] = [
  {
    path: '/articles',
    name: 'Articles',
    redirect: { name: 'ArticlesList' },
    component: () => import('@/admin/pages/Articles/Index.vue'),
    children: [
      {
        path: 'list',
        name: 'ArticlesList',
        meta: { contentType: 'article' },
        component: () => import('@/admin/pages/Articles/List.vue')
      },
      {
        path: 'tutorial/list',
        name: 'TutorialsList',
        meta: { contentType: 'tutorial' },
        component: () => import('@/admin/pages/Articles/List.vue')
      },
      {
        path: 'create',
        name: 'ArticleCreate',
        meta: { contentType: 'article' },
        component: () => import('@/admin/pages/Articles/Create.vue')
      },
      {
        path: 'tutorial/create',
        name: 'TutorialCreate',
        meta: { contentType: 'tutorial' },
        component: () => import('@/admin/pages/Articles/Create.vue')
      },
      {
        path: 'edit/:id',
        props: true,
        name: 'ArticleEdit',
        component: () => import('@/admin/pages/Articles/Edit.vue')
      },
      {
        path: 'tutorial/edit/:id',
        props: true,
        name: 'TutorialEdit',
        component: () => import('@/admin/pages/Articles/Edit.vue')
      },
      {
        path: 'read/:id',
        name: 'ArticleRead',
        props: true,
        component: () => import('@/common/pages/ArticleRead.vue')
      },
      {
        path: 'tutorial/read/:id',
        name: 'TutorialRead',
        props: true,
        component: () => import('@/common/pages/ArticleRead.vue')
      }
    ]
  }
]
