
import api from '@/core/api'
import DatePicker from '@/common/components/DatePicker.vue'
import { Estate, Contact, Cost } from '@/core/models'
import services from '@/core/services'
import { pick } from 'lodash'
import moment from 'moment'
import { defineComponent } from 'vue'
import { confirmDeletion } from '@/common/util'

export default defineComponent({
  components: {
    DatePicker
  },
  data() {
    return {
      products: ['CAPEX', 'OPEX', 'TAX'],
      estates: [] as Estate[],
      vendors: [] as Contact[],
      terms: [5, 14, 30],
      selectedTerm: null,

      cost: Cost.create({}),
      //   email: null,
      //   address: null

      tableValidationRules: {
        product: [{ required: true, message: this.$t('common.required') }],
        description: [{ required: true, message: this.$t('common.required') }],
        quantity: [{ required: true, message: this.$t('common.required') }],
        rate: [{ required: true, message: this.$t('common.required') }]
      }
    }
  },

  async mounted() {
    if (this.$route.params.id) {
      const cost = await api.costs.getById(this.$route.params.id as string)
      if (cost) {
        this.cost = cost
        this.vendors = cost.vendor ? [cost.vendor] : []
        this.estates = cost.estate ? [cost.estate] : []
      }
    }

    const [estates, vendors] = await Promise.all([
      services.users.getEstates(),
      services.users.getContacts({ type: 'vendor' })
    ])

    this.vendors = vendors.data
    this.estates = estates.data
  },

  methods: {
    async save() {
      let formIsInvalid: boolean
      this.$refs.tableForm.validate(isValid => (formIsInvalid = !isValid))

      if (formIsInvalid) return

      const payloadKeys = ['details', 'vendor', 'estate', 'creatorAccount', 'id'] as (keyof Cost)[]

      const payload = pick(this.cost.transformToDbFormat(), payloadKeys)

      this.$route.params.id
        ? await services.costs.update(this.$route.params.id as string, payload)
        : await services.costs.create(payload)

      this.$router.push({ name: 'AccountingCostsList' })
    },

    confirmAndDelete(id) {
      confirmDeletion(this.$t('messages.willPermanentlyDeleteCost')).then(() => {
        this.cost.details.removeDescription(id)
      })
    },

    selectIntervalFromToday(interval: number) {
      this.cost.details.issued = moment().format()
      this.cost.details.due = moment()
        .add(interval, 'days')
        .format()
    },

    ensureEndDateToBeAfterStart(startDate: string) {
      if (this.cost.details.due && moment(startDate).isSameOrAfter(this.cost.details.due)) {
        this.cost.details.due = null
      }
      this.selectedTerm = null
    }
  }
})
