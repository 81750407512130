import { Exclude, Transform, Type } from 'class-transformer'
import { BaseModelWrapper } from './BaseModel.model'
import { pickId } from './helpers'

export class City {
  alpha3: string
  id: string
  name: string
  regionId: string
}

export class Region {
  alpha3: string
  id: string
  name: string
  regionId: string
}

export class Country {
  alpha2: string
  alpha3: string
  code: string
  name: string
  eu: boolean
  id: string
}

export class Address extends BaseModelWrapper<Address>() {
  number = ''
  street = ''
  zip = ''

  @Type(() => City)
  @Transform(pickId, { toPlainOnly: true })
  city = new City()

  @Type(() => Region)
  @Exclude({ toPlainOnly: true })
  region = new Region()

  @Type(() => Country)
  @Exclude({ toPlainOnly: true })
  country = new Country()

  toString() {
    const withComma = str => (str ? `${str},` : '')

    const number = withComma(this.number)
    const street = this.street || ''
    const city = withComma(this.city?.name)
    const region = withComma(this.region?.name)
    const country = withComma(this.country?.name)
    const zip = this.zip || ''

    return `${street} ${number} ${city} ${region} ${country} ${zip}`
  }
}
