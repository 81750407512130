
import { Estate } from '@/core/models'
import services from '@/core/services'
import { find, get, set } from 'lodash'

import { defineComponent } from 'vue'

type Report = {
  [key: string]: any[]
}

type ReportsMap = Record<
  ReportType,
  {
    all: Report[]
    [estateId: string]: Report[]
  }
>

export default defineComponent({
  data() {
    return {
      loading: false,
      estates: [] as Estate[],
      reportsMap: {} as ReportsMap,
      reportsToShow: null as Report,
      selectedEstateId: null as string,
      routes: [
        {
          name: 'ReportsProfitAndLoss',
          reportType: 'profit_and_loss',
          label: this.$t('finances.profitLoss')
        },
        {
          name: 'ReportsBalance',
          reportType: 'balance_sheet',
          label: this.$t('finances.balanceSheet ')
        },
        {
          name: 'ReportsCashFlow',
          reportType: 'cash_flow',
          label: this.$t('finances.cashFlow')
        },
        {
          name: 'ReportsFreeCashFlow',
          reportType: 'fcf',
          label: this.$t('finances.FCF ')
        },
        {
          name: 'ReportsDiscountedCashFlow',
          reportType: 'dcf',
          label: this.$t('finances.DCF')
        }
      ]
    }
  },

  computed: {
    activeReportType(): string {
      const { reportType }: any = find(this.routes, { name: this.$route.name }) || {}
      return reportType
    }
  },

  async mounted() {
    services.users.getEstates().then(({ data }) => (this.estates = data))
    await this.getReports(this.activeReportType)
  },

  methods: {
    async getReports(reportType: string) {
      this.loading = true
      const path = `${reportType}.${this.selectedEstateId || 'all'}`
      let reports = get(this.reportsMap, path)

      if (reports) {
        this.reportsToShow = get(this.reportsMap, path)
      } else {
        this.reportsMap
        reports = await services.users.getReports({
          report: reportType as ReportType,
          estate_id: this.selectedEstateId
        })

        const newReports = set(this.reportsMap, path, reports)
        this.reportsToShow = get(newReports, path)
      }

      this.loading = false
    },

    onEstateSelect(estateId: string) {
      estateId
        ? this.$router.push({ query: { estate: estateId } })
        : this.$router.push({ query: {} })

      this.selectedEstateId = estateId || undefined

      this.getReports(this.activeReportType)
    }
  }
})
