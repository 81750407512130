import api from '@/core/api'
import { withNotify } from '@/common/util/decorators'

export default class CostsService {
  @withNotify({ success: 'messages.successfullyCreated' })
  create(payload: any) {
    return api.costs.create(payload)
  }

  @withNotify({ success: 'messages.successfullyUpdated' })
  update(id: string, payload: any) {
    return api.costs.update(id, payload)
  }
}
