<template>
  <div v-for="index in count" :key="index" class="container">
    <el-skeleton animated :rows="1" />
    <el-skeleton animated :rows="1" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: { count: { type: Number, default: 1 } }
})
</script>

<style scoped>
.container {
  height: 88px;
  margin-top: 20px;
  width: 100%;
  border-radius: 4px;
  padding: 0 24px;
  overflow: hidden;
  background: white;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 10%) !important;
}

.el-skeleton {
  width: 80%;
}
</style>
