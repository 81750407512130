
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'Toolbar',
  props: {
    icons: {
      type: Array as PropType<
        {
          icon: string
          onClick?: (payload: MouseEvent) => void
        }[]
      >,
      required: true
    }
  }
})
