import { IEstatePhoto } from '@/core/models'
import * as AWS from '@aws-sdk/client-s3'

export interface IImageUploader {
  upload(basename: string, file: File): Promise<string>
  remove(basename: string): Promise<any>
  removeMany(photos: IEstatePhoto[]): Promise<any>
}

export class AwsS3ImageUploadingService implements IImageUploader {
  private bucketName = 'rentalsmanagement'
  private bucketUrl = 'https://rentalsmanagement.s3.eu-central-1.amazonaws.com/'

  private client = new AWS.S3({
    region: 'eu-central-1',
    credentials: {
      accessKeyId: 'AKIAREEZEPYQA2EKJS7P',
      secretAccessKey: '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'
    }
  })

  async upload(basename: string, file: File): Promise<string> {
    const command = new AWS.PutObjectCommand({
      Key: basename,
      Body: file,
      Bucket: this.bucketName
    })

    await this.client.send(command)
    const photoUrl = this.bucketUrl + encodeURIComponent(basename)

    return photoUrl
  }

  remove(fullPath: string) {
    const command = new AWS.DeleteObjectCommand({
      Key: this._decodeURI(fullPath),
      Bucket: this.bucketName
    })

    return this.client.send(command)
  }

  removeMany(photos: IEstatePhoto[]) {
    const Objects = photos.map(({ path }) => {
      return { Key: this._decodeURI(path) }
    })

    const command = new AWS.DeleteObjectsCommand({
      Bucket: this.bucketName,
      Delete: { Objects }
    })

    return this.client.send(command)
  }

  private _decodeURI(fullPath) {
    return decodeURIComponent(fullPath)
      .split('/')
      .slice(-2)
      .join('/')
  }
}
