import { i18n } from '@/locales'
import { ElMessageBox } from 'element-plus'

export const confirmDeletion = (locale: string) => {
  const { t } = i18n.global
  return ElMessageBox.confirm(t(locale), t('notification.warning'), {
    confirmButtonText: t('common.OK'),
    cancelButtonText: t('common.cancel'),
    type: 'warning'
  })
}

export function download(url: string, name = 'file', ext = '.pdf') {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', name + ext)
  document.body.appendChild(link)

  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    })
  )

  document.body.removeChild(link)
}

export const not = val => !val

export const DATE_FORMAT = 'DD-MM-YYYY'

export const PERIODS = ['weekly', 'monthly', 'three_months', 'yearly']
export const ARTICLES_PAGE_SIZE = 10

export const TEXT_EDITOR_OPTIONS = {
  'api-key': '6y7smnx3yecv6ovrx4v431f34lj6clzul1l60exkipbr6zdz',
  init: {
    block_unsupported_drop: false,
    image_title: true,
    file_picker_types: 'image',
    height: 500,
    menubar: false,
    statusbar: false,
    plugins: [
      'image code',
      'video code',
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code wordcount'
    ],
    toolbar:
      'undo redo | formatselect | bold italic backcolor | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | removeformat | link image media |'
  }
}
