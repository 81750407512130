<template>
  <div class="card " @click="$emit('click')">
    <div class="flex-between">
      <span class="d-flex">
        <el-checkbox :model-model="checked" @change="$emit('checked', $event)" />

        <span>
          <div>
            <b>
              {{ to?.name }}
              {{ to?.surname }}
            </b>
          </div>
          <div v-if="to?.isCompany" class="text-left mt-10">
            {{ to?.companyName }}
          </div>
        </span>
      </span>
      <span>
        {{ $f.currency(details.totalWithTax) }}
      </span>
    </div>
    <div class="flex-between">
      <span class="primary">{{ details.number }}</span>
      <span class="cost-card__data">
        {{ $moment(details.due).format('DD MMM YYYY') }}
      </span>
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { DocumentDetail } from '@/core/models/Documents/DocumentDetail.model'

export default defineComponent({
  name: 'DocumentPreviewItem',
  props: {
    to: {
      type: Object as PropType<{
        name: string
        surname: string
        isCompany?: boolean
        companyName?: string
      }>,
      required: false,
      default: () => ({})
    },
    details: {
      type: Object as PropType<DocumentDetail>,
      required: true
    },
    checked: {
      type: Boolean,
      default: false
    }
  },
  emits: ['checked', 'click']
})
</script>

<style lang="scss" scoped>
.card {
  cursor: pointer;
  width: 100%;
  background: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 16px 24px;
  margin-top: 20px;
  transition: all 0.3s;

  font-weight: 450;
  font-size: 12px;
  line-height: 12px;

  &:hover {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  }

  & > div:first-child {
    margin-bottom: 10px;
  }

  .el-checkbox {
    margin-right: 8px;
  }

  &__data {
    color: rgba(0, 0, 0, 0.5);
  }
}

.active {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3) !important;
}
</style>
