import { Transform, Type } from 'class-transformer'
import { Contact } from '..'
import { DocumentDetail } from './DocumentDetail.model'
import { Estate } from '../Estate.model'
import { pickId } from '../helpers'
import { User } from '../User.model'
import store from '@/core/store'
import { BaseModelWrapper } from '../BaseModel.model'
import { PaidPlan } from '../PaidPlan.model'

export class Invoice extends BaseModelWrapper<Invoice>() {
  checked = false // TODO:
  statementMessage = ''
  invoiceMessage = ''
  email = ''
  address = ''

  @Type(() => DocumentDetail)
  details = DocumentDetail.create({})

  @Type(() => Estate)
  @Transform(pickId, { toPlainOnly: true })
  estate = Estate.create({})

  @Type(() => User)
  @Transform(pickId, { toPlainOnly: true })
  creatorAccount = User.create({ id: store.getters.user.id })

  @Type(() => Contact)
  @Transform(pickId, { toPlainOnly: true })
  tenant = Contact.create({})

  @Type(() => Contact)
  @Transform(pickId, { toPlainOnly: true })
  managingCompany = Contact.create({})
}

export class AdminInvoice extends BaseModelWrapper<AdminInvoice>() {
  @Type(() => DocumentDetail)
  details = DocumentDetail.create({})

  @Type(() => Estate)
  @Transform(pickId, { toPlainOnly: true })
  estate = Estate.create({})

  @Type(() => User)
  @Transform(pickId, { toPlainOnly: true })
  creatorAccount = User.create({ id: store.getters.user.id })

  @Type(() => User)
  @Transform(pickId, { toPlainOnly: true })
  recipientAccount = User.create({})

  @Type(() => PaidPlan)
  @Transform(pickId, { toPlainOnly: true })
  paidPlan = PaidPlan.create({})
}
