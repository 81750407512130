
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    image: { type: String, required: true }
  },

  data() {
    return {
      scrollY: null as number
    }
  },

  mounted() {
    this.disableScrolling()
  },

  unmounted() {
    this.enableScrolling()
  },

  methods: {
    disableScrolling() {
      this.scrollY = window.scrollY
      window.scrollTo(0, 0)

      document.body.setAttribute('style', 'overflow:hidden;')
    },

    enableScrolling() {
      window.scrollTo(0, this.scrollY)
      document.body.setAttribute('style', 'overflow:auto;')
    }
  }
})
