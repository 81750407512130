import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import client from '@/core/auth'

import SignIn from '@/common/pages/SignIn.vue'
import SignOut from '@/common/pages/SignOut.vue'
import HelpCenter from '@/common/pages/HelpCenter.vue'
import Dashboard from '@/web-app/pages/Dashboard.vue'
import AccountDetails from '@/web-app/pages/AccountDetails.vue'
import realEstate from '@/web-app/router/realEstate'
import accounting from '@/web-app/router/accounting'
import addressBook from '@/web-app/router/addressBook'

const routes: RouteRecordRaw[] = [
  {
    path: '/sign-in',
    name: 'SignIn',
    component: SignIn
  },
  {
    path: '/sign-out',
    name: 'SignOut',
    component: SignOut
  },
  {
    path: '/help-center',
    name: 'HelpCenter',
    component: HelpCenter
  },
  {
    props: true,
    path: '/article/:id',
    name: 'ArticleRead',
    component: () => import('@/common/pages/ArticleRead.vue')
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/account-details',
    name: 'AccountDetails',
    component: AccountDetails
  },
  ...addressBook,
  ...realEstate,
  ...accounting
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  client.routeGuard(to, from, next)

  if (to.name !== from.name) {
    window.scrollTo({ top: 0 })
  }
})

export default router
