<template>
  <div class="header mb-25">
    <div class="icon-group">
      <div v-for="{ icon, onClick = () => {} } in icons" :key="icon" @click="onClick">
        <i :class="icon" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'Toolbar',
  props: {
    icons: {
      type: Array as PropType<
        {
          icon: string
          onClick?: (payload: MouseEvent) => void
        }[]
      >,
      required: true
    }
  }
})
</script>
