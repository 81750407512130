import http from '@/core/api/http'
import { IConfig } from './http'
import { BaseModelWrapper } from '@/core/models/BaseModel.model'

export class CRUD<One = any, Many = One[]> {
  constructor(protected url: string, protected Model?: ReturnType<typeof BaseModelWrapper>) {}

  get(config?: IConfig): Promise<Many> {
    return http.get(this.url, config).then(this.createModelInstance<Many>())
  }

  getById(id: string) {
    return http.get<One>(`${this.url}/${id}`).then(this.createModelInstance<One>())
  }

  create(data: Partial<One>): Promise<One> {
    return http.post(this.url, data)
  }

  update(id: string, data: Partial<One>): Promise<any> {
    return http.put(`${this.url}/${id}`, data)
  }

  delete(id: string): Promise<boolean> {
    return http.delete(`${this.url}/${id}`)
  }

  protected createModelInstance = <M = One>(Model = this.Model) => (response: M): M => {
    if (!Model) {
      return response
    }

    if (Array.isArray(response)) {
      return (response.map(el => Model.create(el)) as unknown) as M
    }

    if (this.responseWithMetadata(response)) {
      return {
        ...response,
        data: Array.isArray(response.data)
          ? response.data.map(el => Model.create(el))
          : Model.create(response)
      }
    }

    return Model.create(response) as M
  }

  private responseWithMetadata<T>(res: T | Paginated<T>): res is Paginated<T> {
    return !!(res['metadata'] && res['data'])
  }
}
