
import DatePicker from '@/common/components/DatePicker.vue'
import { useTabChangeConfirm } from '@/common/hooks'
import { defineComponent, PropType } from 'vue'
import moment from 'moment'
import services from '@/core/services'
import { Contact, Estate, ManagingCompanyFee } from '@/core/models'
import { confirmDeletion } from '@/common/util'
import { reject } from 'lodash'

export default defineComponent({
  components: {
    DatePicker
  },
  props: {
    estate: { type: Object as PropType<Estate>, required: true }
  },
  emits: ['onShouldChangeTab', 'reFetch'],

  data() {
    return {
      newFee: ManagingCompanyFee.create({}),

      managingCompanyContacts: [] as Contact[],
      managingCompanyFees: [...this.$props.estate['managingCompanyFees']]
    }
  },

  async mounted() {
    const { data } = await services.users.getContacts({
      type: 'managing_company'
    })
    this.managingCompanyContacts = data
  },

  async created() {
    useTabChangeConfirm({
      shouldConfirm: () => false,
      onNoConfirm: () => this.$emit('onShouldChangeTab', true),
      onConfirm: () => {
        this.onSave()
        this.$emit('onShouldChangeTab', true)
      },
      onCancel: () => this.$emit('onShouldChangeTab', true)
    })
  },

  methods: {
    async onSave() {
      const payload = this.newFee.transformToDbFormat()

      const { id } = await services.estates.feeCreate(this.estate['id'], payload)

      this.$emit('reFetch')

      this.newFee.id = id
      this.managingCompanyFees.push(this.newFee)

      this.newFee = ManagingCompanyFee.create({})
    },

    async removeFee(id: string) {
      await confirmDeletion(this.$t('messages.willPermanentlyDeleteFee'))

      await services.estates.feeRemove(id, this.estate['id'])
      this.managingCompanyFees = reject(this.managingCompanyFees, { id })
    },

    disableDates(date: string) {
      return moment(date).isSameOrBefore(this.newFee.start)
    },

    ensureEndDateToBeAfterStart(startDate: string) {
      if (this.newFee.end && moment(startDate).isSameOrAfter(this.newFee.end)) {
        this.newFee.end = ''
      }
    },

    onInputRemuneration(value: string) {
      if (+value > 100) {
        return
      }
      this.newFee.remuneration = +value
    }
  }
})
