<template>
  <div class="container">
    <el-upload
      action="#"
      :class="size"
      :show-file-list="false"
      list-type="picture-card"
      :auto-upload="false"
      @change="$emit('uploaded', $event)"
    >
      <template #default>
        <i v-if="src" class="fas el-icon-fa-edit" />
        <i v-else class="el-icon-plus" />
      </template>
    </el-upload>
    <Avatar :src="src" :size="sizeInPixels" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import Avatar from '@/common/components/Avatar.vue'

export default defineComponent({
  components: { Avatar },
  props: {
    src: { type: String, required: true },
    size: {
      type: String as PropType<'large' | 'medium' | 'small'>,
      default: 'medium'
    }
  },

  emits: ['uploaded'],

  computed: {
    sizeInPixels(): number {
      if (this.size === 'large') return 160
      if (this.size === 'medium') return 120
      else return 80
    }
  }
})
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  display: flex;
  justify-content: center;

  :deep(.el-upload--picture-card) {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    border: none;
    opacity: 0;
    transition: all 0.3s;

    i.fas {
      color: white;
    }

    &:hover {
      opacity: 0.6;
      background: black;
    }
  }

  .large {
    :deep(.el-upload--picture-card) {
      width: 160px;
      height: 160px;
    }
  }
  .medium {
    :deep(.el-upload--picture-card) {
      width: 120px;
      height: 120px;
    }
  }
  .small {
    :deep(.el-upload--picture-card) {
      width: 80px;
      height: 80px;
    }
  }
}
</style>
