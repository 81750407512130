
import { defineComponent } from 'vue'
import DoughnutChart from '@/common/components/DoughnutChart.vue'

export default defineComponent({
  name: 'Dashboard',
  components: { DoughnutChart },
  data() {
    const accountingData = [
      {
        label: this.$t('common.revenue'),
        percentage: '+20%',
        amount: 5000,
        data: {
          labels: ['label1, label2'],
          datasets: [
            {
              data: [20, 80],
              backgroundColor: ['#3347d3', '#cdd2f4']
            }
          ]
        }
      },
      {
        label: this.$t('common.cost'),
        percentage: '+5%',
        amount: 3200,
        data: {
          labels: ['label1, label2'],
          datasets: [
            {
              data: [5, 95],
              backgroundColor: ['#05af34', '#c0ebcd']
            }
          ]
        }
      },
      {
        label: this.$t('common.profit'),
        percentage: '+10%',
        amount: 1800,
        data: {
          labels: ['label1, label2'],
          datasets: [
            {
              data: [10, 90],
              backgroundColor: ['#fe0202', '#ffbfbf']
            }
          ]
        }
      }
    ]

    return { accountingData }
  }
})
