
import services from '@/core/services'
import { defineComponent } from 'vue'
import { normalizeTableData, forFutureYearsPrependF } from '../utils'

export default defineComponent({
  props: ['reports'],
  data() {
    return {
      tableData: [],
      columns: [],
      summaryFn: null
    }
  },

  async mounted() {
    const { operatingCashEndOfYear, ...reports } = this.reports

    const { tableData, columns } = normalizeTableData(reports)

    this.tableData = tableData
    this.columns = columns
    this.summaryFn = () =>
      [this.$t('finances.operatingCashEndOfYear')].concat(operatingCashEndOfYear)
  },

  methods: { forFutureYearsPrependF }
})
