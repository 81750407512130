import api from '@/core/api'
import { i18n } from '@/locales'
import { Estate, EstateOwner, ManagingCompanyFee } from '@/core/models'
import { withNotify } from '@/common/util/decorators'
import services from '.'

const t = i18n.global.t

export default class EstateService {
  @withNotify({ success: t('messages.successfullyDeleted') })
  async delete(estate: Estate) {
    await Promise.all([
      api.estates.delete(estate.id),
      estate.photos.length
        ? services.awsImageUploader.removeMany(estate.photos)
        : Promise.resolve(undefined)
    ])
  }

  @withNotify({ success: t('messages.successfullyUpdated') })
  async update(estateId: string, payload: any) {
    return await api.estates.update(estateId, payload)
  }

  @withNotify({ success: t('messages.successfullyCreated') })
  feeCreate(estateId: string, payload: ManagingCompanyFee) {
    return api.estates.feeCreate(estateId, payload)
  }

  @withNotify({ success: t('messages.successfullyDeleted') })
  feeRemove(feeId: string, estateId: string) {
    return api.estates.feeDelete(feeId, estateId)
  }

  @withNotify({ success: t('messages.successfullyUpdated') })
  ownersCreate(estateId: string, payload: EstateOwner) {
    return api.estates.ownersCreate(estateId, payload)
  }

  @withNotify({ success: t('messages.successfullyUpdated') })
  ownersUpdate({ estateId, ownerId }: Record<string, string>, payload: Partial<EstateOwner>) {
    return api.estates.ownersUpdate({ estateId, ownerId }, payload)
  }

  @withNotify({ success: t('messages.successfullyCreated') })
  createReminder(estateId: string, payload) {
    return api.estates.createReminder(estateId, payload)
  }

  @withNotify({ success: t('messages.successfullyDeleted') })
  removeReminder(estateId: string, reminderId: string) {
    return api.estates.removeReminder(estateId, reminderId)
  }
}
