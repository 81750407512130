
import AddressForm from '@/common/components/AddressForm.vue'
import { entries, pick } from 'lodash'
import { defineComponent, PropType } from 'vue'
import { useTabChangeConfirm } from '@/common/hooks'
import services from '@/core/services'
import { Estate } from '@/core/models'
import { getFormIsValid, getValidationRules } from './utils/validatinRules'

export default defineComponent({
  components: { AddressForm },
  props: {
    estate: { type: Object as PropType<Estate>, required: true }
  },
  emits: ['onShouldChangeTab', 'reFetch'],
  data() {
    const estateClone: Estate = (this.$props.estate as Estate).clone()

    return {
      estateClone,
      unchangedEstate: estateClone.clone(),
      validationRules: getValidationRules(estateClone)
    }
  },

  computed: {
    formIsValid(): boolean {
      return getFormIsValid(this.estateClone, this.validationRules)
    }
  },

  created() {
    useTabChangeConfirm({
      shouldConfirm: () => !this.estateClone.isEqualTo(this.unchangedEstate),
      onNoConfirm: () => this.$emit('onShouldChangeTab', true),
      onConfirm: () => {
        this.onSave()
        this.$emit('onShouldChangeTab', true)
      },
      onCancel: () => this.$emit('onShouldChangeTab', true)
    })
  },

  methods: {
    async onSave() {
      let isInvalid
      this.$refs.formRef.validate(valid => (isInvalid = !valid))

      if (isInvalid) {
        return
      }

      const payload = pick(this.estateClone.transformToDbFormat(), [
        'address',
        'name',
        'id',
        'recordNo',
        'primaryUse'
      ] as (keyof Estate)[])

      await services.estates.update(this.estate['id'], payload)
      this.$emit('reFetch')

      this.unchangedEstate = this.estateClone.clone()
    },

    setNewRequiredField(rules: { [key: string]: { required: boolean } }) {
      this.$refs.formRef.clearValidate()

      entries(rules).forEach(([ruleKey, rule]) => {
        this.validationRules[ruleKey][0].required = rule.required
      })
    },

    validateField(field: string) {
      this.$refs.formRef.validateField(field)
    }
  }
})
