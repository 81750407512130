
import { defineComponent, PropType } from 'vue'
import Avatar from '@/common/components/Avatar.vue'

export default defineComponent({
  components: { Avatar },
  props: {
    src: { type: String, required: true },
    size: {
      type: String as PropType<'large' | 'medium' | 'small'>,
      default: 'medium'
    }
  },

  emits: ['uploaded'],

  computed: {
    sizeInPixels(): number {
      if (this.size === 'large') return 160
      if (this.size === 'medium') return 120
      else return 80
    }
  }
})
