import { Exclude, Transform } from 'class-transformer'
import { map, reject, sumBy } from 'lodash'
import { v4 as uuid } from 'uuid'
import { BaseModelWrapper } from './BaseModel.model'

export class Purchase extends BaseModelWrapper<Purchase>() {
  date: string = null
  price = 10000

  @Transform(toListWithIds, { toClassOnly: true })
  @Transform(({ value }) => map(value, 'value'), { toPlainOnly: true })
  otherFee: { id: string; value: number }[]

  @Exclude({ toPlainOnly: true })
  id: string = uuid()

  @Exclude({ toPlainOnly: true })
  deprecationPeriod = null

  @Exclude({ toPlainOnly: true })
  dateOfConstruction = ''

  @Exclude({ toPlainOnly: true })
  dateOfMajorRepair = ''

  @Exclude({ toPlainOnly: true })
  get totalCosts(): number {
    return this.price + sumBy(this.otherFee, 'value')
  }

  getIRR({ monthlyIncome, monthlyExpenses }) {
    return (((monthlyIncome - monthlyExpenses) * 12) / this.totalCosts) * 100
  }

  getRoundedIRR({ monthlyIncome, monthlyExpenses }) {
    return Math.round(this.getIRR({ monthlyIncome, monthlyExpenses }) * 10) / 10
  }

  addOtherFee() {
    this.otherFee.push({ value: 0, id: uuid() })
  }

  removeOtherFee(id: string) {
    this.otherFee = reject(this.otherFee, { id })
  }
}

function primitiveToObjWithId(value: Primitive) {
  return { value, id: uuid() }
}
function toListWithIds({ value = [0] }) {
  if ((value[0] as any).id) {
    return value
  }

  return value.map(primitiveToObjWithId)
}
