
import { i18n } from '@/locales'
import services from '@/core/services'
import { pick } from 'lodash'
import { defineComponent } from 'vue'
import { getFutureYearLabel, forFutureYearsPrependF, normalizeTableData } from '../utils'

export default defineComponent({
  props: ['reports'],
  data() {
    return {
      assets: {
        tableData: [],
        columns: [],
        summaryFn: null
      },

      liabilities: {
        tableData: [],
        columns: [],
        summaryFn: null
      }
    }
  },

  async mounted() {
    const reports = this.reports

    this.setAssets(
      pick(reports, [
        'operatingCashNet',
        'accountsReceivable',
        'totalCurrentAssets',
        'landAndBuildings',
        'propertyPlantEquipmentNet',
        'totalAssets',
        'years'
      ])
    )

    this.setLiabilities(
      pick(reports, [
        'accountsPayable',
        'mortgage',
        'ownersEquity',
        'totalCurrentLiabilities',
        'totalLiabilities',
        'totalSeniorLiabilities',
        'totalLiabilitiesAndOwnersEquity',
        'years'
      ])
    )
  },

  methods: {
    getFutureYearLabel,

    setAssets(reports: { [reportName: string]: any[] }) {
      const { totalAssets, ...dataWithoutTolal } = reports

      const { tableData, columns } = normalizeTableData(dataWithoutTolal)

      const tableCustomHeader = { name: i18n.global.t('finances.assets') }
      dataWithoutTolal.years.forEach(year => {
        tableCustomHeader[year] = 0
      })

      this.assets = {
        summaryFn: () => [this.$t('finances.totalAssets')].concat(totalAssets),
        tableData: [tableCustomHeader, ...tableData],
        columns
      }
    },

    setLiabilities(reports: { [reportName: string]: any[] }) {
      const { totalLiabilitiesAndOwnersEquity, ...dataWithoutTolal } = reports

      const { tableData, columns } = normalizeTableData(dataWithoutTolal)

      const tableCustomHeader = { name: i18n.global.t('finances.totalLiabilitiesAndOwnersEquity') }
      dataWithoutTolal.years.forEach(year => {
        tableCustomHeader[year] = 0
      })

      this.liabilities = {
        summaryFn: () =>
          [this.$t('finances.totalLiabilitiesAndOwnersEquity')].concat(
            totalLiabilitiesAndOwnersEquity
          ),
        tableData: [tableCustomHeader, ...tableData],
        columns
      }
    },

    customHeaderRowSpan({ rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return columnIndex === 0 ? [1, this.assets.columns.length] : [0, 0]
      }
    },

    forFutureYearsPrependF
  }
})
