
import DatePicker from '@/common/components/DatePicker.vue'
import { defineComponent, PropType } from 'vue'
import DoughnutChart from '@/common/components/DoughnutChart.vue'
import services from '@/core/services'
import { useTabChangeConfirm } from '@/common/hooks'
import { Estate, Purchase } from '@/core/models'
import { get, pick } from 'lodash'

export default defineComponent({
  components: {
    DatePicker,
    DoughnutChart
  },
  props: {
    estate: { type: Object as PropType<Estate>, required: true }
  },
  emits: ['update:modelValue', 'reFetch', 'onShouldChangeTab'],

  data() {
    const purchase = get(this.estate, 'purchases[0]', Purchase.create({}))

    return {
      deprecationPeriods: [20, 30, 40],
      purchase: purchase.clone(),
      accountingData: {
        income: null,
        expenses: null,
        internalReturnRate: null
      }
    }
  },

  computed: {
    totalCosts(): number {
      return this.purchase.totalCosts
    }
  },

  watch: {
    totalCosts() {
      const amount = this.purchase.getRoundedIRR({ monthlyIncome: 650, monthlyExpenses: 200 })

      this.accountingData.internalReturnRate = services.finances.getInternalReturnRateChartData(
        amount
      )
    }
  },

  created() {
    useTabChangeConfirm({
      shouldConfirm: () => false,
      onNoConfirm: () => this.$emit('onShouldChangeTab', true),
      onConfirm: () => this.$emit('onShouldChangeTab', true),
      onCancel: () => this.$emit('onShouldChangeTab', true)
    })
  },

  mounted() {
    this.accountingData.income = services.finances.getIncomeChartData()
    this.accountingData.expenses = services.finances.getExpensesChartData()
    this.accountingData.internalReturnRate = services.finances.getInternalReturnRateChartData(
      this.purchase.getRoundedIRR({ monthlyIncome: 650, monthlyExpenses: 200 })
    )
  },

  methods: {
    async onSave() {
      const payload = pick(this.purchase.transformToDbFormat(), [
        'date',
        'price',
        'otherFee',
        'id'
      ] as (keyof Purchase)[])

      await services.estates.update(this.$props.estate['id'], {
        purchases: [payload]
      })

      this.$emit('reFetch')
    }
  }
})
