import { Exclude, Transform, Type } from 'class-transformer'
import { Address } from './Address.model'
import { BaseModelWrapper } from './BaseModel.model'
import { EstateOwner } from './EstateOwner.model'
import { pickId } from './helpers'
import { ManagingCompanyFee } from './ManagingCompanyFee.model'
import { Purchase } from './Purchase.model'
import { User } from './User.model'

export class Estate extends BaseModelWrapper<Estate>() {
  @Type(() => User)
  @Transform(pickId, { toPlainOnly: true })
  creatorAccount: User

  @Type(() => Address)
  address = Address.create({})

  @Type(() => ManagingCompanyFee)
  managingCompanyFees: ManagingCompanyFee[]

  @Type(() => EstateOwner)
  owners = [EstateOwner.create({})]

  @Type(() => Purchase)
  purchases = [Purchase.create({})]

  name: string
  id: string
  creatorIsOwner: boolean
  recordNo: string
  associatedAccounts: IAssociatedAccount[]
  photos: IEstatePhoto[]
  primaryUse?: string
  mapData?: any
  wwwData?: IWwwData
  reconstructionDate?: string
  constructionDate?: string

  @Exclude({ toPlainOnly: true })
  get fullAddress() {
    return this.address?.toString() || ''
  }
}

export interface IEstatePhoto {
  isMain: boolean
  path: string
}

export interface IWwwData {
  accommodation: 'property'
  activate: boolean
  currencyId: string
  header: string
  isPublic: boolean
  languages: string[]
  logoPath: string
  ownUrl: string
  subdomain: string
}
