import { classToClass, classToPlain, plainToClass } from 'class-transformer'
import { isEqual } from 'lodash'

type NonFunctionPropertyNames<T> = {
  [K in keyof T]: T[K] extends Function ? never : K
}[keyof T]

export type NonFunctionProperties<T> = Pick<T, NonFunctionPropertyNames<T>>

export const BaseModelWrapper = <T>(baseClass = class Base {}) =>
  class BaseModel extends baseClass {
    id: string

    /** instead of **new Model()** use **Model.create({})** */
    protected constructor() {
      super()
    }

    static create(data: NonFunctionProperties<Partial<T>>) {
      return (plainToClass(this as any, data, { exposeDefaultValues: true }) as unknown) as T
    }

    transformToDbFormat() {
      return classToPlain(this) as T
    }

    clone() {
      return classToClass(this, { enableCircularCheck: false, exposeUnsetFields: true })
    }

    isEqualTo(value: any) {
      // for (const key in this) {
      //   if (value[key]) {
      //     if (!isEqual(this[key], value[key])) {
      //       console.log(this[key], value[key])
      //     }
      //   } else console.log('missing key: ', key)
      // }
      return isEqual(JSON.stringify(this), JSON.stringify(value))
    }
  }
