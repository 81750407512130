import { i18n } from '@/locales'

export const normalizeTableData = (obj: Record<string, number[]>) => {
  const { years, ...data } = obj

  const tableData = Object.entries(data).map(([reportName, reportData]) => {
    return {
      name: i18n.global.t(`finances.${reportName}`),
      ...reportData.reduce((mapping, amount, index) => {
        return {
          [years[index]]: amount,
          ...mapping
        }
      }, {})
    }
  })

  let columns = Object.keys(tableData[0])
  columns = [columns.pop(), ...columns]

  return { columns, tableData }
}

export const getFutureYearLabel = (year: number) => {
  const currentYear = new Date().getFullYear()

  return year >= currentYear ? `${year}F` : year
}

export const forFutureYearsPrependF = col => {
  if (typeof +col === 'number' && !isNaN(+col)) {
    return getFutureYearLabel(col)
  }
  return col === 'name' ? '' : col
}

export const REPORTS_YEARS_RANGE = 5
