import Invoices from '@/admin/pages/Invoices/Index.vue'
import InvoicesList from '@/admin/pages/Invoices/List.vue'
import Invoice from '@/admin/pages/Invoices/Invoice.vue'

import { RouteRecordRaw } from 'vue-router'

export const invoicesRoutes: RouteRecordRaw[] = [
  {
    path: '/invoices',
    name: 'AdminInvoices',
    component: Invoices,
    redirect: { name: 'AdminInvoicesList' },
    children: [
      {
        path: 'list',
        name: 'AdminInvoicesList',
        component: InvoicesList
      },
      {
        path: 'item',
        name: 'AdminInvoiceCreate',
        component: Invoice
      },
      {
        path: 'item/:id',
        name: 'AdminInvoiceEdit',
        component: Invoice
      }
    ]
  }
]
