
import { defineComponent, PropType } from 'vue'
import { DocumentDetail } from '@/core/models/Documents/DocumentDetail.model'

export default defineComponent({
  name: 'DocumentPreviewItem',
  props: {
    to: {
      type: Object as PropType<{
        name: string
        surname: string
        isCompany?: boolean
        companyName?: string
      }>,
      required: false,
      default: () => ({})
    },
    details: {
      type: Object as PropType<DocumentDetail>,
      required: true
    },
    checked: {
      type: Boolean,
      default: false
    }
  },
  emits: ['checked', 'click']
})
