import api from '@/core/api'

export default class RegionService {
  async getByCountryCode(alpha3: string) {
    const { data } = await api.regions.get({
      params: { alpha3, items_per_page: 0 }
    })

    return data
  }
}
