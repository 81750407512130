
import { defineComponent, PropType } from 'vue'
import AddressForm from '@/common/components/AddressForm.vue'
import AvatarWithUpload from '@/common/components/AvatarWithUpload.vue'
import services from '@/core/services'
import { omit } from 'lodash'
import { confirmDeletion } from '@/common/util'
import { Contact } from '@/core/models'

export default defineComponent({
  components: { AddressForm, AvatarWithUpload },
  props: {
    contact: { type: Object as PropType<Contact>, required: true }
  },

  emits: ['onShouldChangeTab', 'reFetch'],

  data() {
    return {
      uploadedAvatar: null as null | IUploadedFile,
      contactClone: (this.contact as Contact).clone()
    }
  },

  methods: {
    async onSave() {
      const payload = omit(this.contactClone.transformToDbFormat(), 'photo') as Contact
      const { address } = payload

      if (this.uploadedAvatar) {
        payload.photo = await services.awsImageUploader.upload(
          this.uploadedAvatar.name,
          this.uploadedAvatar.raw
        )
      }

      await services.contacts.update(this.contact['id'], {
        ...payload,
        address
      })

      this.$emit('reFetch')
    },

    async onPhotoUpload(file: IUploadedFile) {
      this.contactClone.photo = file.url
      this.uploadedAvatar = {
        ...file,
        name: `${this.contact['id']}/${file.name}`
      }
    },

    async onDeleteContact() {
      try {
        await confirmDeletion(this.$t('messages.willPermanentlyDeleteContact'))
        await services.contacts.delete(this.contact['id'])
        this.$router.push({ name: 'AddressBookList' })
      } catch (error) {
        /*  */
      }
    }
  }
})
