
import Avatar from '@/common/components/Avatar.vue'
import { confirmDeletion } from '@/common/util'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { first, groupBy, orderBy, reject } from 'lodash'
import { User } from '@/core/models'
import api from '@/core/api'
import services from '@/core/services'

export default defineComponent({
  components: { Avatar },

  data() {
    return {
      loading: null,
      accounts: [] as User[]
      // accounts: {} as { [firstLetter: string]: IUser[] }
      // selectedSortOption: 'name',
      // selectedSortOrder: '' as '' | '-',
      // sortOptions: ['name', 'surname', 'companyName', 'rate'],
      // sortOrders: [
      //   { operator: '', label: 'ascending' },
      //   { operator: '-', label: 'descending' }
      // ]
    }
  },

  computed: {
    groupedAccounts(): { [firstLetter: string]: User[] } {
      return this.groupByFirstLetterOfName(this.accounts)
    },

    ...mapGetters(['contacts'])

    // sortQuery(): Record<string, string> {
    //   return {
    //     sort: this.selectedSortOrder + snakeCase(this.selectedSortOption)
    //   }
    // }
  },

  async mounted() {
    await this.getAcounts()
  },

  methods: {
    groupByFirstLetterOfName(accounts: User[]) {
      const alphabeticallyOrdered = orderBy(
        accounts,
        ({ name, surname }) => name?.toLowerCase() || surname?.toLowerCase()
      )

      const groupedByFirstLetter = groupBy(
        alphabeticallyOrdered,
        ({ name, surname }) => first(name)?.toUpperCase() || first(surname)?.toUpperCase()
      )

      return groupedByFirstLetter
    },

    goToEditPage({ id }) {
      this.$router.push({
        name: 'User',
        params: { id }
      })
    },

    async getAcounts() {
      this.loading = true
      const { data } = await api.accounts.get()
      this.accounts = data
      this.loading = false
    },

    // async onAddContact() {
    //   let name, surname

    //   this.$msgbox({
    //     title: this.$t('messages.inputContactName'),
    //     message: h('div', {}, [
    //       h('input', {
    //         placeholder: this.$t('common.name'),
    //         class: 'custom-input mb-20',
    //         onInput: e => (name = e.target.value)
    //       }),
    //       h('input', {
    //         placeholder: this.$t('form.surname'),
    //         class: 'custom-input ',
    //         onInput: e => (surname = e.target.value)
    //       })
    //     ]),
    //     showCancelButton: true,
    //     confirmButtonText: this.$t('common.add'),
    //     cancelButtonText: this.$t('common.cancel'),
    //     beforeClose: async (action: 'confirm' | 'cancel', instance, done) => {
    //       if (action === 'cancel') return done()

    //       instance.confirmButtonLoading = true
    //       instance.confirmButtonText = 'Loading...'
    //       const { id } = await services.users.createContact({ name, surname })
    //       done()

    //       this.$router.push({
    //         name: 'AddressBookItem',
    //         params: { id }
    //       })
    //       setTimeout(() => (instance.confirmButtonLoading = false), 300)
    //     }
    //   })
    // },

    async onConfirmAndDelete(id: string) {
      try {
        await confirmDeletion(this.$t('messages.willPermanentlyDeleteAccount'))
        await services.users.delete(id)
        this.accounts = reject(this.accounts, { id })
      } catch (error) {
        /*  */
      }
    }
  }
})
