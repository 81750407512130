import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import client from '@/core/auth'

import SignIn from '@/common/pages/SignIn.vue'
import SignOut from '@/common/pages/SignOut.vue'
import AccountDetails from '@/web-app/pages/AccountDetails.vue'

import { userRoutes } from './users'
import { invoicesRoutes } from './invoices'
import { articlesRoutes } from './articles'

const routes: RouteRecordRaw[] = [
  {
    path: '/sign-in',
    name: 'SignIn',
    component: SignIn
  },
  {
    path: '/sign-out',
    name: 'SignOut',
    component: SignOut
  },
  {
    path: '/account-details',
    name: 'AccountDetails',
    component: AccountDetails
  },
  ...userRoutes,
  ...invoicesRoutes,
  ...articlesRoutes,
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'Users' }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  client.routeGuard(to, from, next)

  if (to.name !== from.name) {
    window.scrollTo({ top: 0 })
  }
})

export default router
