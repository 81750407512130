import { RouteRecordRaw } from 'vue-router'
import UsersList from '@/admin/pages/Users/List.vue'
import Users from '@/admin/pages/Users/Index.vue'

export const userRoutes: RouteRecordRaw[] = [
  {
    path: '/users',
    name: 'Users',
    redirect: { name: 'UsersList' },
    component: Users,
    children: [
      {
        path: 'list',
        name: 'UsersList',
        component: UsersList
      },
      {
        path: ':id',
        name: 'User',
        props: true,
        component: () => import('@/admin/pages/Users/User/Index.vue'),
        redirect: { name: 'UserProfile' },
        children: [
          {
            path: 'profile',
            name: 'UserProfile',
            component: () => import('@/admin/pages/Users/User/Profile.vue')
          },
          {
            path: 'estates',
            name: 'UserEstates',
            component: () => import('@/admin/pages/Users/User/Estates.vue')
          }
        ]
      }
    ]
  }
]
