
import api from '@/core/api'
import DatePicker from '@/common/components/DatePicker.vue'
import { Contact, Estate, Invoice } from '@/core/models'
import services from '@/core/services'
import { pick } from 'lodash'
import moment from 'moment'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    DatePicker
  },
  data() {
    return {
      loading: true,
      tenants: [] as Readonly<Contact>[],
      estates: [] as Readonly<Estate>[],
      products: ['RENT'],
      terms: [5, 14, 30],
      selectedTerm: null,

      invoice: Invoice.create({}),

      tableValidationRules: {
        product: [{ required: true, message: this.$t('common.required') }],
        description: [{ required: true, message: this.$t('common.required') }],
        quantity: [{ required: true, message: this.$t('common.required') }],
        rate: [{ required: true, message: this.$t('common.required') }]
      }
    }
  },

  async mounted() {
    if (this.$route.params.id) {
      const invoice = await api.invoices.getById(this.$route.params.id as string)
      if (invoice) {
        this.invoice = invoice
        this.estates = invoice.estate ? [invoice.estate] : []
        this.tenants = invoice.tenant ? [invoice.tenant] : []
      }
    }

    const [estates, tenants] = await Promise.all([
      services.users.getEstates(),
      services.users.getContacts({ type: 'tenant' })
    ])

    this.estates = estates.data
    this.tenants = tenants.data
    this.loading = false
  },

  methods: {
    async save() {
      let formIsInvalid: boolean
      this.$refs.tableForm.validate(isValid => (formIsInvalid = !isValid))

      if (formIsInvalid) return

      const payloadKeys = [
        'details',
        'tenant',
        'estate',
        'creatorAccount',
        'id'
      ] as (keyof Invoice)[]

      const payload = pick(this.invoice.transformToDbFormat(), payloadKeys)

      this.$route.params.id
        ? await services.invoices.update(this.$route.params.id as string, payload)
        : await services.invoices.create(payload)

      this.$router.push({ name: 'AccountingInvoicesList' })
    },

    selectIntervalFromToday(interval: number) {
      this.invoice.details.issued = moment().format()
      this.invoice.details.due = moment()
        .add(interval, 'days')
        .format()
    },

    ensureEndDateToBeAfterStart(startDate: string) {
      if (this.invoice.details.due && moment(startDate).isSameOrAfter(this.invoice.details.due)) {
        this.invoice.details.due = null
      }
      this.selectedTerm = null
    }
  }
})
