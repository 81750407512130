import { sum } from 'lodash'
import randomColor from 'randomcolor'

export default class FinanceService {
  mockExpenses = [100, 50, 30, 20]
  mockIncomes = [50, 200, 30, 170, 200]

  getExpensesChartData() {
    const backgroundColor = this.mockExpenses.map(() =>
      randomColor({
        format: 'rgba',
        hue: 'blue',
        luminosity: 'dark'
      })
    )

    return {
      label: 'finances.expenses',
      cutoutPercentage: 55,
      amount: sum(this.mockExpenses),
      data: {
        labels: this.mockExpenses,
        datasets: [{ data: this.mockExpenses, backgroundColor }]
      }
    }
  }

  getIncomeChartData() {
    const backgroundColor = this.mockIncomes.map(() =>
      randomColor({
        format: 'rgba',
        hue: 'blue',
        luminosity: 'dark'
      })
    )

    return {
      label: 'finances.income',
      cutoutPercentage: 55,
      amount: sum(this.mockIncomes),
      data: {
        labels: this.mockIncomes,
        datasets: [{ data: this.mockIncomes, backgroundColor }]
      }
    }
  }

  getInternalReturnRateChartData(amount: number) {
    return {
      label: 'finances.irr',
      amount,
      get text() {
        return `${this.amount} %`
      },
      cutoutPercentage: 75,
      data: {
        labels: ['Internal Rate of Return', ''],
        datasets: [
          {
            data: [amount, 100 - amount],
            backgroundColor: ['#3347d3', '#ccd0f4']
          }
        ]
      }
    }
  }
}
