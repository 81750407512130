import { Exclude, Transform, Type } from 'class-transformer'
import { isEmpty } from 'lodash'
import { BaseModelWrapper } from './BaseModel.model'
import { Contact } from './Contact.model'
import { pickId } from './helpers'

export class ManagingCompanyFee extends BaseModelWrapper<ManagingCompanyFee>() {
  @Type(() => Contact)
  @Transform(pickId, { toPlainOnly: true })
  managingCompany = Contact.create({})

  start = ''
  end = ''
  remuneration: number = null

  @Exclude({ toPlainOnly: true })
  get isValid() {
    return Boolean(
      !isEmpty(this.managingCompany) &&
        this.remuneration >= 0 &&
        this.remuneration < 101 &&
        this.start &&
        this.end
    )
  }
}
