import api from '@/core/api'
import { withNotify } from '@/common/util/decorators'
import store from '../store'

export default class InvoicesService {
  private get endpoint() {
    return store.getters.isWebApp ? api.invoices : api.adminInvoices
  }

  @withNotify({ success: 'messages.successfullyCreated' })
  create(payload: any) {
    return this.endpoint.create(payload)
  }

  @withNotify({ success: 'messages.successfullyUpdated' })
  update(id: string, payload: any) {
    return this.endpoint.update(id, payload)
  }
}
