<template>
  <el-card>
    <el-tabs v-model="activeTab" stretch :before-leave="onTabChange">
      <el-tab-pane :label="$t('common.address')" name="RealEstateEditAddress" />
      <el-tab-pane :label="$t('common.ownerAddress')" name="RealEstateEditOwnerAddress" />
      <el-tab-pane :label="$t('common.management')" name="RealEstateEditManagement" />
      <el-tab-pane :label="$t('common.photos')" name="RealEstateEditPhotos" />
      <el-tab-pane :label="$t('common.finance')" name="RealEstateFinance" />
      <el-tab-pane :label="$t('common.reminder')" name="RealEstateReminder" />
    </el-tabs>
    <div>
      <router-view
        v-if="estate"
        :estate="estate"
        @onShouldChangeTab="handleTabChange"
        @reFetch="fetchEstate($props.id)"
      />
    </div>
  </el-card>
</template>

<script lang="ts">
import api from '@/core/api'
import { Estate } from '@/core/models'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    id: { type: String, required: true }
  },

  data() {
    return {
      estate: null as Estate,
      nextTabName: '',
      allowTabChange: false
    }
  },

  computed: {
    activeTab: {
      get(): string {
        return this.$route.name as string
      },
      set(name: string) {
        this.$router.push({ name })
      }
    }
  },

  async mounted() {
    await this.fetchEstate(this.$props.id as string)
  },

  methods: {
    onTabChange(toTab: string) {
      // prevent element plus tab defult behaviour
      this.nextTabName = toTab
      this.$router.push({ name: toTab })
      return this.allowTabChange
    },

    async handleTabChange(shouldChange: boolean) {
      if (!shouldChange) {
        return
      }

      this.allowTabChange = true
      this.nextTabName = ''
      setImmediate(() => (this.allowTabChange = false))
    },

    async fetchEstate(id: string) {
      this.estate = await api.estates.getById(id)
    }
  }
})
</script>
