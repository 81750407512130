
import services from '@/core/services'
import Avatar from '@/common/components/Avatar.vue'
import { confirmDeletion } from '@/common/util'
import { defineComponent, h } from 'vue'
import { mapGetters } from 'vuex'
import { snakeCase } from 'lodash'
import { Contact } from '@/core/models'

export default defineComponent({
  components: { Avatar },

  data() {
    return {
      loading: null,
      selectedSortOption: 'name',
      selectedSortOrder: '',
      sortOptions: ['name', 'surname', 'companyName', 'rate'],
      sortOrders: [
        { operator: '', label: 'ascending' },
        { operator: '-', label: 'descending' }
      ]
    }
  },

  computed: {
    groupedContacts(): { [key: string]: Contact[] } {
      let contacts = this.contacts
      const type = this.$route.query?.type as
        | 'isVendor'
        | 'isTenant'
        | Array<'isManagingCompany' | 'isCompany'>

      if (type) {
        contacts = contacts.filter(contact => {
          return Array.isArray(type) ? type.some(t => contact[t]) : contact[type]
        })
      }

      return services.contacts.groupBySortOption(contacts, this.selectedSortOption)
    },

    ...mapGetters(['contacts']),

    sortQuery(): Record<string, string> {
      return { sort: this.selectedSortOrder + snakeCase(this.selectedSortOption) }
    }
  },

  async mounted() {
    await this.getContacts()
  },

  methods: {
    goToEditPage({ id }) {
      this.$router.push({
        name: 'AddressBookItem',
        params: { id }
      })
    },

    async getContacts() {
      this.loading = true
      await services.users.getContacts(this.sortQuery)
      this.loading = false
    },

    async onAddContact() {
      let name, surname

      this.$msgbox({
        title: this.$t('messages.inputContactName'),
        message: h('div', {}, [
          h('input', {
            placeholder: this.$t('common.name'),
            class: 'custom-input mb-20',
            onInput: e => (name = e.target.value)
          }),
          h('input', {
            placeholder: this.$t('form.surname'),
            class: 'custom-input ',
            onInput: e => (surname = e.target.value)
          })
        ]),
        showCancelButton: true,
        confirmButtonText: this.$t('common.add'),
        cancelButtonText: this.$t('common.cancel'),
        beforeClose: async (action: 'confirm' | 'cancel', instance, done) => {
          if (action === 'cancel') return done()

          instance.confirmButtonLoading = true
          instance.confirmButtonText = 'Loading...'
          const { id } = await services.users.createContact({ name, surname })
          done()

          this.$router.push({
            name: 'AddressBookItem',
            params: { id }
          })
          setTimeout(() => (instance.confirmButtonLoading = false), 300)
        }
      })
    },

    async onConfirmAndDeleteContact(id: string) {
      try {
        await confirmDeletion(this.$t('messages.willPermanentlyDeleteContact'))
        await services.contacts.delete(id)
        this.$store.commit('removeContact', id)
      } catch (error) {
        /*  */
      }
    }
  }
})
