
import { defineComponent } from 'vue'
import NavBar from './core/layout/NavBar.vue'
import Header from './core/layout/Header.vue'
import Aside from './core/layout/Aside.vue'
import ImagePreview from '@/common/components/ImagePreview.vue'
import { mapGetters } from 'vuex'
import { useAuth, useIdle } from './common/hooks'

export default defineComponent({
  name: 'App',
  components: {
    NavBar,
    Header,
    Aside,
    ImagePreview
  },
  setup() {
    const auth = useAuth()

    const tenMins = 10 * 60 * 1000
    useIdle({
      durationMs: tenMins,
      onCountEnd: () => auth.signOut()
    })
  },

  data() {
    return { rightNavIsCollapsed: false }
  },

  computed: mapGetters(['previewImage'])
})
