
import { DATE_FORMAT } from '@/common/util'
import { ElDatePicker } from 'element-plus'
import moment from 'moment'
import { defineComponent } from 'vue'

export default defineComponent({
  extends: ElDatePicker,
  // eslint-disable-next-line vue/require-prop-types
  props: ['modelValue'],

  emits: {
    'update:modelValue': String,
    change: String
  },

  data() {
    return { DATE_FORMAT }
  },

  computed: {
    date: {
      get(): string {
        return this.$props['modelValue'] as string
      },

      set(value) {
        this.$emit('update:modelValue', value ? moment(value).format('YYYY-MM-DD') : null)
      }
    }
  }
})
