import { createI18n } from 'vue-i18n'
import en from './en.json'
import sk from './sk.json'
import ru from './ru.json'
import { Locale } from '@/core/models'

export const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en, sk, ru }
})

export function setLocale(locale: Locale) {
  i18n.global.locale = locale.toLowerCase()
}
