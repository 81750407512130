import store from '@/core/store'
import { capitalize, isEmpty, lowerCase, snakeCase } from 'lodash'
import { App } from 'vue'

export type Formatter = (value: string | number) => string

export const formatters = (app: App) => {
  const { getters } = store

  if (isEmpty(getters.user)) {
    return
  }

  const userLanguageShort = () => getters.languagesById[getters.user.language]
  const userCurrencyShort = () => store.getters.user.currency.short

  const methods = {
    number(n: string | number) {
      return (+n).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    },

    currency(n: string | number) {
      return new Intl.NumberFormat(userLanguageShort(), {
        style: 'currency',
        currency: userCurrencyShort()
      }).format(+n)
    },

    toSnakeCase(str: string) {
      return snakeCase(str)
    },

    toLowerCaseWithSpaces(str: string) {
      //  lowerCase('--Foo-Bar--'); 'foo bar'
      //  lowerCase('fooBar'); 'foo bar'
      //  lowerCase(__FOO_BAR__); 'foo bar'

      return lowerCase(str)
    },

    capitalize(str: string) {
      return capitalize(str)
    }
  }

  app.config.globalProperties.$f = methods
  app.provide('formatters', methods)
}
