import { BaseModelWrapper } from './BaseModel.model'
import { Language } from './Language.model'
import { Type, Transform, Exclude } from 'class-transformer'
import { pickId } from './helpers'
import { Address } from './Address.model'
import { Currency } from './Currency.model'
import { PaidPlan } from './PaidPlan.model'
import { Group } from './Group.model'
import { ColorTheme } from './ColorTheme.model'
import moment from 'moment'

export class User extends BaseModelWrapper<User>() {
  id: string
  name: string
  surname: string
  businessName?: string
  nickname: string
  email: string
  photo: string
  isVerified: boolean
  timezone: number
  invoicePrefix?: string
  phone?: string

  @Type(() => Language)
  @Transform(pickId, { toPlainOnly: true })
  language: Language

  @Type(() => Address)
  businessAddress = new Address()

  @Exclude({ toPlainOnly: true })
  get address() {
    return this.businessAddress
  }

  @Type(() => Currency)
  @Transform(pickId, { toPlainOnly: true })
  currency: Currency

  @Type(() => PaidPlan)
  @Transform(pickId, { toPlainOnly: true })
  paidPlan = PaidPlan.create({})

  paidPlanDate: string

  @Type(() => Group)
  @Transform(pickId, { toPlainOnly: true })
  group: Group

  @Type(() => ColorTheme)
  @Transform(pickId, { toPlainOnly: true })
  colorTheme: ColorTheme

  @Exclude({ toPlainOnly: true })
  getFullName() {
    return `${this.name || this.businessName} ${this.surname || ''} ${this.nickname || ''}`
  }

  @Exclude({ toPlainOnly: true })
  get isAdmin() {
    return this.group.finance === 'admin'
  }

  getValidPaidPlanDays(date: string) {
    return moment(date).isSameOrBefore(
      moment()
        .subtract(1, 'day')
        .format()
    )
  }
}
