import { ElMessageBox } from 'element-plus'
import { Action } from 'element-plus/lib/el-message-box/src/message-box.type'
import { isFunction } from 'lodash'
import { ComputedRef } from 'vue'
import { useI18n } from 'vue-i18n'
import { onBeforeRouteLeave } from 'vue-router'

type Options = {
  shouldConfirm: ComputedRef<boolean> | (() => boolean)
  onNoConfirm?: Function
  onConfirm: Function
  onCancel: Function
  onClose?: Function
  message?: string
  title?: string
  confirmButtonText?: string
  cancelButtonText?: string
}

export const useTabChangeConfirm = ({
  shouldConfirm,
  onNoConfirm,
  onConfirm,
  onCancel,
  onClose,
  message = 'messages.changesWillBeLost',
  title = 'messages.youHaveUnsavedChanges',
  confirmButtonText = 'common.save',
  cancelButtonText = 'common.continueWithoutSaving'
}: Options) => {
  const { t } = useI18n()
  onBeforeRouteLeave(async (_to, _from, next) => {
    const shouldOpenConfirmModal = isFunction(shouldConfirm) ? shouldConfirm() : shouldConfirm.value

    if (shouldOpenConfirmModal) {
      try {
        await ElMessageBox.confirm(t(message), t(title), {
          confirmButtonText: t(confirmButtonText),
          cancelButtonText: t(cancelButtonText),
          type: 'info',
          dangerouslyUseHTMLString: true,
          distinguishCancelAndClose: true
        })

        onConfirm()
        return next()
      } catch (action) {
        if ((action as Action) === 'cancel') {
          onCancel()

          return next()
        } else if ((action as Action) === 'close') {
          if (onClose) onClose()

          return next(false)
        }
      }
    }

    if (onNoConfirm) onNoConfirm()
    return next()
  })
}
