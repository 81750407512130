<template>
  <el-tabs :model-value="activeTabName" type="border-card" @tabClick="onTabChange">
    <el-tab-pane v-loading="loading" lazy name="article" label="Articles">
      <ArticlesList
        :total-articles-count="totals.article"
        :articles="selectedTabCurrentPageData"
        :current-page="currentPage"
        @on-page-change="handlePageChange"
      />
    </el-tab-pane>

    <el-tab-pane v-loading="loading" lazy name="tutorial" label="Tutorials">
      <ArticlesList
        :total-articles-count="totals.article"
        :articles="selectedTabCurrentPageData"
        :current-page="currentPage"
        @on-page-change="handlePageChange"
      />
    </el-tab-pane>
  </el-tabs>
</template>

<script lang="ts">
import api from '@/core/api'
import { Article, ArticleType, ArticleParams } from '@/core/models'
import { defineComponent } from '@vue/runtime-core'
import { get, isEmpty, set } from 'lodash'
import ArticlesList from '../components/ArticlesList.vue'
import { ARTICLES_PAGE_SIZE } from '../util'

export default defineComponent({
  components: { ArticlesList },
  data() {
    return {
      loading: false,
      totals: {
        article: 0,
        tutorial: 0
      },
      article: {} as {
        [page: string]: Article[]
      },
      tutorial: {} as {
        [page: string]: Article[]
      }
    }
  },

  computed: {
    selectedTabCurrentPageData(): Article[] {
      return get(this, `${this.activeTabName}[${this.currentPage}]`, [])
    },
    currentPage(): string {
      return (this.$route.query.page || '1') as string
    },
    activeTabName(): ArticleType {
      return (this.$route.query.tab || 'article') as ArticleType
    }
  },

  async mounted() {
    this.getArticles({
      page_number: this.currentPage,
      content_type: this.activeTabName
    })
  },

  methods: {
    async getArticles(params: ArticleParams) {
      const pathToCurrentPageArticles = `${params.content_type}[${params.page_number}]`

      const dataHasAlreadyFetched = !isEmpty(get(this, pathToCurrentPageArticles))
      if (dataHasAlreadyFetched) return

      this.loading = true

      const { data, metadata } = await api.articles.get({
        params: {
          items_per_page: ARTICLES_PAGE_SIZE,
          content_type: this.activeTabName,
          ...params
        }
      })

      this.totals[params.content_type] = metadata.count
      set(this, `${this.activeTabName}[${this.currentPage}]`, data)
      this.loading = false
    },

    async handlePageChange(page) {
      this.$router.push({
        query: { tab: this.activeTabName, page }
      })

      await this.getArticles({ page_number: page })
    },

    async onTabChange({ props }) {
      if (props.name === this.activeTabName) return

      this.$router.push({
        query: { tab: props.name, page: 1 }
      })

      await this.getArticles({ page_number: 1, content_type: props.name })
    }
  }
})
</script>

<style lang="scss" scoped>
:deep(.el-tab-pane) {
  min-height: 600px;
  display: flex;
  flex-direction: column;
}

.el-tabs__item.is-active {
  background-color: #ffff !important;
}
:deep(.el-tabs__item) {
  font-size: 18px !important;
}
</style>
