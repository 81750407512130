import Accounting from '@/web-app/pages/Accounting/Accounting.vue'
import Reports from '@/web-app/pages/Accounting/Reports/Reports.vue'
import ReportsProfitAndLoss from '@/web-app/pages/Accounting/Reports/ProfitAndLoss.vue'
import ReportsBalance from '@/web-app/pages/Accounting/Reports/Balance.vue'
import ReportsFreeCashFlow from '@/web-app/pages/Accounting/Reports/FreeCashFlow.vue'
import ReportsDiscountedCashFlow from '@/web-app/pages/Accounting/Reports/DiscountedCashFlow.vue'
import ReportsCashFlow from '@/web-app/pages/Accounting/Reports/CashFlow.vue'

import Costs from '@/web-app/pages/Accounting/Costs/Costs.vue'
import CostsList from '@/web-app/pages/Accounting/Costs/List.vue'
import CostCreate from '@/web-app/pages/Accounting/Costs/Create.vue'

import Invoices from '@/web-app/pages/Accounting/Invoices/Invoices.vue'
import InvoicesList from '@/web-app/pages/Accounting/Invoices/List.vue'
import InvoiceCreate from '@/web-app/pages/Accounting/Invoices/Create.vue'

import { RouteRecordRaw } from 'vue-router'

export const routes: RouteRecordRaw[] = [
  {
    path: '/accounting',
    name: 'Accounting',
    component: Accounting,
    redirect: { name: 'AccountingInvoicesList' },
    children: [
      {
        path: 'invoices',
        name: 'AccountingInvoices',
        component: Invoices,
        redirect: { name: 'AccountingInvoicesList' },
        children: [
          {
            path: 'list',
            name: 'AccountingInvoicesList',
            component: InvoicesList
          },
          {
            path: 'item',
            name: 'AccountingInvoiceCreate',
            component: InvoiceCreate
          },
          {
            path: 'item/:id',
            name: 'AccountingInvoiceEdit',
            component: InvoiceCreate
          }
        ]
      },

      {
        path: 'costs',
        name: 'AccountingCosts',
        component: Costs,
        redirect: { name: 'AccountingCostsList' },
        children: [
          {
            path: 'list',
            name: 'AccountingCostsList',
            component: CostsList
          },
          {
            path: 'item/:id',
            name: 'AccountingCostsEdit',
            component: CostCreate
          },
          {
            path: 'item',
            name: 'AccountingCostsCreate',
            component: CostCreate
          }
        ]
      },

      {
        path: 'reports',
        name: 'AccountingReports',
        redirect: { name: 'ReportsProfitAndLoss' },
        component: Reports,
        children: [
          {
            path: 'profit-and-loss',
            name: 'ReportsProfitAndLoss',
            component: ReportsProfitAndLoss
          },
          {
            path: 'balance',
            name: 'ReportsBalance',
            component: ReportsBalance
          },
          {
            path: 'cash-flow',
            name: 'ReportsCashFlow',
            component: ReportsCashFlow
          },
          {
            path: 'free-cash-flow',
            name: 'ReportsFreeCashFlow',
            component: ReportsFreeCashFlow
          },
          {
            path: 'discounted-cash-flow',
            name: 'ReportsDiscountedCashFlow',
            component: ReportsDiscountedCashFlow
          }
        ]
      }
    ]
  }
]

export default routes
