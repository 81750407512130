import { Exclude } from 'class-transformer'
import { isEmpty } from 'lodash'
import { v4 as uuid } from 'uuid'
import { BaseModelWrapper } from '../BaseModel.model'

export class DocumentDetailDescription extends BaseModelWrapper<DocumentDetailDescription>() {
  id: string = uuid()
  product: 'RENT' | 'CAPEX' | 'OPEX' | 'TAX' | 'SUBSCRIPTION' = null
  description = ''
  quantity: number = null
  rate: number = null

  @Exclude({ toPlainOnly: true })
  get value() {
    return this.rate * this.quantity
  }

  @Exclude({ toPlainOnly: true })
  get isValid() {
    return (
      !isEmpty(this.description) && !isEmpty(this.product) && this.quantity > 0 && this.rate > 0
    )
  }
}
