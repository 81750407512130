<template>
  <div
    class="avatar"
    :style="{
      backgroundImage: `url(${src || avatarFallback})`,
      width: size + 'px',
      height: size + 'px'
    }"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import avatarFallback from '@/assets/images/avatarFallback.png'

export default defineComponent({
  props: {
    src: { type: String, default: avatarFallback },
    size: { type: Number, default: 120 }
  },
  data() {
    return { avatarFallback }
  }
})
</script>

<style lang="scss" scoped>
.avatar {
  background-position: center 0%;
  background-size: cover;
  border-radius: 50%;
  border: 1px solid #ebecf0;
}
</style>
