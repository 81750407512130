import api from '@/core/api'
import { withNotify } from '@/common/util/decorators'
import { Article } from '../models'

export default class ArticleService {
  @withNotify({ success: 'messages.successfullyCreated' })
  create(payload: Article) {
    return api.articles.create(payload)
  }

  @withNotify({ success: 'messages.successfullyUpdated' })
  update(id: string, payload: Partial<Article>) {
    return api.articles.update(id, payload)
  }

  @withNotify({ success: 'messages.successfullyDeleted' })
  delete(id: string) {
    return api.articles.delete(id)
  }
}
