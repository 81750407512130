import http from './http'

export default class ManagingCompaniesEntity {
  constructor(private url: string) {}

  get() {
    return http.get('/api/companies/contact/managing_company')
  }

  getCosts(managingCompanyId: string) {
    return http.get(`${this.url}/${managingCompanyId}/costs`)
  }

  getInvoices(managingCompanyId: string) {
    return http.get(`${this.url}/${managingCompanyId}/invoices`)
  }
}
