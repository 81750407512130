import api from '@/core/api'
import { Contact } from '@/core/models'
import { withNotify } from '@/common/util/decorators'
import { groupBy, isNumber } from 'lodash'

export default class ContactsService {
  @withNotify({ success: 'messages.successfullyUpdated' })
  update(id: string, payload: any) {
    return api.contacts.update(id, payload)
  }

  @withNotify({ success: 'messages.successfullyDeleted' })
  delete(id: string) {
    return api.contacts.delete(id)
  }

  groupBySortOption(contacts: Contact[], sortOption: any) {
    const WILDCARD = '#'
    return groupBy(contacts, contact => {
      const sortableValue = contact[sortOption]

      return isNumber(sortableValue)
        ? sortableValue + ' ' // adding space not to refer key as an index of array
        : (sortableValue || WILDCARD)[0].toUpperCase()
    })
  }
}
