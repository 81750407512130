
import { defineComponent, PropType } from 'vue'
import BaseChart from '@/common/components/Chart.vue'
import Chart from 'chart.js'

export default defineComponent({
  components: { BaseChart },
  props: {
    size: { type: Number, default: 160 },
    cutoutPercentage: { type: Number, default: 55 },
    containerClass: { type: String, default: '' },
    data: {
      type: Object as PropType<Chart.ChartData>,
      required: true
    }
  }
})
