<template>
  <div class="real-estate">
    <div class="real-estate__add-property">
      <el-button
        :disabled="loadingEstates || estates.length >= 3"
        type="primary"
        round
        @click="onAddProperty"
      >
        {{ $t('properties.addProperty') }}
      </el-button>
    </div>

    <el-card v-loading="loadingEstates">
      <PaginatedTable
        :data="estates"
        :total-rows="estatesTotalCount"
        @row-click="goToEditPage($event)"
        @page-changed="getEstates($event)"
      >
        <template #default>
          <el-table-column :label="$t('common.name')" prop="name" width="180" />

          <el-table-column :label="$t('common.address')" prop="fullAddress" width="230" />

          <el-table-column :label="$t('common.status')">
            <template #default="{ row }">
              <div>
                <div class="real-estate__status-icon flex-center" @click.stop>
                  <div v-if="!row.status" class="el-icon-fa-check fas" />
                  <div v-else class="el-icon-fa-times fas" />
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column :label="$t('common.report')" width="180">
            <template #default>
              <span class="report-btn" @click.stop> {{ $t('common.view') }} </span>
              <el-divider direction="vertical" />
              <span class="report-btn" @click.stop>{{ $t('common.print') }}</span>
            </template>
          </el-table-column>

          <el-table-column width="40">
            <template #default="{ row: estate }">
              <i class="el-icon-fa-trash-alt fas " @click.stop="confirmAndDeleteEstate(estate)" />
            </template>
          </el-table-column>
        </template>
      </PaginatedTable>
    </el-card>
  </div>
</template>

<script lang="ts">
import api from '@/core/api'
import services from '@/core/services'
import { confirmDeletion } from '@/common/util'
import { reject } from 'lodash'
import { defineComponent } from 'vue'
import PaginatedTable from '@/common/components/PaginatedTable.vue'
import { Estate } from '@/core/models'

export default defineComponent({
  components: { PaginatedTable },

  data: () => ({
    estates: [] as Estate[],
    estatesTotalCount: 0,
    loadingEstates: null
  }),

  async mounted() {
    await this.getEstates()
  },

  methods: {
    async getEstates(page = 1) {
      this.loadingEstates = true
      const { data, metadata } = await api.accounts.getEstates(this.$store.getters.user.id, {
        page_number: page,
        items_per_page: 10
      })

      this.loadingEstates = false
      this.estatesTotalCount = metadata.count
      this.estates = data
    },

    async onAddProperty() {
      try {
        const { value: name } = await this.$prompt(
          this.$t('properties.inputYourPropertyName'),
          this.$t('properties.propertyName'),
          {
            confirmButtonText: this.$t('common.add'),
            cancelButtonText: this.$t('common.cancel')
          }
        )

        const { id } = await services.users.createEstate(name)

        this.$router.push({
          name: 'RealEstateEditAddress',
          params: { id }
        })
      } catch (e) {
        console.log(e)
      }
    },

    async confirmAndDeleteEstate(estate: Estate) {
      try {
        await confirmDeletion(this.$t('messages.willPermanentlyDeleteProperty'))
        await services.estates.delete(estate)

        this.estates = reject(this.estates, { id: estate.id })
      } catch {
        /*  */
      }
    },

    goToEditPage({ id }) {
      this.$router.push({
        name: 'RealEstateItem',
        params: { id }
      })
    }
  }
})
</script>

<style lang="scss" scoped>
.real-estate {
  &__add-property {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  &__status-icon {
    width: 20px;
    height: 20px;
    border: 1px solid #e5e5e5;
    border-radius: 3px;

    .el-icon-fa-check {
      color: #3347d3;
    }

    .el-icon-fa-times {
      color: #ff0102;
    }
  }

  .el-table__row {
    cursor: pointer;
  }

  .report-btn {
    text-decoration: underline;
    cursor: pointer;
  }
}

.el-divider {
  margin: 0 20px;
}

:deep(.el-card__body) {
  min-height: 300px;
}
</style>
