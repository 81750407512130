<template>
  <div v-for="{ id, shortText, title, created } in articles" :key="id" class="article">
    <div class="flex-between align-center">
      <router-link :to="{ name: 'ArticleRead', params: { id } }">
        <h3>{{ title }}</h3>
      </router-link>
      <div class="d-flex">
        <span class="date">{{ $moment(created).format($DATE_FORMAT) }}</span>
        <el-dropdown v-if="!$store.getters.isWebApp" placement="bottom-end" trigger="click">
          <i class="fas el-icon-fa-ellipsis-v"></i>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item icon="el-icon-delete" @click="onDelete(id)">
                {{ $t('common.delete') }}
              </el-dropdown-item>
              <el-dropdown-item icon="el-icon-edit" @click="onEdit(id)">
                {{ $t('common.edit') }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <p>{{ shortText }}</p>
  </div>
  <el-pagination
    v-if="totalArticlesCount - pageSize > 0"
    background
    layout="prev, pager, next"
    :page-size="pageSize"
    :total="totalArticlesCount"
    :current-page="+currentPage"
    :pager-count="5"
    @current-change="handlePageChange"
  />
</template>

<script lang="ts">
import { Article } from '@/core/models'
import { defineComponent, PropType } from '@vue/runtime-core'
import { ARTICLES_PAGE_SIZE, confirmDeletion } from '@/common/util'
import services from '@/core/services'

export default defineComponent({
  name: 'ArticlesList',
  props: {
    articles: {
      type: Array as PropType<Article[]>,
      required: true
    },
    totalArticlesCount: {
      type: Number,
      required: true
    },
    pageSize: {
      type: Number,
      default: ARTICLES_PAGE_SIZE
    },
    currentPage: {
      type: [Number, String],
      required: true
    }
  },

  emits: ['onPageChange', 'onDelete'],

  methods: {
    handlePageChange(page: number) {
      this.$router.push({ query: { ...this.$route.query, page } })
      this.$emit('onPageChange', page)
    },

    onDelete(id: string) {
      confirmDeletion(this.$t('messages.willPermanentlyDeleteArticle')).then(async () => {
        await services.articles.delete(id as string)
        this.$emit('onDelete', id)
      })
    },

    onEdit(id: string) {
      this.$router.push({ name: 'ArticleEdit', params: { id } })
    }
  }
})
</script>

<style lang="scss" scoped>
.el-pagination {
  margin-top: auto;
  padding-top: 20px;
}

.article {
  padding: 20px;
  .date {
    font-size: 13px;
    color: #afafaf;
    margin-right: 20px;
  }
}

p {
  text-align: left;
}

h3 {
  margin: 0;
  text-decoration: underline;
  cursor: pointer;
}
</style>
