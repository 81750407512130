<template>
  <div class="reminder">
    <div class="container">
      <DatePicker v-model="reminder.date" :placeholder="$t('common.date')" />
      <el-input v-model="reminder.description" :placeholder="$t('common.description')" />
      <el-select
        default-first-option
        :model-value="`${$t('common.repeat')} ${$t(`common.${reminder.period}`)}`"
        :placeholder="$t('common.repeatPeriod')"
        @change="e => (reminder.period = e)"
      >
        <el-option v-for="period in periods" :key="period" :value="period" />
      </el-select>
    </div>

    <div class="footer">
      <el-button type="primary" :disabled="!reminder.isValid" @click="onSave">{{
        $t('common.save')
      }}</el-button>
    </div>

    <el-table :data="reminders" style="width: 100%">
      <el-table-column width="140" :label="$t('common.start')">
        <template #default="{row}">
          {{ $moment(row.date).format($DATE_FORMAT) }}
        </template>
      </el-table-column>
      <el-table-column prop="description" :label="$t('common.description')" />
      <el-table-column width="140" :label="$t('common.repeatPeriod')">
        <template #default="{ row}">
          {{ $f.toLowerCaseWithSpaces(row.period) }}
        </template>
      </el-table-column>
      <el-table-column width="40">
        <template #default="{ row}">
          <i class="el-icon-delete pointer" @click="removeReminder(row.id)"></i>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import DatePicker from '@/common/components/DatePicker.vue'
import { useTabChangeConfirm } from '@/common/hooks'
import services from '@/core/services'
import api from '@/core/api'
import { reject } from 'lodash'
import { confirmDeletion } from '@/common/util'
import { Estate, Reminder } from '@/core/models'

export default defineComponent({
  components: { DatePicker },
  props: {
    estate: { type: Object as PropType<Estate>, required: true }
  },

  emits: ['onShouldChangeTab', 'reFetch'],

  data() {
    return {
      periods: ['never', 'weekly', 'monthly', 'threeMonths', 'yearly'],
      reminders: [] as Reminder[],

      reminder: Reminder.create({})
    }
  },

  async mounted() {
    const { data } = await api.estates.getReminders(this.estate['id'])
    this.reminders = data
  },

  created() {
    useTabChangeConfirm({
      shouldConfirm: () => false,
      onNoConfirm: () => this.$emit('onShouldChangeTab', true),
      onConfirm: () => this.$emit('onShouldChangeTab', true),
      onCancel: () => this.$emit('onShouldChangeTab', true)
    })
  },

  methods: {
    async onSave() {
      const { id } = await services.estates.createReminder(
        this.estate['id'],
        this.reminder.transformToDbFormat()
      )

      this.reminder.id = id
      this.reminders.push(this.reminder)

      this.reminder = Reminder.create({})
    },

    async removeReminder(id: string) {
      try {
        await confirmDeletion(this.$t('messages.willPermanentlyDeleteReminder'))
        await services.estates.removeReminder(this.estate['id'], id)
        this.reminders = reject(this.reminders, { id })
      } catch {
        /*  */
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 30px 0;

  & > div {
    display: flex;
    align-items: center;
  }
}

.reminder {
  .el-input {
    flex: 1;
    margin: 0 20px;
  }
}

:deep(.el-date-editor) {
  width: 140px !important;
}
:deep(.el-select) {
  width: 220px !important;
}

.footer {
  text-align: left;
  margin-bottom: 30px;
}
</style>
