import { CRUD } from './CRUD'
import http from './http'

export default class InvoicesEntity<T> extends CRUD<T, Paginated<T[]>> {
  async getPdfUrl(id: string) {
    const blob = await http.get(`${this.url}/${id}/pdf`, {
      responseType: 'blob'
    })

    return window.URL.createObjectURL(new Blob([blob]))
  }
}
