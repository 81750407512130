<template>
  <el-table
    :data="tableData"
    style="width: 100%"
    show-summary
    :sum-text="$t('finances.incomeAfterTaxes')"
  >
    <el-table-column
      v-for="col in columns"
      :key="col"
      :label="forFutureYearsPrependF(col)"
      :prop="col"
      :min-width="col === 'name' ? 200 : 0"
    >
    </el-table-column>
  </el-table>
</template>

<script lang="ts">
import services from '@/core/services'
import { defineComponent } from 'vue'
import { normalizeTableData, forFutureYearsPrependF } from '../utils'

export default defineComponent({
  props: ['reports'],
  data() {
    return {
      tableData: [],
      columns: []
    }
  },

  async mounted() {
    const { tableData, columns } = normalizeTableData(this.reports)

    this.tableData = tableData
    this.columns = columns
  },

  methods: { forFutureYearsPrependF }
})
</script>

<style lang="scss" scoped></style>
