import { Transform, Type } from 'class-transformer'
import { Contact } from '..'
import { DocumentDetail } from './DocumentDetail.model'
import { Estate } from '../Estate.model'
import { pickId } from '../helpers'
import { User } from '../User.model'
import store from '@/core/store'
import { BaseModelWrapper } from '../BaseModel.model'

export class Cost extends BaseModelWrapper<Cost>() {
  checked = false // TODO:

  @Type(() => DocumentDetail)
  details = DocumentDetail.create({})

  costType: string

  @Type(() => Contact)
  @Transform(pickId, { toPlainOnly: true })
  vendor = Contact.create({})

  @Type(() => Estate)
  @Transform(pickId, { toPlainOnly: true })
  estate = Estate.create({})

  @Type(() => User)
  @Transform(pickId, { toPlainOnly: true })
  creatorAccount = User.create({ id: store.getters.user.id })
}
