<template>
  <div class="label">
    <b>{{ $t('messages.uploadUpTo6Photos') }}</b>
  </div>

  <el-upload
    :class="{ 'no-upload': uploadLimitExceeded }"
    multiple
    action="#"
    :limit="IMAGE_LIMIT"
    list-type="picture-card"
    :auto-upload="false"
    :on-change="onPhotoUpload"
    :show-file-list="false"
  >
    <template #default>
      <i class="el-icon-plus"></i>
    </template>
  </el-upload>

  <div class="images-container">
    <div
      v-for="photo in photos"
      :key="photo.name"
      class="image position-relative"
      :style="{ backgroundImage: `url(${photo.url || photo.path})` }"
    >
      <Loader v-if="photo.isUploading" />
      <template v-else>
        <div class="icon-delete-container">
          <span class="el-icon-delete" @click="onPhotoRemove(photo)"> </span>
          <span
            class="el-icon-zoom-in ml-10"
            @click="$store.commit('setPreviewImage', photo.url || photo.path)"
          >
          </span>
        </div>
      </template>
    </div>
  </div>

  <div class="footer">
    <el-button :disabled="hasUploadingInProcess" type="primary" @click="onSave">{{
      $t('common.save')
    }}</el-button>
  </div>
</template>

<script lang="ts">
import { cloneDeep, find, isEqual, reject, some } from 'lodash'
import { defineComponent, PropType } from 'vue'
import { useTabChangeConfirm } from '@/common/hooks'
import services from '@/core/services'
import Loader from '@/common/components/Loader.vue'
import { Estate, IEstatePhoto } from '@/core/models'

type Photo = IUploadedFile & IEstatePhoto & { uid: string; isNew: boolean; isUploading?: boolean }

export default defineComponent({
  components: { Loader },
  props: {
    estate: { type: Object as PropType<Estate>, required: true }
  },
  emits: ['reFetch', 'onShouldChangeTab'],

  data() {
    return {
      dialogVisible: true,
      IMAGE_LIMIT: 6,
      photos: cloneDeep(this.$props.estate['photos']) as Photo[],
      unchangedPhotos: [] as Photo[], // will be initialized after mounted
      removedPhotos: [] as Photo[],

      /**
       *  photo will be uploaded to aws server after
       *  uploadading from pc then after
       *  saving changes will be saved to db.
       *
       *  if user leaves page without saving changes all
       *  uploads in this list should be removed from aws
       */
      awsUploadedPhotos: [] as Photo[]
    }
  },

  computed: {
    uploadLimitExceeded(): boolean {
      return this.photos.length >= this.IMAGE_LIMIT
    },

    hasUploadingInProcess(): boolean {
      return some(this.photos, 'isUploading')
    }
  },

  mounted() {
    /**
     *  el-upload is modifing initial state if it exists
     *  so in order to have fresh state we are setting
     *  unchangedPhotos in mounted  phase
     */
    this.unchangedPhotos = cloneDeep(this.photos)
  },

  created() {
    useTabChangeConfirm({
      shouldConfirm: () => !isEqual(this.photos, this.unchangedPhotos),
      onNoConfirm: () => this.$emit('onShouldChangeTab', true),
      onConfirm: () => {
        this.onSave()
        this.$emit('onShouldChangeTab', true)
      },
      onCancel: () => {
        if (this.awsUploadedPhotos.length) {
          services.awsImageUploader.removeMany(this.awsUploadedPhotos)
        }
        this.$emit('onShouldChangeTab', true)
      }
    })
  },

  methods: {
    async onPhotoUpload(file: IUploadedFile, files: IUploadedFile[]) {
      if (this.uploadLimitExceeded) {
        return this.notifyPhotoLimitExceeded()
      }

      const fileName = this.$props.estate['id'] + '/' + Date.now() + file.name
      const isMain = files.length === 1

      this.photos.push({
        ...file,
        name: fileName,
        uid: file.raw.uid,
        url: file.url,
        path: file.url,
        isMain,
        isNew: true,
        isUploading: true
      })

      const path = await this.uploadPhotoToAws(fileName, file.raw, { isMain })

      this.photos = this.photos.map(photo => {
        return photo.name === fileName ? { ...photo, isUploading: false, path } : photo
      })
    },

    async uploadPhotoToAws(name: string, file: File, meta: Partial<Photo> = {}) {
      const path = await services.awsImageUploader.upload(name, file)
      this.awsUploadedPhotos.push({ path, name, ...meta } as Photo)

      return path
    },

    async onSave() {
      if (this.removedPhotos.length) {
        await services.awsImageUploader.removeMany(this.removedPhotos)
        this.removedPhotos = []
      }

      const oldPhotos = reject(this.photos, 'isNew')

      const payload: IEstatePhoto[] = [
        ...oldPhotos,
        ...this.awsUploadedPhotos
      ].map(({ isMain, path }) => ({ isMain, path }))

      await services.estates.update(this.$props.estate['id'], {
        photos: payload
      })

      this.unchangedPhotos = cloneDeep(this.photos)
      this.$emit('reFetch')
    },

    async onPhotoRemove(removedPhoto: Photo) {
      this.photos = reject(this.photos, ({ path }) => {
        return path === removedPhoto.path
      })

      if (!removedPhoto.isNew) {
        this.removedPhotos.push(removedPhoto)
        return
      }

      // remove from aws
      const photo = find(this.awsUploadedPhotos, {
        name: removedPhoto.name
      })

      if (photo) {
        await services.awsImageUploader.remove(photo.path)
        this.awsUploadedPhotos = reject(this.awsUploadedPhotos, {
          path: photo.path
        })
      }
    },

    notifyPhotoLimitExceeded() {
      this.$notify({
        duration: 0,
        type: 'error',
        title: this.$t('common.photoUploadLimitExceeded'),
        message: this.$t('messages.pleaseRemovePhotoAndTryAgain')
      })
    }
  }
})
</script>

<style lang="scss" scoped>
.label {
  margin: 20px 0;
}
.no-upload {
  :deep(.el-upload) {
    display: none;
  }
}

.icon-delete-container {
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  transition: all 0.3s;
  color: white;
  opacity: 0;

  &:hover {
    opacity: 1;
    background: rgba(0, 0, 0, 0.4);
  }

  span {
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      transform: scale(1.1);
    }
  }

  .el-icon-delete {
    font-size: 20px;
  }
}
.images-container {
  display: flex;
  flex-wrap: wrap;
  width: calc(4 * 148px + 4 * 15px);
  margin: 30px auto;
}

.image {
  background-size: cover;
  background-repeat: no-repeat;
  width: 148px;
  height: 148px;
  border-radius: 6px;
  margin-left: 15px;
  margin-top: 15px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
}

.el-upload-list__item {
  div:first-child {
    height: 100%;
  }
}

.footer {
  padding: 0 30px;
  text-align: left;
}
</style>
