
import { defineComponent, PropType } from 'vue'
import DatePicker from '@/common/components/DatePicker.vue'
import { useTabChangeConfirm } from '@/common/hooks'
import services from '@/core/services'
import api from '@/core/api'
import { reject } from 'lodash'
import { confirmDeletion } from '@/common/util'
import { Estate, Reminder } from '@/core/models'

export default defineComponent({
  components: { DatePicker },
  props: {
    estate: { type: Object as PropType<Estate>, required: true }
  },

  emits: ['onShouldChangeTab', 'reFetch'],

  data() {
    return {
      periods: ['never', 'weekly', 'monthly', 'threeMonths', 'yearly'],
      reminders: [] as Reminder[],

      reminder: Reminder.create({})
    }
  },

  async mounted() {
    const { data } = await api.estates.getReminders(this.estate['id'])
    this.reminders = data
  },

  created() {
    useTabChangeConfirm({
      shouldConfirm: () => false,
      onNoConfirm: () => this.$emit('onShouldChangeTab', true),
      onConfirm: () => this.$emit('onShouldChangeTab', true),
      onCancel: () => this.$emit('onShouldChangeTab', true)
    })
  },

  methods: {
    async onSave() {
      const { id } = await services.estates.createReminder(
        this.estate['id'],
        this.reminder.transformToDbFormat()
      )

      this.reminder.id = id
      this.reminders.push(this.reminder)

      this.reminder = Reminder.create({})
    },

    async removeReminder(id: string) {
      try {
        await confirmDeletion(this.$t('messages.willPermanentlyDeleteReminder'))
        await services.estates.removeReminder(this.estate['id'], id)
        this.reminders = reject(this.reminders, { id })
      } catch {
        /*  */
      }
    }
  }
})
