import api from '@/core/api'
import store from '@/core/store'
import { isEmpty } from 'lodash'

export default class CountryService {
  async get() {
    if (isEmpty(store.getters.countries)) {
      const { data } = await api.countries.get({
        params: { items_per_page: 0 }
      })

      store.commit('setCountries', data)
    }

    return store.getters.countries
  }
}
