import RealEstate from '@/web-app/pages/RealEstate/RealEstate.vue'
import List from '@/web-app/pages/RealEstate/List.vue'

import Item from '@/web-app/pages/RealEstate/Edit/Item.vue'
import EditAddress from '@/web-app/pages/RealEstate/Edit/Address.vue'
import EditOwnerAddress from '@/web-app/pages/RealEstate/Edit/OwnerAddress.vue'
import EditManagement from '@/web-app/pages/RealEstate/Edit/Management.vue'
import EditPhotos from '@/web-app/pages/RealEstate/Edit/Photos.vue'
import Finance from '@/web-app/pages/RealEstate/Edit/Finance.vue'
import Reminder from '@/web-app/pages/RealEstate/Edit/Reminder.vue'
import { RouteRecordRaw } from 'vue-router'

export const routes: RouteRecordRaw[] = [
  {
    path: '/real-estate',
    name: 'RealEstate',
    component: RealEstate,
    redirect: { name: 'RealEstateList' },
    children: [
      {
        path: 'list',
        name: 'RealEstateList',
        component: List
      },

      {
        path: 'edit/:id',
        name: 'RealEstateItem',
        props: true,
        component: Item,
        redirect: { name: 'RealEstateEditAddress' },
        children: [
          {
            path: 'address',
            name: 'RealEstateEditAddress',
            component: EditAddress
          },
          {
            path: 'owner-address',
            name: 'RealEstateEditOwnerAddress',
            component: EditOwnerAddress
          },
          {
            path: 'management',
            name: 'RealEstateEditManagement',
            component: EditManagement
          },
          {
            path: 'photos',
            name: 'RealEstateEditPhotos',
            component: EditPhotos
          },
          {
            path: 'finance',
            name: 'RealEstateFinance',
            component: Finance
          },
          {
            path: 'reminder',
            name: 'RealEstateReminder',
            component: Reminder
          }
        ]
      }
    ]
  }
]

export default routes
