import AuthService from './AuthService'
import CityService from './CityService'
import CountryService from './CountryService'
import EstateService from './EstateService'
import UserService from './UserService'
import RegionService from './RegionService'
import FinanceService from './FinanceService'
import { AwsS3ImageUploadingService } from './AwsS3ImageUploadingService'
import InvoicesService from './InvoicesService'
import CostsService from './CostsService'
import ContactsService from './ContactsService'
import { CalculatorService } from './CalculatorService'
import ArticleService from './ArticleService'

export default {
  auth: new AuthService(),
  articles: new ArticleService(),
  users: new UserService(),
  estates: new EstateService(),
  country: new CountryService(),
  city: new CityService(),
  region: new RegionService(),
  finances: new FinanceService(),
  awsImageUploader: new AwsS3ImageUploadingService(),
  invoices: new InvoicesService(),
  costs: new CostsService(),
  contacts: new ContactsService(),
  calculator: new CalculatorService()
}
