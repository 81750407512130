import { Address } from './Address.model'
import { Exclude, Transform, Type } from 'class-transformer'
import { User } from './User.model'
import { pickId } from './helpers'
import { BaseModelWrapper } from './BaseModel.model'

export class Contact extends BaseModelWrapper<Contact>() {
  id: string
  name: string
  surname: string
  companyName: string
  isCompany: boolean
  description: string
  email: string
  isManagingCompany: boolean
  isTenant: boolean
  isVendor: boolean
  linkedAccount: string
  phone: string
  photo: string
  rate: 0 | 1 | 2 | 3 | 4 | 5
  taxNumber: string

  @Type(() => Address)
  address = Address.create({})

  @Type(() => User)
  @Transform(pickId, { toPlainOnly: true })
  creatorAccount: string

  @Exclude({ toPlainOnly: true })
  getFullName(): string {
    return `${this.name || ''} ${this.surname || ''} ${
      this.companyName ? ' - ' + this.companyName : ''
    }`.trim()
  }
}
