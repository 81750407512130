<template>
  <el-card>
    <div class="cost">
      <div class="cost-left">
        <div class="inputField">
          <span class="label white-space-normal">{{ $t('common.vendor') }}</span>
          <el-select
            :model-value="cost.vendor?.id"
            filterable
            :placeholder="$t('common.vendor')"
            @change="$event => (cost.vendor = vendors.find(e => e.id === $event))"
          >
            <el-option
              v-for="vendor in vendors"
              :key="vendor.id"
              :value="vendor.id"
              :label="vendor.getFullName()"
            />
          </el-select>
        </div>

        <div class="inputField">
          <span class="label">{{ $t('common.estate') }}</span>

          <el-select
            :placeholder="$t('common.estate')"
            :model-value="cost.estate?.id"
            filterable
            @change="$event => (cost.estate = estates.find(e => e.id === $event))"
          >
            <el-option
              v-for="estate in estates"
              :key="estate.id"
              :value="estate.id"
              :label="estate.name"
            />
          </el-select>
        </div>

        <!-- <div class="inputField">
          <span class="label">{{ $t('form.email') }}</span>
          <el-input v-model="cost.email" :placeholder="$t('cost.email')" />
        </div> -->

        <!-- <div class="inputField">
          <span class="label">{{ $t('common.address') }}</span>
          <el-input
            v-model="cost.address"
            type="textarea"
            resize="none"
            :placeholder="$t('common.address')"
          />
        </div> -->

        <div class="inputField">
          <span class="label">{{ $t('common.terms') }}</span>
          <el-select
            v-model="selectedTerm"
            filterable
            :placeholder="$t('common.terms')"
            @change="selectIntervalFromToday"
          >
            <el-option
              v-for="interval in terms"
              :key="interval"
              :value="interval"
              :label="`${interval} days`"
            />
          </el-select>
        </div>
      </div>
      <div class="cost-right">
        <div class="mb-20">
          <div class="cost__heading">
            {{ $t('common.cost').toUpperCase() }}
          </div>
          <div v-if="cost.details.number" class="cost__number mt-10">
            <span> No.</span> {{ cost.details.number }}
          </div>
        </div>

        <div class="flex-between">
          <div class="cost__dates">
            <div class="inputField">
              <span class="label">{{ $t('common.cost') }} {{ $t('common.date') }}</span>
              <DatePicker
                v-model="cost.details.issued"
                :placeholder="$t('common.date')"
                @change="ensureEndDateToBeAfterStart"
              />
            </div>

            <div class="inputField">
              <span class="label">{{ $t('common.due') }}</span>
              <DatePicker
                v-model="cost.details.due"
                :disabled-date="end => $moment(end).isBefore(cost.details.issued)"
                :placeholder="$t('common.date')"
                @change="selectedTerm = null"
              />
            </div>
          </div>
          <div class="cost__total">
            <div>{{ $t('finances.total') }}</div>
            <div>{{ $f.currency(cost.details.totalWithTax) }}</div>
          </div>
        </div>
      </div>
    </div>

    <el-form ref="tableForm" :rules="tableValidationRules" :model="cost.details.description">
      <el-table :data="cost.details.description" class="mt-30 mb-30">
        <el-table-column type="index" label="No" />

        <el-table-column :label="`${$t('common.product')}/${$t('common.service')}`" width="180">
          <template #default="{ row, $index }">
            <el-form-item
              class="mt-15"
              :prop="$index + '.product'"
              :rules="tableValidationRules.product"
            >
              <el-select v-model="row.product" placeholder="Select">
                <el-option v-for="product in products" :key="product" :value="product"> </el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column :label="$t('common.description')">
          <template #default="{row, $index}">
            <el-form-item
              class="w-100 mt-15"
              :prop="$index + '.description'"
              :rules="tableValidationRules.description"
            >
              <el-input
                v-model="row.description"
                :autosize="true"
                resize="none"
                :placeholder="$t('common.shortDescription')"
                type="textarea"
              />
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column width="80" :label="$t('common.qty')">
          <template #default="{row, $index}">
            <el-form-item
              class="mt-15"
              :prop="$index + '.quantity'"
              :rules="tableValidationRules.quantity"
            >
              <el-input v-model="row.quantity" type="number" />
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column width="80" :label="$t('finances.rate')">
          <template #default="{row, $index}">
            <el-form-item class="mt-15" :prop="$index + '.rate'" :rules="tableValidationRules.rate">
              <el-input v-model="row.rate" type="number" />
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column width="100" :label="$t('finances.amount')">
          <template #default="{ row }">{{ $f.currency(row.value) }}</template>
        </el-table-column>
        <el-table-column width="40">
          <template #default="{ row }">
            <i
              v-if="cost.details.description.length > 1"
              class="el-icon-fa-trash-alt fas "
              @click.stop="confirmAndDelete(row.id)"
            />
          </template>
        </el-table-column>
      </el-table>
    </el-form>

    <div class="flex-between">
      <div>
        <el-button @click="cost.details.addDescription()">{{ $t('common.addLines') }}</el-button>
        <el-button @click="cost.details.clearDescriptions()">
          <!-- <el-button @click="addDetailDescriptonRow">{{ $t('common.addLines') }}</el-button> -->
          <!-- <el-button @click="clearTableRows"> -->
          {{ $t('common.clearAllLines') }}
        </el-button>
        <!-- <el-button>{{ $t('common.addSubtotal') }}</el-button> -->
      </div>
      <span class="text-right">
        <span class="main-color"> {{ $t('finances.tax') }}</span>
        <el-input-number
          v-model="cost.details.tax"
          class="tax-input"
          :controls="false"
          :min="0"
          :max="100"
          @change="val => (cost.details.tax = val || 0)"
        />
        <i class="main-color fas el-icon-fa-percent"></i>
      </span>
    </div>

    <div class="cost__total-with-taxes">
      <div class="flex-between">
        <span>{{ $t('finances.total') }}</span>
        <span>{{ $f.currency(cost.details.totalWithTax) }}</span>
      </div>
      <div class="flex-between">
        <span>{{ $t('finances.balanceDue') }}</span>
        <span>{{ $f.currency(cost.details.totalWithTax) }}</span>
      </div>
    </div>

    <div class="text-right">
      <el-button :disabled="!cost.details.descriptionsAreValid" type="primary" @click="save">
        {{ $t('common.save') }}
      </el-button>
      <!-- <el-button :disabled="!canSave" type="primary">{{ $t('common.saveAndSend') }}</el-button> -->
    </div>
  </el-card>
</template>

<script lang="ts">
import api from '@/core/api'
import DatePicker from '@/common/components/DatePicker.vue'
import { Estate, Contact, Cost } from '@/core/models'
import services from '@/core/services'
import { pick } from 'lodash'
import moment from 'moment'
import { defineComponent } from 'vue'
import { confirmDeletion } from '@/common/util'

export default defineComponent({
  components: {
    DatePicker
  },
  data() {
    return {
      products: ['CAPEX', 'OPEX', 'TAX'],
      estates: [] as Estate[],
      vendors: [] as Contact[],
      terms: [5, 14, 30],
      selectedTerm: null,

      cost: Cost.create({}),
      //   email: null,
      //   address: null

      tableValidationRules: {
        product: [{ required: true, message: this.$t('common.required') }],
        description: [{ required: true, message: this.$t('common.required') }],
        quantity: [{ required: true, message: this.$t('common.required') }],
        rate: [{ required: true, message: this.$t('common.required') }]
      }
    }
  },

  async mounted() {
    if (this.$route.params.id) {
      const cost = await api.costs.getById(this.$route.params.id as string)
      if (cost) {
        this.cost = cost
        this.vendors = cost.vendor ? [cost.vendor] : []
        this.estates = cost.estate ? [cost.estate] : []
      }
    }

    const [estates, vendors] = await Promise.all([
      services.users.getEstates(),
      services.users.getContacts({ type: 'vendor' })
    ])

    this.vendors = vendors.data
    this.estates = estates.data
  },

  methods: {
    async save() {
      let formIsInvalid: boolean
      this.$refs.tableForm.validate(isValid => (formIsInvalid = !isValid))

      if (formIsInvalid) return

      const payloadKeys = ['details', 'vendor', 'estate', 'creatorAccount', 'id'] as (keyof Cost)[]

      const payload = pick(this.cost.transformToDbFormat(), payloadKeys)

      this.$route.params.id
        ? await services.costs.update(this.$route.params.id as string, payload)
        : await services.costs.create(payload)

      this.$router.push({ name: 'AccountingCostsList' })
    },

    confirmAndDelete(id) {
      confirmDeletion(this.$t('messages.willPermanentlyDeleteCost')).then(() => {
        this.cost.details.removeDescription(id)
      })
    },

    selectIntervalFromToday(interval: number) {
      this.cost.details.issued = moment().format()
      this.cost.details.due = moment()
        .add(interval, 'days')
        .format()
    },

    ensureEndDateToBeAfterStart(startDate: string) {
      if (this.cost.details.due && moment(startDate).isSameOrAfter(this.cost.details.due)) {
        this.cost.details.due = null
      }
      this.selectedTerm = null
    }
  }
})
</script>

<style lang="scss" scoped>
:deep(.el-card__body) {
  padding: 40px 30px;

  .el-form-item__content {
    margin: 0 !important;
    width: 100% !important;

    .el-textarea__inner {
      min-height: 40px !important;
      padding: 8px 15px !important;
    }

    .el-input__inner {
      padding: 5px;
    }
  }
}

.cost {
  display: flex;
  justify-content: space-between;

  &-left {
    width: 35%;
  }

  &-right {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .cost__dates {
    flex-direction: column;
    width: 65%;
  }

  @media (max-width: 1279px) {
    &-left {
      width: 30%;
    }

    &-right {
      width: 65%;
    }
  }

  &__heading {
    text-align: right !important;
    font-size: 25px;
    font-weight: bolder;
    text-align: center;
    color: #409eff;
  }

  &__number {
    text-align: right;
    padding: 6px 21px 6px 28px;
    width: 200px;
    margin-left: auto;
    white-space: nowrap;
    background: rgba(51, 70, 211, 0.05);

    span {
      margin-right: 10px;
    }
  }

  &__dates {
    display: flex;

    .inputField {
      display: block;
      align-self: flex-end;

      .label {
        margin-right: 10px;
      }
    }
    :deep(.el-input) {
      width: 140px;
    }

    :deep(.el-input__prefix) {
      display: none;
    }
    :deep(.el-input__inner) {
      padding-left: 10px !important;
    }
  }

  &__total {
    margin-left: 10px;

    div {
      font-weight: bolder;
    }
    & > div:first-child {
      margin-bottom: 5px;
      font-size: 18px;
    }
  }

  &__total-with-taxes {
    width: 220px;
    margin-left: auto;
    margin-top: 16px;
    margin-bottom: 50px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 450;

    .flex-between {
      padding: 6px 21px 6px 28px;
      min-width: 200px;
      white-space: nowrap;

      span:first-child {
        margin-right: 10px;
      }
    }

    .flex-between:last-child {
      background: rgba(51, 70, 211, 0.05);
    }
  }
}

.el-table {
  .el-table__row:hover td {
    background: white;
  }

  .el-textarea__inner {
    min-height: 40px !important;
    padding: 8px 15px !important;
  }

  .el-input__inner {
    padding: 5px;
  }
}

.tax-input {
  width: 30%;
  margin: 0 10px;
}
</style>
