<template>
  <canvas ref="canvasRef">
    <slot>Your browser does not support canvas</slot>
  </canvas>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  toRefs,
  watch
} from 'vue'
import Chart from 'chart.js'
import { get, has, keys } from 'lodash'

export default defineComponent({
  name: 'Chart',
  props: {
    type: {
      type: String as PropType<Chart.ChartType>,
      required: true
    },
    data: {
      type: Object as PropType<Chart.ChartData>,
      required: true
    },
    options: {
      type: Object as PropType<Chart.ChartOptions>,
      default: () => ({})
    },
    plugins: {
      type: Object as PropType<Chart.PluginServiceRegistrationOptions[]>,
      default: () => ({})
    }
  },

  setup(props) {
    const canvasRef = ref<HTMLCanvasElement>()
    let chart = reactive({} as Chart)

    const { data } = toRefs(props)
    watch(data, watchDataChanges)

    onMounted(() => {
      renderChart(props)
    })

    function renderChart({ type, data, plugins, options }) {
      if (chart?.destroy) chart.destroy()

      chart = new Chart(canvasRef.value!.getContext('2d')!, {
        type,
        data,
        options: { aspectRatio: 1, responsive: true, ...options },
        plugins
      })
    }

    function watchDataChanges(
      newData: Chart.ChartData,
      oldData: Chart.ChartData
    ) {
      if (oldData) {
        // Get new and old DataSet Labels
        const newDatasetLabels = newData.datasets?.map(({ label }) => label)
        const oldDatasetLabels = oldData.datasets?.map(({ label }) => label)

        // Stringify 'em for easier compare
        const oldLabels = JSON.stringify(oldDatasetLabels)
        const newLabels = JSON.stringify(newDatasetLabels)

        // Check if Labels are equal and if dataset length is equal
        if (
          newLabels === oldLabels &&
          oldData.datasets?.length === newData.datasets?.length
        ) {
          newData.datasets?.forEach((dataset, i) => {
            // Get new and old dataset keys
            const oldDatasetKeys = keys(get(oldData, `datasets[${i}]`))
            const newDatasetKeys = keys(dataset)

            // Get keys that aren't present in the new data
            const deletionKeys = oldDatasetKeys.filter(key => {
              return key !== '_meta' && newDatasetKeys.indexOf(key) === -1
            })

            // Remove outdated key-value pairs
            deletionKeys.forEach(deletionKey => {
              delete chart.data.datasets![i][deletionKey]
            })

            // Update attributes individually to avoid re-rendering the entire chart
            for (const attribute in dataset) {
              if (has(dataset, attribute)) {
                chart.data.datasets![i][attribute] = dataset[attribute]
              }
            }
          })

          if (has(newData, 'labels')) {
            chart.data.labels = newData.labels
          }
          chart.update()
        } else {
          if (chart) {
            chart.destroy()
          }

          renderChart(props)
        }
      } else {
        if (chart) {
          chart.destroy()
        }

        renderChart(props)
      }
    }

    return { canvasRef }
  }
})
</script>
