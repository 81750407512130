import { Type, Transform, TransformFnParams } from 'class-transformer'
import { isString } from 'lodash'
import { Address } from './Address.model'
import { BaseModelWrapper } from './BaseModel.model'

export class EstateOwner extends BaseModelWrapper<EstateOwner>() {
  id: string
  name = ''
  isCompany = false

  @Type(() => Address)
  address = Address.create({})

  @Transform(stringifyValue, { toPlainOnly: true })
  @Transform(parseValue, { toClassOnly: true })
  metadata = {
    phone: '',
    licenseNumber: '',
    birthday: ''
  }
}

function stringifyValue({ value }: TransformFnParams) {
  return JSON.stringify(value)
}

function parseValue({ value }: TransformFnParams) {
  try {
    return isString(value) ? JSON.parse(value) : value
  } catch {
    return value
  }
}
