
import services from '@/core/services'
import { defineComponent } from 'vue'
import { normalizeTableData, forFutureYearsPrependF } from '../utils'

export default defineComponent({
  props: ['reports'],
  data() {
    return {
      tableData: [],
      columns: []
    }
  },

  async mounted() {
    const { tableData, columns } = normalizeTableData(this.reports)

    this.tableData = tableData
    this.columns = columns
  },

  methods: { forFutureYearsPrependF }
})
