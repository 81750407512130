
import { defineComponent } from 'vue'
import avatarFallback from '@/assets/images/avatarFallback.png'

export default defineComponent({
  props: {
    src: { type: String, default: avatarFallback },
    size: { type: Number, default: 120 }
  },
  data() {
    return { avatarFallback }
  }
})
