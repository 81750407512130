<template>
  <Toolbar
    :icons="[
      { icon: 'fas el-icon-fa-pen', onClick: goToEditInvoicePage },
      { icon: 'fas el-icon-fa-file-pdf', onClick: downloadPdf }
    ]"
  />

  <div class="flex-between">
    <DocumentPreviewItemsList
      :header="$t('common.allInvoices')"
      :total-documents-count="totalInvociesCount"
      :loading="loading"
      @on-create="$router.push({ name: 'AdminInvoiceCreate' })"
      @on-page-change="getInvoices($event)"
    >
      <DocumentPreviewItem
        v-for="invoice in invoices"
        :key="invoice.id"
        :class="{ active: invoice.id === selectedInvoice?.id }"
        :to="invoice.recipientAccount"
        :details="invoice.details"
        @click="selectInvoice(invoice.id)"
      >
        <span class="primary">SENT</span>
      </DocumentPreviewItem>
    </DocumentPreviewItemsList>

    <DocumentSkeleton v-if="loading && !selectedInvoice" />
    <Document
      v-if="selectedInvoice"
      :label="$t('finances.taxInvoice')"
      :from="selectedInvoice.creatorAccount"
      :to="selectedInvoice.recipientAccount"
      :details="selectedInvoice.details"
    />
  </div>
</template>

<script lang="ts">
import { AdminInvoice } from '@/core/models'
import { find } from 'lodash'
import { defineComponent } from 'vue'
import Document from '@/common/components/Accounting/Document.vue'
import DocumentPreviewItem from '@/common/components/Accounting/DocumentPreviewItem.vue'
import DocumentSkeleton from '@/common/components/Accounting/DocumentSkeleton.vue'
import api from '@/core/api'
import Toolbar from '@/common/components/Accounting/Toolbar.vue'
import DocumentPreviewItemsList from '@/common/components/Accounting/DocumentPreviewItemsList.vue'
import { download } from '@/common/util'

export default defineComponent({
  components: {
    Document,
    DocumentPreviewItem,
    DocumentSkeleton,
    Toolbar,
    DocumentPreviewItemsList
  },
  data() {
    return {
      loading: null,
      totalInvociesCount: null,
      PAGE_SIZE: 4,
      invoices: [] as AdminInvoice[],
      selectedInvoice: null as AdminInvoice
    }
  },

  async mounted() {
    await this.getInvoices()
  },

  methods: {
    async getInvoices(page = 1) {
      this.loading = true
      const { data, metadata } = await api.adminInvoices.get({
        params: {
          page_number: page,
          items_per_page: this.PAGE_SIZE
        }
      })
      this.loading = false

      this.totalInvociesCount = metadata.count
      this.invoices = data

      this.selectInvoice(this.invoices[0]?.id)
    },

    selectInvoice(id: string) {
      if (id) {
        this.selectedInvoice = find(this.invoices, { id })
      }
    },

    goToEditInvoicePage() {
      if (this.selectedInvoice?.id) {
        this.$router.push({
          name: 'AdminInvoiceEdit',
          params: { id: this.selectedInvoice.id }
        })
      }
    },

    async downloadPdf() {
      if (this.selectedInvoice?.id) {
        const url = await api.adminInvoices.getPdfUrl(this.selectedInvoice.id)

        download(url, `invoice-${this.selectedInvoice.details.number}`)
      }
    }
  }
})
</script>
