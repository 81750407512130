import http from '@/core/api/http'
import config from '@/core/auth/config.json'

export default class AuthService {
  private token = ''

  private async getToken(): Promise<string> {
    const { tokenUrl, ...body } = config.api
    const { tokenType, accessToken } = await http.post(tokenUrl, body)

    this.token = `${tokenType} ${accessToken}`
    console.log({ token: this.token })

    return this.token
  }

  async setHeaders() {
    http.setHeader('Authorization', await this.getToken())
  }
}
