<template>
  <div class="common-layout">
    <el-container>
      <NavBar class="nav-bar" />
      <el-container class="main-content content-height">
        <el-header v-if="$auth.isAuthenticated" height="96px">
          <Header />
        </el-header>
        <el-container>
          <el-main class="main-container" :class="{ 'no-header': !$auth.isAuthenticated }">
            <router-view />
          </el-main>
          <div v-if="rightNavIsCollapsed" class="mt-15 mr-5">
            <el-button
              size="small"
              icon="el-icon-arrow-left"
              circle
              @click="rightNavIsCollapsed = false"
            ></el-button>
          </div>
          <el-aside
            class="right-nav"
            :class="{
              'no-header': !$auth.isAuthenticated,
              collapsed: rightNavIsCollapsed
            }"
          >
            <Aside @hide="rightNavIsCollapsed = true" />
          </el-aside>
        </el-container>
      </el-container>
    </el-container>
  </div>
  <ImagePreview v-if="previewImage" :image="previewImage" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import NavBar from './core/layout/NavBar.vue'
import Header from './core/layout/Header.vue'
import Aside from './core/layout/Aside.vue'
import ImagePreview from '@/common/components/ImagePreview.vue'
import { mapGetters } from 'vuex'
import { useAuth, useIdle } from './common/hooks'

export default defineComponent({
  name: 'App',
  components: {
    NavBar,
    Header,
    Aside,
    ImagePreview
  },
  setup() {
    const auth = useAuth()

    const tenMins = 10 * 60 * 1000
    useIdle({
      durationMs: tenMins,
      onCountEnd: () => auth.signOut()
    })
  },

  data() {
    return { rightNavIsCollapsed: false }
  },

  computed: mapGetters(['previewImage'])
})
</script>

<style lang="scss">
@import '@/common/styles/global.scss';

.el-header {
  position: relative;
  box-shadow: 5px 0px 5px rgba(0, 0, 0, 0.1);
  z-index: 3;
  padding-left: 48px !important;
  padding-right: 42px !important;
}

.main-content {
  padding-left: 192px;

  & > .el-container {
    overflow: hidden;
    background: #f7f7fe;
  }
}

.main-container,
.right-nav {
  &.no-header {
    height: 100vh;
  }
}
.content-height {
  height: 100vh;
}
.right-nav {
  position: relative;
  background: white;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  z-index: 2;
  padding: 30px;
  width: 21.8vw !important;
  transition: all 0.3s;

  &.collapsed {
    width: 0vw !important;
    padding: 30px 0;
  }
}

.el-main {
  position: relative;
  z-index: 1;
  background-color: #f7f7ff;
  text-align: center;
  padding: 48px !important;

  @media (max-width: 1400px) {
    padding: 48px 20px !important;
  }
}

.el-card {
  border-radius: 15px !important;
}

.el-table__row {
  cursor: pointer;
}

.el-icon-fa-trash-alt {
  color: #909399;
  cursor: pointer;

  &:hover {
    color: darken(#909399, 50%);
  }
}
</style>
