<template>
  <div class="container">
    <div class="header mb-25">
      <div class="icon-group">
        <div @click="goToEditCostsPage">
          <i class="fas el-icon-fa-pen" />
        </div>
      </div>
    </div>
    <div class="flex-between wrapper">
      <el-card class="all-costs" :header="$t('common.allCosts')">
        <router-link :to="{ name: 'AccountingCostsCreate' }">
          <el-button size="small" round type="primary"> {{ $t('common.new') }} + </el-button>
        </router-link>
        <div class="flex-column">
          <template v-if="loading">
            <DocumentPreviewSkeleton v-for="id in [1, 2, 3, 4]" :key="id" />
          </template>
          <DocumentPreview
            v-for="cost in costs"
            v-else
            :key="cost.id"
            :class="{ 'card-active': cost.id === selectedCost?.id }"
            :to="cost.vendor"
            :details="cost.details"
            @click="selectCost(cost.id)"
          >
            <span class="primary">PAY</span>
          </DocumentPreview>
        </div>
        <el-pagination
          v-if="totalCostsCount - PAGE_SIZE > 0"
          class="mt-20 mb-20"
          background
          layout="prev, pager, next"
          :page-size="PAGE_SIZE"
          :total="totalCostsCount"
          :pager-count="5"
          @current-change="getCosts($event)"
        />
      </el-card>
      <DocumentSkeleton v-if="loading && !selectedCost" />
      <Document
        v-if="selectedCost"
        :label="$t('finances.costRecord').toUpperCase()"
        :from="selectedCost.estate.owners[0]"
        :to="selectedCost.vendor"
        :estate="selectedCost.estate"
        :details="selectedCost.details"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Cost } from '@/core/models'
import services from '@/core/services'
import { find, last } from 'lodash'
import { defineComponent } from 'vue'
import Document from '@/common/components/Accounting/Document.vue'
import DocumentPreview from '@/common/components/Accounting/DocumentPreviewItem.vue'
import DocumentSkeleton from '@/common/components/Accounting/DocumentSkeleton.vue'
import DocumentPreviewSkeleton from '@/common/components/Accounting/DocumentPreviewSkeleton.vue'

export default defineComponent({
  components: { Document, DocumentPreview, DocumentSkeleton, DocumentPreviewSkeleton },
  data() {
    return {
      loading: true,
      totalCostsCount: null,
      PAGE_SIZE: 4,
      costs: [] as Cost[],
      selectedCost: null as Cost
    }
  },

  async mounted() {
    await this.getCosts()
    this.loading = false
  },

  methods: {
    selectCost(id: string) {
      if (id) {
        this.selectedCost = find(this.costs, { id })
      }
    },

    async getCosts(page = 1) {
      this.loading = true
      const { data, metadata } = await services.users.getCosts({
        page_number: page,
        items_per_page: this.PAGE_SIZE
      })
      this.loading = false

      this.totalCostsCount = metadata.count
      this.costs = data

      this.loading = false
      this.selectCost(last(this.costs)?.id)
    },

    goToEditCostsPage() {
      if (this.selectedCost?.id) {
        this.$router.push({
          name: 'AccountingCostsEdit',
          params: { id: this.selectedCost.id }
        })
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.percentage {
  position: absolute;
  font-size: 6px;
  transform: translate(1px, 10px);
}

.wrapper {
  margin-top: 30px;
}

.all-costs {
  width: 40%;
  margin-right: 20px;
  white-space: nowrap;
  align-self: start;
  :deep(.el-card__header) {
    color: gray;
  }

  .el-button {
    width: 100px;
  }

  .el-button:first-child {
    margin-right: 20px;
  }
}

.flex-column {
  min-height: 400px;
}

.card-active {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3) !important;
}
</style>
