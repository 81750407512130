import { i18n } from '@/locales'
import { ElNotification } from 'element-plus'
import { INotificationOptions } from 'element-plus/lib/el-notification/src/notification.type'
import { isString } from 'lodash'

interface Option {
  type?: INotificationOptions['type']
  title?: string
  message: string
}

interface Options {
  success: Option | string
  error?: Partial<Option> | string
}

/**
 * **if** `success` or `error` is passed as string
 *  value will be passed to `message` field.
 *
 * **Else**  each field to its corresponding field
 */
export const withNotify = ({ success, error = {} }: Options) => {
  return (target: any, propertyKey: string) => {
    const prevFunction = target[propertyKey]

    target[propertyKey] = async (...args) => {
      try {
        const res = await prevFunction.call(target, ...args)

        ElNotification({
          type: isString(success) ? 'success' : success.type || 'success',

          title: isString(success)
            ? i18n.global.t('common.success')
            : success.title || i18n.global.t('common.success'),

          message: i18n.global.t(isString(success) ? success : success.message)
        })

        return res
      } catch (e) {
        ElNotification({
          type: isString(error) ? 'error' : error.type || 'error',

          title: isString(error)
            ? i18n.global.t('common.error')
            : error.type || i18n.global.t('common.error'),

          message: isString(error) ? error : error.message || e.message
        })

        console.dir(e)
        return e
      }
    }

    return target[propertyKey]
  }
}
