<template>
  <el-table :data="data" @row-click="$emit('rowClick', $event)">
    <slot />
  </el-table>
  <el-pagination
    v-if="data.length / pageSize > 1"
    class="mt-10"
    :page-size="pageSize"
    background
    layout="prev, pager, next"
    :total="totalRows"
    @current-change="setPage"
  >
  </el-pagination>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'PaginatedTable',

  props: {
    data: { type: Array as PropType<any[]>, required: true },
    totalRows: { type: Number, default: 0 }
  },
  emits: ['pageChanged', 'rowClick'],

  data() {
    return { page: 1, pageSize: 10 }
  },

  methods: {
    setPage(newPage) {
      this.$emit('pageChanged', newPage)
      this.page = newPage
    }
  }
})
</script>

<style lang="scss" scoped></style>
