<template>
  <el-form ref="formRef" :validate-on-rule-change="false" :model="owner" :rules="validationRules">
    <div class="address-form">
      <div class="address-form__left">
        <el-form-item :label="$t('form.name')">
          <el-input v-model="owner.name" placeholder="John Smith" />
        </el-form-item>

        <el-form-item :label="$t('common.isCompany')" class="switcher">
          <el-switch
            v-model="owner.isCompany"
            active-color="#409eff"
            inactive-color="#dcdfe6"
            @change="onIsCompanyToggle"
          />
        </el-form-item>

        <el-form-item v-if="owner.isCompany" :label="$t('common.licenseNumber')">
          <el-input
            v-model="owner.metadata.licenseNumber"
            :placeholder="$t('common.licenseNumber')"
          />
        </el-form-item>
        <el-form-item v-else :label="$t('common.birthday')">
          <DatePicker v-model="owner.metadata.birthday" :placeholder="$t('common.pickDay')" />
        </el-form-item>

        <el-form-item :label="$t('form.phone')">
          <TelephoneInput v-model="owner.metadata.phone" />
        </el-form-item>
      </div>
      <div class="address-form__right">
        <AddressForm
          v-model="owner.address"
          @validateField="$refs.formRef.validateField($event)"
          @requiredFieldChanged="setNewRequiredField"
        />
      </div>
    </div>
  </el-form>

  <div class="footer">
    <el-button :disabled="!formIsValid" type="primary" @click="onSave">
      {{ $t('common.save') }}
    </el-button>
  </div>
</template>

<script lang="ts">
import DatePicker from '@/common/components/DatePicker.vue'
import TelephoneInput from '@/common/components/TelephoneInput.vue'
import AddressForm from '@/common/components/AddressForm.vue'
import { useTabChangeConfirm } from '@/common/hooks'
import services from '@/core/services'
import { entries, isEmpty } from 'lodash'
import { defineComponent, PropType } from 'vue'
import { getFormIsValid, getValidationRules } from './utils/validatinRules'
import { Estate, EstateOwner } from '@/core/models'

export default defineComponent({
  components: {
    DatePicker,
    AddressForm,
    TelephoneInput
  },
  props: {
    estate: { type: Object as PropType<Estate>, required: true }
  },
  emits: ['onShouldChangeTab', 'reFetch'],
  data() {
    const owner = EstateOwner.create(this.$props.estate['owners'][0])
    return {
      owner,
      unchangedOwner: owner.clone(),
      validationRules: getValidationRules(owner)
    }
  },

  computed: {
    formIsValid(): boolean {
      return getFormIsValid(this.owner, this.validationRules)
    }
  },

  created() {
    useTabChangeConfirm({
      shouldConfirm: () => !this.owner.isEqualTo(this.unchangedOwner),
      onNoConfirm: () => this.$emit('onShouldChangeTab', true),
      onConfirm: () => {
        this.onSave()
        this.$emit('onShouldChangeTab', true)
      },
      onCancel: () => this.$emit('onShouldChangeTab', true)
    })
  },

  methods: {
    async onSave() {
      let isInvalid
      this.$refs.formRef.validate(valid => (isInvalid = !valid))

      if (isInvalid) {
        return
      }

      const payload = this.owner.transformToDbFormat()
      if (this.owner?.id) {
        await services.estates.ownersUpdate(
          { estateId: this.estate['id'], ownerId: this.owner.id },
          payload
        )
      } else {
        const { id } = await services.estates.ownersCreate(this.estate['id'], payload)
        this.owner.id = id
      }

      this.$emit('reFetch')

      this.unchangedOwner = this.owner.clone()
    },

    setNewRequiredField(rules: { [key: string]: { required: boolean } }) {
      if (isEmpty(this.owner.address?.country)) {
        // clear validation error messages
        this.$refs.formRef.clearValidate()
      }

      entries(rules).forEach(([ruleKey, rule]) => {
        this.validationRules[ruleKey][0].required = rule.required
      })
    },

    validateField(field: string) {
      this.$refs.formRef.validateField(field)
    },

    onIsCompanyToggle(isCompany: boolean) {
      if (isCompany) this.owner.metadata.birthday = ''
      else this.owner.metadata.licenseNumber = ''
    }
  }
})
</script>

<style lang="scss" scoped>
.address-form {
  color: #909399;
  display: flex;
  padding: 25px 0;

  &__right {
    flex: 1;
  }

  &__left {
    flex: 1;
    margin-right: 30px;

    .el-select {
      flex: 1;
    }
  }

  .switcher {
    :deep(.el-form-item__content) {
      width: fit-content !important;
    }
  }
}

.footer {
  padding: 0 30px;
  text-align: left;
}
</style>
