<template>
  <div id="accounting-costs">
    <router-view />
  </div>
</template>

<style lang="scss">
#accounting-costs {
  .header {
    text-align: end;

    .icon-group {
      display: inline-flex;

      div {
        background: white;
        border-radius: 5px;
        width: 36px;
        height: 36px;
        margin-right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        cursor: pointer;
      }
      i {
        font-weight: bold;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.5);
      }
    }

    .el-button {
      margin-left: 18px;
    }
  }
}
</style>
