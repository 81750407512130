<template>
  <div>
    <div class="header">
      <b>{{ instructionText }} Instruction</b>
      <span class="hide-btn" @click="$emit('hide')">{{ $t('common.hide') }}</span>
    </div>
    <p>
      s simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
      industry's standard dummy text ever since the 1500s, when an t to make a type specimen book.
      It has survived not only five centuries, but also the leap into electronic typesetting,
      remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset
      s
    </p>

    <p>
      s simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
      industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
      type and scrambled it to make a type specimen book. It has survived not only five centuries,
      but also the leap into electronic typesetting, remaining essentially unchanged. It was
      popularised in the 1960s with the release of
    </p>

    <p>
      s simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
      industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
      type and scrambled it to make a type specimen book. It has survived not only five centuries,
      but also the leap
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  emits: ['hide'],

  computed: {
    instructionText(): string {
      const sectionRoute = this.$route.matched[0]

      if (!sectionRoute?.name) {
        return
      }

      const instructions: { [sectionRouteName: string]: string } = {
        SignIn: 'SignIn',
        SignOut: 'SignOut',
        Dashboard: 'Dashboard',
        AccountDetails: 'AccountDetails',
        RealEstate: 'RealEstate',
        Accounting: 'Accounting',
        AddressBook: 'AddressBook'
      }

      return instructions[sectionRoute.name as string]
    }
  }
})
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
}
.hide-btn {
  font-weight: bold;
  color: #3346d3;
  cursor: pointer;
}
</style>
