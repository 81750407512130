
import api from '@/core/api'
import { isEmpty } from 'lodash'
import { Contact, Invoice } from '@/core/models'
import { defineComponent, PropType } from 'vue'
import { download } from '@/common/util'

export default defineComponent({
  props: {
    contact: { type: Object as PropType<Contact>, required: true }
  },

  emits: ['onShouldChangeTab', 'reFetch'],
  data() {
    return {
      downloading: false,
      invoices: [] as Invoice[],
      selectedInvoices: [] as Invoice[]
    }
  },

  async mounted() {
    const { data } = await api.contacts.getInvoices(this.$props.contact['id'])
    this.invoices = data
  },

  methods: {
    toggleSelection(rows?) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },

    handleSelectionChange(val) {
      this.selectedInvoices = val
    },

    async downloadPdfs() {
      if (isEmpty(this.selectedInvoices)) return

      this.downloading = true
      await Promise.all(
        this.selectedInvoices.map(invoice =>
          api.invoices
            .getPdfUrl(invoice.id)
            .then(url => download(url, `invoice-${invoice.details?.number}`))
        )
      )

      this.downloading = false
    }
  }
})
