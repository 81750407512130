<template>
  <el-table
    ref="multipleTable"
    :data="invoices"
    style="width: 100%"
    @selection-change="handleSelectionChange"
  >
    <el-table-column type="selection" width="55" />

    <el-table-column :label="$t('common.invoiceNumber')" width="140">
      <template #default="{ row }">{{ row.details.number }}</template>
    </el-table-column>

    <el-table-column :label="$t('common.dateSent')" width="120">
      <template #default="{ row }">{{ row.details.issued }}</template>
    </el-table-column>

    <el-table-column :label="$t('common.dueDate')" width="120">
      <template #default="{ row }"> {{ row.details.due }} </template>
    </el-table-column>

    <el-table-column show-overflow-tooltip :label="$t('finances.amount')">
      <template #default="{ row }"> {{ $f.currency(row.details.value) }} </template>
    </el-table-column>

    <el-table-column show-overflow-tooltip :label="$t('finances.amountOutstanding')">
      <template #default="{ row }"> {{ $f.currency(row.details.value) }} </template>
    </el-table-column>

    <el-table-column width="100">
      <span class="main-color">
        <i class="fas el-icon-fa-file-pdf" />
        <b class="ml-10 mr-10">|</b>
        <span class="go-to-invoice-btn">view</span>
      </span>
    </el-table-column>
  </el-table>

  <div v-if="invoices.length" class="text-left mt-40">
    <el-button
      :disabled="!selectedInvoices.length"
      :loading="downloading"
      type="primary"
      @click="downloadPdfs"
      >{{ $t('common.generatePdf') }}</el-button
    >
  </div>
</template>

<script lang="ts">
import api from '@/core/api'
import { isEmpty } from 'lodash'
import { Contact, Invoice } from '@/core/models'
import { defineComponent, PropType } from 'vue'
import { download } from '@/common/util'

export default defineComponent({
  props: {
    contact: { type: Object as PropType<Contact>, required: true }
  },

  emits: ['onShouldChangeTab', 'reFetch'],
  data() {
    return {
      downloading: false,
      invoices: [] as Invoice[],
      selectedInvoices: [] as Invoice[]
    }
  },

  async mounted() {
    const { data } = await api.contacts.getInvoices(this.$props.contact['id'])
    this.invoices = data
  },

  methods: {
    toggleSelection(rows?) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },

    handleSelectionChange(val) {
      this.selectedInvoices = val
    },

    async downloadPdfs() {
      if (isEmpty(this.selectedInvoices)) return

      this.downloading = true
      await Promise.all(
        this.selectedInvoices.map(invoice =>
          api.invoices
            .getPdfUrl(invoice.id)
            .then(url => download(url, `invoice-${invoice.details?.number}`))
        )
      )

      this.downloading = false
    }
  }
})
</script>

<style lang="scss" scoped>
.go-to-invoice-btn {
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: black;
  }
}
</style>
