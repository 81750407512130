
import DatePicker from '@/common/components/DatePicker.vue'
import TelephoneInput from '@/common/components/TelephoneInput.vue'
import AddressForm from '@/common/components/AddressForm.vue'
import { useTabChangeConfirm } from '@/common/hooks'
import services from '@/core/services'
import { entries, isEmpty } from 'lodash'
import { defineComponent, PropType } from 'vue'
import { getFormIsValid, getValidationRules } from './utils/validatinRules'
import { Estate, EstateOwner } from '@/core/models'

export default defineComponent({
  components: {
    DatePicker,
    AddressForm,
    TelephoneInput
  },
  props: {
    estate: { type: Object as PropType<Estate>, required: true }
  },
  emits: ['onShouldChangeTab', 'reFetch'],
  data() {
    const owner = EstateOwner.create(this.$props.estate['owners'][0])
    return {
      owner,
      unchangedOwner: owner.clone(),
      validationRules: getValidationRules(owner)
    }
  },

  computed: {
    formIsValid(): boolean {
      return getFormIsValid(this.owner, this.validationRules)
    }
  },

  created() {
    useTabChangeConfirm({
      shouldConfirm: () => !this.owner.isEqualTo(this.unchangedOwner),
      onNoConfirm: () => this.$emit('onShouldChangeTab', true),
      onConfirm: () => {
        this.onSave()
        this.$emit('onShouldChangeTab', true)
      },
      onCancel: () => this.$emit('onShouldChangeTab', true)
    })
  },

  methods: {
    async onSave() {
      let isInvalid
      this.$refs.formRef.validate(valid => (isInvalid = !valid))

      if (isInvalid) {
        return
      }

      const payload = this.owner.transformToDbFormat()
      if (this.owner?.id) {
        await services.estates.ownersUpdate(
          { estateId: this.estate['id'], ownerId: this.owner.id },
          payload
        )
      } else {
        const { id } = await services.estates.ownersCreate(this.estate['id'], payload)
        this.owner.id = id
      }

      this.$emit('reFetch')

      this.unchangedOwner = this.owner.clone()
    },

    setNewRequiredField(rules: { [key: string]: { required: boolean } }) {
      if (isEmpty(this.owner.address?.country)) {
        // clear validation error messages
        this.$refs.formRef.clearValidate()
      }

      entries(rules).forEach(([ruleKey, rule]) => {
        this.validationRules[ruleKey][0].required = rule.required
      })
    },

    validateField(field: string) {
      this.$refs.formRef.validateField(field)
    },

    onIsCompanyToggle(isCompany: boolean) {
      if (isCompany) this.owner.metadata.birthday = ''
      else this.owner.metadata.licenseNumber = ''
    }
  }
})
