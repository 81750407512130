
import api from '@/core/api'
import services from '@/core/services'
import { confirmDeletion } from '@/common/util'
import { reject } from 'lodash'
import { defineComponent } from 'vue'
import PaginatedTable from '@/common/components/PaginatedTable.vue'
import { Estate } from '@/core/models'

export default defineComponent({
  components: { PaginatedTable },

  data: () => ({
    estates: [] as Estate[],
    estatesTotalCount: 0,
    loadingEstates: null
  }),

  async mounted() {
    await this.getEstates()
  },

  methods: {
    async getEstates(page = 1) {
      this.loadingEstates = true
      const { data, metadata } = await api.accounts.getEstates(this.$store.getters.user.id, {
        page_number: page,
        items_per_page: 10
      })

      this.loadingEstates = false
      this.estatesTotalCount = metadata.count
      this.estates = data
    },

    async onAddProperty() {
      try {
        const { value: name } = await this.$prompt(
          this.$t('properties.inputYourPropertyName'),
          this.$t('properties.propertyName'),
          {
            confirmButtonText: this.$t('common.add'),
            cancelButtonText: this.$t('common.cancel')
          }
        )

        const { id } = await services.users.createEstate(name)

        this.$router.push({
          name: 'RealEstateEditAddress',
          params: { id }
        })
      } catch (e) {
        console.log(e)
      }
    },

    async confirmAndDeleteEstate(estate: Estate) {
      try {
        await confirmDeletion(this.$t('messages.willPermanentlyDeleteProperty'))
        await services.estates.delete(estate)

        this.estates = reject(this.estates, { id: estate.id })
      } catch {
        /*  */
      }
    },

    goToEditPage({ id }) {
      this.$router.push({
        name: 'RealEstateItem',
        params: { id }
      })
    }
  }
})
