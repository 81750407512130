import { cloneDeep, isFunction, set, toPairs } from 'lodash'
import { useContext } from 'vue'

/**
 *  pass a function as a first argument
 *  not to lose reactivity
 */
export const useOnChange = (
  value: Function | Record<string, any>,
  modelValueName = 'modelValue'
) => {
  const { emit } = useContext()

  return options => {
    const updates = cloneDeep(isFunction(value) ? value() : value)

    toPairs(options).forEach(([path, value]) => {
      set(updates, path, value)
    })

    emit(`update:${modelValueName}`, updates)
  }
}
