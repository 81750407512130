import api from '@/core/api'

type GetParams = {
  alpha3: string
  regionId?: string
  items_per_page?: string
  name?: string
  page_number?: number
}

export default class CityService {
  async get(params: GetParams) {
    const { data } = await api.cities.get({
      params: { items_per_page: 0, ...params }
    })

    return data
  }
}
