
import { defineComponent, ref } from '@vue/runtime-core'
import { isEqual, omit, some } from 'lodash'
import { useRouter } from 'vue-router'
import logo from '@/assets/images/logo.svg'

import { mapGetters } from 'vuex'
import { i18n } from '@/locales'
import { ContactType } from '@/core/api/ContactsEntity'
import { Contact } from '@/core/models'
const t = i18n.global.t

export default defineComponent({
  name: 'NavBar',
  setup() {
    const router = useRouter()

    const menu = ref<{ open: Function; close: Function }>()

    router.beforeEach(openCloseNavbarSubmenus)
    function openCloseNavbarSubmenus(to, from, next) {
      const routingToChildPath = to.path.startsWith(from.path) && from.path !== '/'

      try {
        if (!routingToChildPath) {
          menu.value?.close(from.matched[0].name)
          menu.value?.open(to.matched[0].name)
        }
      } finally {
        next()
      }
    }

    return { logo, menu }
  },

  computed: {
    routes(): any[] {
      return this.isWebApp ? this.webAppRoutes : this.adminAppRoutes
    },

    webAppRoutes(): any[] {
      return [
        {
          label: t('navbar.dashboard'),
          routeName: 'Dashboard',
          icon: 'el-icon-fa-chart-bar fas'
        },
        {
          label: t('navbar.accountDetails'),
          routeName: 'AccountDetails',
          icon: 'el-icon-fa-user fas'
        },
        {
          label: t('navbar.realEstate'),
          routeName: 'RealEstate',
          icon: 'el-icon-fa-home fas'
        },
        {
          label: t('navbar.accounting'),
          routeName: 'Accounting',
          icon: 'el-icon-fa-credit-card fas',
          children: [
            { label: t('finances.costs'), routeName: 'AccountingCosts' },
            { label: t('navbar.reports'), routeName: 'AccountingReports' },
            { label: t('finances.invoices'), routeName: 'AccountingInvoicesList' }
          ]
        },
        {
          label: t('navbar.addressBook'),
          routeName: 'AddressBook',
          icon: 'el-icon-fa-address-book fas',
          children: this.addressBookFiltersWithCounts
        }
      ]
    },

    ...mapGetters(['contacts', 'isWebApp']),

    adminAppRoutes(): any {
      return [
        {
          label: t('common.users'),
          routeName: 'UsersList',
          icon: 'el-icon-fa-address-book fas'
        },
        {
          label: t('common.invoices'),
          routeName: 'AdminInvoices',
          icon: 'el-icon-fa-file-invoice fas'
        },
        {
          label: t('common.articles'),
          routeName: 'ArticlesList',
          icon: 'el-icon-fa-newspaper fas'
        },
        {
          label: t('common.tutorials'),
          routeName: 'TutorialsList',
          icon: 'el-icon-fa-question-circle fas'
        }
      ]
    },

    addressBookFiltersWithCounts(): any[] {
      if (this.$route.name !== 'AddressBookList') {
        return []
      }

      const counts = {
        all: this.contacts.length,
        vendors: 0,
        tenants: 0,
        others: 0
      }

      this.contacts.forEach((contact: Contact) => {
        if (contact.isVendor) counts.vendors += 1
        if (contact.isTenant) counts.tenants += 1
        if (contact.isCompany || contact.isManagingCompany) counts.others += 1
      })

      return [
        {
          label: t('common.allContacts'),
          routeName: '',
          query: '',
          meta: { count: counts.all }
        },
        {
          label: t('common.myVendors'),
          routeName: '',
          query: 'isVendor',
          meta: { count: counts.vendors }
        },
        {
          label: t('common.myTenants'),
          routeName: '',
          query: 'isTenant',
          meta: { count: counts.tenants }
        },
        {
          label: t('common.otherPartners'),
          routeName: '',
          query: ['isCompany', 'isManagingCompany'],
          meta: { count: counts.others }
        }
      ]
    }
  },

  methods: {
    isActive(routeName: string) {
      return some(this.$route.matched, { name: routeName }) ? 'is-active' : ''
    },

    isActiveChild(child: { routeName: string; query?: any }) {
      if (child.routeName) return this.isActive(child.routeName)
      else return isEqual(child.query, this.$route.query) ? 'is-active' : ''
    },

    getContactGroupQueryParam(type: ContactType | '') {
      if (!type) {
        // remove filters
        return omit(this.$route.query, 'type')
      }

      return { ...this.$route.query, type }
    }
  }
})
