import api from '@/core/api'
import { ContactGetParams } from '@/core/api/ContactsEntity'
import { Contact, User } from '@/core/models'
import { REPORTS_YEARS_RANGE } from '@/web-app/pages/Accounting/utils'
import store from '@/core/store'
import { withNotify } from '@/common/util/decorators'
import { i18n } from '@/locales'

const t = i18n.global.t

export default class UserService {
  private get currentUserId(): string {
    return store.getters.user.id
  }

  @withNotify({
    success: 'messages.changesSaved',
    error: 'messages.somethingWentWrong'
  })
  async update(payload: Partial<User>, user: User, userId = this.currentUserId) {
    await api.accounts.update(userId, payload)

    if (userId === this.currentUserId) {
      store.commit('setUser', user)
    }
  }

  @withNotify({
    success: t('messages.successfullyDeleted'),
    error: 'messages.somethingWentWrong'
  })
  async delete(userId: string) {
    await api.accounts.delete(userId)
  }

  getReports(params: {
    report: ReportType
    from_year?: number
    forward?: number
    estate_id?: any
  }) {
    const currentYear = new Date().getFullYear()

    return api.accounts.getReports(this.currentUserId, {
      ...params,
      from_year: currentYear - REPORTS_YEARS_RANGE,
      forward: REPORTS_YEARS_RANGE - 1
    })
  }

  getInvoices(params?: IPagination) {
    return api.accounts.getInvoices(this.currentUserId, params)
  }

  getCosts(params?: IPagination) {
    return api.accounts.getCosts(this.currentUserId, params)
  }

  getEstates() {
    return api.accounts.getEstates(this.currentUserId)
  }

  @withNotify({ success: 'messages.successfullyCreated' })
  createEstate(name: string) {
    return api.estates.create({ name, creatorAccount: this.currentUserId as any })
  }

  async getContacts(params: ContactGetParams) {
    const contacts = await api.accounts.getContacts(this.currentUserId, params)

    store.commit('setContacts', contacts)
    return contacts
  }

  @withNotify({ success: 'messages.successfullyCreated' })
  createContact({ name, surname }: Partial<Contact>) {
    return api.contacts.create({
      name,
      surname,
      creatorAccount: this.currentUserId
    })
  }
}
