
import { defineComponent, ref } from '@vue/runtime-core'
import Avatar from '@/common/components/Avatar.vue'

export default defineComponent({
  components: { Avatar },
  setup() {
    const sections = [
      { iconClass: 'el-icon-fa-bell fas' },
      { iconClass: 'el-icon-fa-envelope fas has-notification' },
      { iconClass: 'el-icon-fa-home fas' },
      { iconClass: 'el-icon-fa-cog fas' }
    ]

    return {
      query: ref(''),
      sections
    }
  }
})
