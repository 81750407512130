<template>
  <el-card>
    <div class="invoice">
      <div class="invoice-left">
        <div class="inputField">
          <span class="label">{{ $t('common.customer') }}</span>
          <el-select
            :model-value="invoice.tenant?.id"
            filterable
            :placeholder="$t('common.customer')"
            @change="$event => (invoice.tenant = tenants.find(e => e.id === $event))"
          >
            <el-option
              v-for="tenant in tenants"
              :key="tenant.id"
              :value="tenant.id"
              :label="tenant?.getFullName()"
            />
          </el-select>
        </div>

        <div class="inputField">
          <span class="label">{{ $t('common.estate') }}</span>
          <el-select
            :model-value="invoice.estate?.id"
            filterable
            :placeholder="$t('common.estate')"
            @change="$event => (invoice.estate = estates.find(e => e.id === $event))"
          >
            <el-option v-for="{ name, id } in estates" :key="id" :value="id" :label="name" />
          </el-select>
        </div>

        <!-- <div class="inputField">
          <span class="label">{{ $t('form.email') }}</span>
          <el-input v-model="invoice.email" :placeholder="$t('form.email')" />
        </div> -->

        <!-- <div class="inputField">
          <span class="label">{{ $t('common.address') }}</span>
          <el-input
            v-model="invoice.address"
            type="textarea"
            :row="2"
            resize="none"
            :placeholder="$t('common.address')"
          />
        </div> -->

        <div class="inputField">
          <span class="label">{{ $t('common.terms') }}</span>
          <el-select
            v-model="selectedTerm"
            filterable
            :placeholder="$t('common.terms')"
            @change="selectIntervalFromToday"
          >
            <el-option
              v-for="interval in terms"
              :key="interval"
              :value="interval"
              :label="`${interval} days`"
            />
          </el-select>
        </div>
      </div>
      <div class="invoice-right">
        <div class="flex-between">
          <div class="invoice__payment-methods">
            <div class="invoice__payment-label">
              {{ $t('common.onlinePayments') }}
            </div>
            <div>
              <el-checkbox>
                <i class="el-icon-fa-cc-visa fab" />
                <i class="el-icon-fa-cc-mastercard fab" />
                <i class="el-icon-fa-cc-amex fab" />
              </el-checkbox>
            </div>
            <div class="mt-10">
              <el-checkbox>
                <i class="el-icon-fa-cc-paypal fab" />
              </el-checkbox>
            </div>
          </div>
          <div>
            <div class="invoice__heading">
              {{ $t('common.invoiceUpper') }}
            </div>
            <div v-if="invoice.details?.number" class="invoice__number mt-10">
              <span> No.</span> {{ invoice.details.number }}
            </div>
          </div>
        </div>
        <div class="flex-between">
          <div class="invoice__dates">
            <div class="inputField">
              <span class="label">{{ $t('common.invoiceDate') }}</span>
              <DatePicker
                v-model="invoice.details.issued"
                :placeholder="$t('common.date')"
                @change="ensureEndDateToBeAfterStart"
              />
            </div>

            <div class="inputField">
              <span class="label">{{ $t('common.due') }}</span>
              <DatePicker
                v-model="invoice.details.due"
                :disabled-date="end => $moment(end).isBefore(invoice.details.issued)"
                :placeholder="$t('common.date')"
                @change="selectedTerm = null"
              />
            </div>
          </div>
          <div class="invoice__total">
            <div>{{ $t('finances.total') }}</div>
            <div>{{ $f.currency(invoice.details.totalWithTax) }}</div>
          </div>
        </div>
      </div>
    </div>

    <el-form ref="tableForm" :rules="tableValidationRules" :model="invoice.details.description">
      <el-table :data="invoice.details.description" class="mt-30 mb-30">
        <el-table-column type="index" label="No" />

        <el-table-column :label="`${$t('common.product')}/${$t('common.service')}`" width="180">
          <template #default="{ row, $index }">
            <el-form-item
              class="mt-15"
              :prop="$index + '.product'"
              :rules="tableValidationRules.product"
            >
              <el-select v-model="row.product" placeholder="Select">
                <el-option v-for="product in products" :key="product" :value="product"> </el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column :label="$t('common.description')">
          <template #default="{row, $index}">
            <el-form-item
              class="w-100 mt-15"
              :prop="$index + '.description'"
              :rules="tableValidationRules.description"
            >
              <el-input
                v-model="row.description"
                :autosize="true"
                resize="none"
                :placeholder="$t('common.shortDescription')"
                type="textarea"
              />
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column width="80" :label="$t('common.qty')">
          <template #default="{row, $index}">
            <el-form-item
              class="mt-15"
              :prop="$index + '.quantity'"
              :rules="tableValidationRules.quantity"
            >
              <el-input v-model="row.quantity" type="number" />
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column width="80" :label="$t('finances.rate')">
          <template #default="{row, $index}">
            <el-form-item class="mt-15" :prop="$index + '.rate'" :rules="tableValidationRules.rate">
              <el-input v-model="row.rate" type="number" />
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column width="100" :label="$t('finances.amount')">
          <template #default="{ row }">{{ $f.currency(row.value) }}</template>
        </el-table-column>
        <el-table-column width="40">
          <template #default="{ row }">
            <i
              v-if="invoice.details.description.length > 1"
              class="el-icon-fa-trash-alt fas "
              @click.stop="invoice.details.removeDescription(row.id)"
            />
          </template>
        </el-table-column>
      </el-table>
    </el-form>

    <div class="flex-between">
      <div>
        <el-button @click="invoice.details.addDescription()">{{ $t('common.addLines') }}</el-button>
        <el-button @click="invoice.details.clearDescriptions()">
          {{ $t('common.clearAllLines') }}
        </el-button>
        <!-- <el-button>{{ $t('common.addSubtotal') }}</el-button> -->
      </div>
      <span class="text-right">
        <span class="main-color"> {{ $t('finances.tax') }}</span>
        <el-input-number
          v-model="invoice.details.tax"
          class="tax-input"
          :controls="false"
          :min="0"
          :max="100"
          @change="val => (invoice.details.tax = val || 0)"
        />
        <i class="main-color fas el-icon-fa-percent"></i>
      </span>
    </div>

    <div class="flex-between mt-35">
      <div class="invoice__message">
        <div class="mb-15">{{ $t('common.messageOnInvoice') }}</div>
        <el-input
          v-model="invoice.invoiceMessage"
          rows="5"
          resize="none"
          type="textarea"
          :placeholder="$t('common.typeYourMessageHere')"
        ></el-input>
      </div>
      <div class="invoice__total-with-taxes">
        <div class="flex-between">
          <span>{{ $t('finances.total') }}</span>
          <span>{{ $f.currency(invoice.details.totalWithTax) }}</span>
        </div>
        <div class="flex-between">
          <span>{{ $t('finances.balanceDue') }}</span>
          <span>{{ $f.currency(invoice.details.totalWithTax) }}</span>
        </div>
      </div>
    </div>

    <div class="invoice__statement-message mt-35">
      <div class="mb-15">{{ $t('common.messageOnStatement') }}</div>
      <el-input
        v-model="invoice.statementMessage"
        rows="5"
        resize="none"
        type="textarea"
        :placeholder="$t('common.typeYourMessageHere')"
      ></el-input>
    </div>

    <div class="text-right">
      <el-button type="primary" :disabled="!invoice.details.descriptionsAreValid" @click="save()">{{
        $t('common.save')
      }}</el-button>
      <!-- <el-button :disabled="!canSave" type="primary">{{ $t('common.saveAndSend') }}</el-button> -->
    </div>
  </el-card>
</template>

<script lang="ts">
import api from '@/core/api'
import DatePicker from '@/common/components/DatePicker.vue'
import { Contact, Estate, Invoice } from '@/core/models'
import services from '@/core/services'
import { pick } from 'lodash'
import moment from 'moment'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    DatePicker
  },
  data() {
    return {
      loading: true,
      tenants: [] as Readonly<Contact>[],
      estates: [] as Readonly<Estate>[],
      products: ['RENT'],
      terms: [5, 14, 30],
      selectedTerm: null,

      invoice: Invoice.create({}),

      tableValidationRules: {
        product: [{ required: true, message: this.$t('common.required') }],
        description: [{ required: true, message: this.$t('common.required') }],
        quantity: [{ required: true, message: this.$t('common.required') }],
        rate: [{ required: true, message: this.$t('common.required') }]
      }
    }
  },

  async mounted() {
    if (this.$route.params.id) {
      const invoice = await api.invoices.getById(this.$route.params.id as string)
      if (invoice) {
        this.invoice = invoice
        this.estates = invoice.estate ? [invoice.estate] : []
        this.tenants = invoice.tenant ? [invoice.tenant] : []
      }
    }

    const [estates, tenants] = await Promise.all([
      services.users.getEstates(),
      services.users.getContacts({ type: 'tenant' })
    ])

    this.estates = estates.data
    this.tenants = tenants.data
    this.loading = false
  },

  methods: {
    async save() {
      let formIsInvalid: boolean
      this.$refs.tableForm.validate(isValid => (formIsInvalid = !isValid))

      if (formIsInvalid) return

      const payloadKeys = [
        'details',
        'tenant',
        'estate',
        'creatorAccount',
        'id'
      ] as (keyof Invoice)[]

      const payload = pick(this.invoice.transformToDbFormat(), payloadKeys)

      this.$route.params.id
        ? await services.invoices.update(this.$route.params.id as string, payload)
        : await services.invoices.create(payload)

      this.$router.push({ name: 'AccountingInvoicesList' })
    },

    selectIntervalFromToday(interval: number) {
      this.invoice.details.issued = moment().format()
      this.invoice.details.due = moment()
        .add(interval, 'days')
        .format()
    },

    ensureEndDateToBeAfterStart(startDate: string) {
      if (this.invoice.details.due && moment(startDate).isSameOrAfter(this.invoice.details.due)) {
        this.invoice.details.due = null
      }
      this.selectedTerm = null
    }
  }
})
</script>

<style lang="scss" scoped>
:deep(.el-card__body) {
  padding: 40px 30px;

  .el-form-item__content {
    margin: 0 !important;
    width: 100% !important;

    .el-textarea__inner {
      min-height: 40px !important;
      padding: 8px 15px !important;
    }

    .el-input__inner {
      padding: 5px;
    }
  }
}

.invoice {
  display: flex;
  justify-content: space-between;

  &-left {
    width: 35%;
  }

  &-right {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .invoice__dates {
    flex-direction: column;
    width: 65%;
  }

  @media (max-width: 1279px) {
    &-left {
      width: 30%;
    }

    &-right {
      width: 65%;
    }
  }

  &__payment-methods {
    text-align: left;
    align-items: flex-start;

    .el-checkbox {
      display: flex;
      align-items: center;
    }

    i {
      font-size: 24px;
      margin-right: 10px;
      color: #909399;
      cursor: pointer;
      position: relative;
    }
  }

  &__payment-label {
    font-size: 18px;
    font-weight: bolder;
    margin-bottom: 10px;
  }

  &__heading {
    font-size: 25px;
    font-weight: bolder;
    text-align: center;
    color: #409eff;
  }

  &__number {
    padding: 6px 21px 6px 28px;
    min-width: 200px;
    white-space: nowrap;
    background: rgba(51, 70, 211, 0.05);

    span {
      margin-right: 10px;
    }
  }

  &__dates {
    display: flex;

    .inputField {
      display: block;
      align-self: flex-end;

      .label {
        margin-right: 10px;
      }
    }
    :deep(.el-input) {
      width: 140px;
    }

    :deep(.el-input__prefix) {
      display: none;
    }
    :deep(.el-input__inner) {
      padding-left: 10px !important;
    }
  }

  &__total {
    margin-left: 10px;

    div {
      font-weight: bolder;
    }
    & > div:first-child {
      margin-bottom: 5px;
      font-size: 18px;
    }
  }

  &__statement-message,
  &__message {
    width: 40%;
    text-align: left;
    color: rgba(0, 0, 0, 0.5);

    div {
      font-weight: 450;
    }

    .el-textarea__inner {
      margin-top: 16px;
      padding: 24px 46px 24px 24px;
      background: rgba(0, 0, 0, 0.05);
      border: none;
      border-radius: 5px;
      font-size: 14px;
      font-style: normal;
      font-weight: 450;
      line-height: 16px;
      letter-spacing: 0em;
    }
  }

  &__total-with-taxes {
    width: 220px;
    margin-left: auto;
    margin-top: 16px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 450;

    .flex-between {
      padding: 6px 21px 6px 28px;
      min-width: 200px;
      white-space: nowrap;

      span:first-child {
        margin-right: 10px;
      }
    }

    .flex-between:last-child {
      background: rgba(51, 70, 211, 0.05);
    }
  }
}

.tax-input {
  width: 30%;
  margin: 0 10px;
}
</style>
