<template>
  <div class="wrapper">
    <el-image style="width:40%" :src="progressBar"></el-image>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import progressBar from '@/assets/images/progress-bar.svg'

export default defineComponent({
  data() {
    return { progressBar }
  }
})
</script>

<style lang="scss" scoped>
.wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: inherit;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}
</style>
