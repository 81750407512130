<template>
  <el-card class="documents" :header="header">
    <el-button size="small" round type="primary" @click="$emit('onCreate')">
      {{ $t('common.new') }} +
    </el-button>
    <div class="flex-column">
      <DocumentPreviewSkeleton v-if="loading" :count="pageSize" />
      <slot v-else></slot>
    </div>
    <el-pagination
      v-if="totalDocumentsCount - pageSize > 0"
      class="mt-20 mb-20"
      background
      layout="prev, pager, next"
      :page-size="pageSize"
      :total="totalDocumentsCount"
      :pager-count="3"
      @current-change="$emit('onPageChange', $event)"
    />
  </el-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import DocumentPreviewSkeleton from './DocumentPreviewSkeleton.vue'

export default defineComponent({
  name: 'DocumentPreviewItemsList',
  components: { DocumentPreviewSkeleton },
  props: {
    header: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    pageSize: {
      type: Number,
      default: 4
    },
    totalDocumentsCount: {
      type: Number,
      default: 0
    }
  },
  emits: ['checked', 'click', 'onPageChange', 'onCreate']
})
</script>
<style lang="scss" scoped>
.documents {
  width: 40%;

  margin-right: 20px;
  white-space: nowrap;
  :deep(.el-card__header) {
    color: gray;
  }

  .el-button {
    width: 100px;
  }
}

.flex-column {
  min-height: 400px;
}
</style>
