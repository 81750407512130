<template>
  <div class="text-right mb-15">
    <!-- <div class="query-builder">
      <span class="mr-10 main-color">{{ $t('common.sortBy') }}</span>
      <el-select
        :model-value="$t(`common.${selectedSortOption}`)"
        class="mr-10"
        @change="
          $event => {
            selectedSortOption = $event
            getAcounts()
          }
        "
      >
        <el-option
          v-for="option in sortOptions"
          :key="option"
          :label="$t(`common.${option}`)"
          :value="option"
        />
      </el-select>
      <el-select v-model="selectedSortOrder" @change="getAcounts">
        <el-option
          v-for="{ operator, label } in sortOrders"
          :key="label"
          :label="$t(`common.${label}`)"
          :value="operator"
        />
      </el-select>
    </div> -->

    <!-- <el-button type="primary" @click="onAddContact">{{ $t('common.addContact') }} + </el-button> -->
  </div>
  <div class="flex-between">
    <div class="card-wrapper">
      <el-card v-loading="loading">
        <div v-if="!Object.entries(groupedAccounts).length">
          <p class="text-center main-color mt-25">
            {{ $t('common.nothingToShow') }}
          </p>
        </div>

        <div
          v-for="[group, data] in Object.entries(groupedAccounts)"
          v-else
          :key="group"
          class="mt-25 text-left"
        >
          <h3 v-if="isNaN(+group)" :id="group" class="ml-15">{{ group }}</h3>
          <div v-else :id="group.trim()" class="mb-20">
            <el-rate disabled :model-value="+group"> </el-rate>
          </div>
          <el-divider />
          <el-table
            tooltip-effect="light"
            style="width: 100%"
            :show-header="false"
            :data="data"
            @row-click="goToEditPage($event)"
          >
            <el-table-column width="50">
              <template #default="{ row }">
                <Avatar :size="40" :src="row.photo" />
              </template>
            </el-table-column>

            <el-table-column width="200">
              <template #default="{ row }">
                <div>
                  <b>{{ row.name }} {{ row?.surname }}</b>
                </div>
                <div>{{ row.companyName }}</div>
              </template>
            </el-table-column>

            <el-table-column property="email" />
            <!-- <el-table-column property="phone" width="150" /> -->
            <el-table-column property="group.finance" width="70" />

            <el-table-column width="100">
              <template #default="{row}">
                <span v-if="row.isVerified" style="color: green">{{ $t('common.verified') }}</span>
                <span v-else style="color: red">{{ $t('common.unverified') }}</span>
              </template>
            </el-table-column>

            <el-table-column width="70">
              <template #default="{row}">
                <div class="menu-wrapper">
                  <el-dropdown placement="bottom-end" trigger="click">
                    <span class="el-dropdown-link">
                      <i class="fas el-icon-fa-ellipsis-v"></i>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item icon="el-icon-delete" @click="onConfirmAndDelete(row.id)">
                          {{ $t('common.delete') }}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
    </div>

    <div class="navigation">
      <div
        v-for="[groupName] in Object.entries(groupedAccounts)"
        :key="groupName"
        :class="{ active: $route.hash === `#${groupName}` }"
      >
        <a :href="`#${groupName}`">{{ groupName }}</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Avatar from '@/common/components/Avatar.vue'
import { confirmDeletion } from '@/common/util'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { first, groupBy, orderBy, reject } from 'lodash'
import { User } from '@/core/models'
import api from '@/core/api'
import services from '@/core/services'

export default defineComponent({
  components: { Avatar },

  data() {
    return {
      loading: null,
      accounts: [] as User[]
      // accounts: {} as { [firstLetter: string]: IUser[] }
      // selectedSortOption: 'name',
      // selectedSortOrder: '' as '' | '-',
      // sortOptions: ['name', 'surname', 'companyName', 'rate'],
      // sortOrders: [
      //   { operator: '', label: 'ascending' },
      //   { operator: '-', label: 'descending' }
      // ]
    }
  },

  computed: {
    groupedAccounts(): { [firstLetter: string]: User[] } {
      return this.groupByFirstLetterOfName(this.accounts)
    },

    ...mapGetters(['contacts'])

    // sortQuery(): Record<string, string> {
    //   return {
    //     sort: this.selectedSortOrder + snakeCase(this.selectedSortOption)
    //   }
    // }
  },

  async mounted() {
    await this.getAcounts()
  },

  methods: {
    groupByFirstLetterOfName(accounts: User[]) {
      const alphabeticallyOrdered = orderBy(
        accounts,
        ({ name, surname }) => name?.toLowerCase() || surname?.toLowerCase()
      )

      const groupedByFirstLetter = groupBy(
        alphabeticallyOrdered,
        ({ name, surname }) => first(name)?.toUpperCase() || first(surname)?.toUpperCase()
      )

      return groupedByFirstLetter
    },

    goToEditPage({ id }) {
      this.$router.push({
        name: 'User',
        params: { id }
      })
    },

    async getAcounts() {
      this.loading = true
      const { data } = await api.accounts.get()
      this.accounts = data
      this.loading = false
    },

    // async onAddContact() {
    //   let name, surname

    //   this.$msgbox({
    //     title: this.$t('messages.inputContactName'),
    //     message: h('div', {}, [
    //       h('input', {
    //         placeholder: this.$t('common.name'),
    //         class: 'custom-input mb-20',
    //         onInput: e => (name = e.target.value)
    //       }),
    //       h('input', {
    //         placeholder: this.$t('form.surname'),
    //         class: 'custom-input ',
    //         onInput: e => (surname = e.target.value)
    //       })
    //     ]),
    //     showCancelButton: true,
    //     confirmButtonText: this.$t('common.add'),
    //     cancelButtonText: this.$t('common.cancel'),
    //     beforeClose: async (action: 'confirm' | 'cancel', instance, done) => {
    //       if (action === 'cancel') return done()

    //       instance.confirmButtonLoading = true
    //       instance.confirmButtonText = 'Loading...'
    //       const { id } = await services.users.createContact({ name, surname })
    //       done()

    //       this.$router.push({
    //         name: 'AddressBookItem',
    //         params: { id }
    //       })
    //       setTimeout(() => (instance.confirmButtonLoading = false), 300)
    //     }
    //   })
    // },

    async onConfirmAndDelete(id: string) {
      try {
        await confirmDeletion(this.$t('messages.willPermanentlyDeleteAccount'))
        await services.users.delete(id)
        this.accounts = reject(this.accounts, { id })
      } catch (error) {
        /*  */
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.el-divider {
  margin: 0;
  background: #ebeef5;
}

i.fas {
  font-size: 25px;
  margin-left: 30px;
  cursor: pointer;
  color: #9c9494c7;
  transition: all 0.3s;
}

.el-icon-fa-dollar-sign {
  &.active {
    color: green;
  }
  &:hover {
    color: green;
  }
}

.el-icon-fa-globe-americas {
  &.active {
    color: rgb(0, 183, 255);
  }
  &:hover {
    color: rgb(0, 183, 255);
  }
}

.el-icon-fa-wrench {
  &.active {
    color: rgb(160, 129, 218);
  }
  &:hover {
    color: rgb(160, 129, 218);
  }
}

.card-wrapper {
  width: calc(100% - 65px);
}

:deep(.el-card__body) {
  flex: 1;
}

.navigation {
  width: 30px;
  margin-left: 20px;
  position: relative;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  font-size: 1.17rem;
  font-weight: 450;

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
      background: #409eff;
      color: white;
      border-radius: 50%;
      width: 30px;
      height: 30px;
    }
  }
}
.query-builder {
  display: inline-block;
  margin-right: 20px;

  .main-color {
    font-size: 14px;
  }

  .el-select {
    width: 150px;
  }
}
.menu-wrapper {
  text-align: right;
}
</style>
