
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'PaginatedTable',

  props: {
    data: { type: Array as PropType<any[]>, required: true },
    totalRows: { type: Number, default: 0 }
  },
  emits: ['pageChanged', 'rowClick'],

  data() {
    return { page: 1, pageSize: 10 }
  },

  methods: {
    setPage(newPage) {
      this.$emit('pageChanged', newPage)
      this.page = newPage
    }
  }
})
