
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'SignIn',
  setup: () => {
    return {
      username: ref('')
    }
  },
  mounted() {
    this.$auth.show()
  },
  methods: {}
})
