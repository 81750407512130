import { BaseModelWrapper } from './BaseModel.model'

export class PaidPlan extends BaseModelWrapper<PaidPlan>() {
  id: string
  name: 'Starter' | 'Basic' | 'Pro' = 'Starter'
  price: number
  period: 'monthly' | 'yearly' = 'monthly'

  yearlyDiscount: number | string
}
