import { Contact, Country, Currency, Language, User } from '@/core/models'
import { each, keyBy, reject } from 'lodash'
import { readonly } from 'vue'
import { createLogger, createStore } from 'vuex'

const store = createStore({
  state: {
    user: {} as User,
    isWebApp: true,
    previewImage: null as string | null,

    languages: [] as Language[],
    languagesById: {} as { [id: string]: Language },

    currencies: [] as Currency[],
    currenciesById: {} as { [id: string]: Currency },

    countries: [] as Country[],
    countriesById: {} as { [id: string]: Country },

    paidPlans: [] as any[],
    groups: [] as any[],

    contacts: [] as Contact[]
  },
  mutations: {
    set(state, payload: Record<string, any>) {
      each(payload, (value, key) => {
        state[key] = value
      })
    },

    setPreviewImage(state, image: string | null) {
      state.previewImage = image
    },

    setLanguages(state, languages: Language[]) {
      state.languages = languages
      state.languagesById = keyBy(languages, 'id')
    },

    setCurrencies(state, currencies: Currency[]) {
      state.currencies = currencies
      state.currenciesById = keyBy(currencies, 'id')
    },

    setCountries(state, countries: Country[]) {
      state.countries = countries
      state.countriesById = keyBy(countries, 'id')
    },

    setUser(state, user: Partial<User>) {
      state.user = { ...state.user, ...user } as User
    },

    setContacts(state, contacts: Paginated<Contact[]>) {
      state.contacts = contacts.data
    },

    removeContact(state, contactId) {
      state.contacts = reject(state.contacts, { id: contactId })
    }
  },

  getters: {
    user: store => User.create(store.user),
    isWebApp: store => store.isWebApp,
    previewImage: store => store.previewImage,

    languages: store => readonly(store.languages),
    languagesById: store => readonly(store.languagesById),

    currencies: store => readonly(store.currencies),
    currenciesById: store => readonly(store.currenciesById),

    countries: store => readonly(store.countries),
    countriesById: store => readonly(store.countriesById),

    contacts: store => readonly(store.contacts)
  },
  plugins: [createLogger()]
})

window['store'] = store
export type State = typeof store.state
export default store
