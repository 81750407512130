
import api from '@/core/api'
import DatePicker from '@/common/components/DatePicker.vue'
import { AdminInvoice, Invoice, PaidPlan, User } from '@/core/models'
import services from '@/core/services'
import { find, get, pick } from 'lodash'
import moment from 'moment'
import { defineComponent } from 'vue'
import { DocumentDetail } from '@/core/models/Documents/DocumentDetail.model'
import { DocumentDetailDescription } from '@/core/models/Documents/DocumentDetailDescription.model'

export default defineComponent({
  components: {
    DatePicker
  },
  data() {
    return {
      loading: true,
      accounts: [] as Readonly<User>[],
      products: ['SUBSCRIPTION'],
      terms: [5, 14, 30],
      selectedTerm: null,

      invoice: AdminInvoice.create({
        details: DocumentDetail.create({
          tax: 20,
          description: [DocumentDetailDescription.create({ product: 'SUBSCRIPTION' })]
        })
      }),

      tableValidationRules: {
        product: [{ required: true, message: this.$t('common.required') }],
        description: [{ required: true, message: this.$t('common.required') }],
        quantity: [{ required: true, message: this.$t('common.required') }],
        rate: [{ required: true, message: this.$t('common.required') }]
      }
    }
  },

  async mounted() {
    if (this.$route.params.id) {
      const invoice = await api.adminInvoices.getById(this.$route.params.id as string)
      if (invoice) {
        this.invoice = invoice as any
        this.accounts = invoice.recipientAccount ? [invoice.recipientAccount] : []
      }
    }

    const { data } = await api.accounts.get()
    this.accounts = data

    this.loading = false
  },

  methods: {
    onRecipientSelect(accountId: string) {
      const account = find(this.accounts, { id: accountId })
      this.invoice.recipientAccount = account

      this.invoice.details.description[0].rate = get(account, 'paidPlan.price', 0)
      this.invoice.details.description[0].description = this.getDescription(account.paidPlan)
      this.invoice.details.description[0].quantity = 1
    },

    async save() {
      let formIsInvalid: boolean
      this.$refs.tableForm.validate(isValid => (formIsInvalid = !isValid))

      if (formIsInvalid) return

      const payloadKeys = [
        'details',
        'tenant',
        'recipientAccount',
        'paid_plan',
        'estate',
        'creatorAccount',
        'id'
      ] as (keyof Invoice)[]

      const payload = pick(this.invoice.transformToDbFormat(), payloadKeys)

      this.$route.params.id
        ? await services.invoices.update(this.$route.params.id as string, payload)
        : await services.invoices.create(payload)

      this.$router.push({ name: 'AdminInvoicesList' })
    },

    selectIntervalFromToday(interval: number) {
      this.invoice.details.issued = moment().format()
      this.invoice.details.due = moment()
        .add(interval, 'days')
        .format()
    },

    ensureEndDateToBeAfterStart(startDate: string) {
      if (this.invoice.details.due && moment(startDate).isSameOrAfter(this.invoice.details.due)) {
        this.invoice.details.due = null
      }
      this.selectedTerm = null
    },

    getDescription(paidPlan = {} as PaidPlan) {
      return `Subscription for the platform ${
        paidPlan.name ? `- ${paidPlan.name} ` : ''
      }${paidPlan.period || ''}`
    }
  }
})
