<template>
  <div class="container">
    <div class="card">
      <div class="card__title">You've been Signed Out</div>
      <div class="card__content">
        lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet vv lorem
        ipsum dolor sit amet lorem ipsum dolor sit ametlorem ipsum dolor sit ametlorem ipsum dolor
        sit amet
      </div>
      <router-link :to="{ name: 'SignIn' }">
        <el-button class="card__btn" type="success">Sign In</el-button>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SignOut'
})
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 700px;
}

.card {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  background: white;
  width: 560px;
  padding: 44px;
  border-radius: 10px;

  &__title {
    margin: 0;
    font-size: 36px;
    font-weight: bolder;
    margin: 36px 0;
  }

  &__content {
    margin: 36px 0;
  }

  &__btn {
    width: 50%;
  }
}
</style>
