<template>
  <div id="accounting-invoices">
    <router-view />
  </div>
  <!-- <div class="header mb-25">
    <div class="icon-group">
      <div :class="{ 'active-shadow': $route.query.isEditing }">
        <i class="fas el-icon-fa-pen" @click="edit" />
      </div>
      <div><i class="fas el-icon-fa-sticky-note" /></div>
      <div><i class="el-icon-printer" /></div>
      <div><i class="fas el-icon-fa-envelope" /></div>
      <div><i class="fas el-icon-fa-paperclip" /></div>
    </div>
    <el-button round type="primary">{{ $t('common.reportPayment') }}</el-button>
    <el-button round>{{ $t('common.more') }}</el-button>
  </div> -->
</template>

<style lang="scss">
#accounting-invoices {
  .header {
    text-align: end;

    .icon-group {
      display: inline-flex;

      div {
        background: white;
        border-radius: 5px;
        width: 36px;
        height: 36px;
        margin-right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        cursor: pointer;
      }
      i {
        font-weight: bold;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.5);
      }
    }

    .el-button {
      margin-left: 18px;
    }
  }
}
</style>
