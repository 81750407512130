<template>
  <el-form
    ref="formRef"
    :validate-on-rule-change="false"
    :model="estateClone"
    :rules="validationRules"
  >
    <div class="form">
      <div class="form__left">
        <el-form-item :label="$t('form.name')" prop="name">
          <el-input v-model="estateClone.name" :placeholder="$t('common.apartment')" />
        </el-form-item>
        <el-form-item :label="$t('form.recordNumber')">
          <el-input v-model="estateClone.recordNo" placeholder="FI99999999" />
        </el-form-item>
        <el-form-item :label="$t('form.primaryUse')" prop="primaryUse">
          <el-input
            v-model="estateClone.primaryUse"
            :placeholder="`${$t('common.garage')}, ${$t('common.house')}, ${$t('common.shop')}...`"
          />
        </el-form-item>
      </div>
      <div class="form__right">
        <AddressForm
          v-model="estateClone.address"
          @validateField="validateField"
          @requiredFieldChanged="setNewRequiredField"
        />
      </div>
    </div>
    <div class="form-submit">
      <el-form-item>
        <el-button type="primary" :disabled="!formIsValid" @click="onSave">
          {{ $t('common.save') }}
        </el-button>
      </el-form-item>
    </div>
  </el-form>
</template>

<script lang="ts">
import AddressForm from '@/common/components/AddressForm.vue'
import { entries, pick } from 'lodash'
import { defineComponent, PropType } from 'vue'
import { useTabChangeConfirm } from '@/common/hooks'
import services from '@/core/services'
import { Estate } from '@/core/models'
import { getFormIsValid, getValidationRules } from './utils/validatinRules'

export default defineComponent({
  components: { AddressForm },
  props: {
    estate: { type: Object as PropType<Estate>, required: true }
  },
  emits: ['onShouldChangeTab', 'reFetch'],
  data() {
    const estateClone: Estate = (this.$props.estate as Estate).clone()

    return {
      estateClone,
      unchangedEstate: estateClone.clone(),
      validationRules: getValidationRules(estateClone)
    }
  },

  computed: {
    formIsValid(): boolean {
      return getFormIsValid(this.estateClone, this.validationRules)
    }
  },

  created() {
    useTabChangeConfirm({
      shouldConfirm: () => !this.estateClone.isEqualTo(this.unchangedEstate),
      onNoConfirm: () => this.$emit('onShouldChangeTab', true),
      onConfirm: () => {
        this.onSave()
        this.$emit('onShouldChangeTab', true)
      },
      onCancel: () => this.$emit('onShouldChangeTab', true)
    })
  },

  methods: {
    async onSave() {
      let isInvalid
      this.$refs.formRef.validate(valid => (isInvalid = !valid))

      if (isInvalid) {
        return
      }

      const payload = pick(this.estateClone.transformToDbFormat(), [
        'address',
        'name',
        'id',
        'recordNo',
        'primaryUse'
      ] as (keyof Estate)[])

      await services.estates.update(this.estate['id'], payload)
      this.$emit('reFetch')

      this.unchangedEstate = this.estateClone.clone()
    },

    setNewRequiredField(rules: { [key: string]: { required: boolean } }) {
      this.$refs.formRef.clearValidate()

      entries(rules).forEach(([ruleKey, rule]) => {
        this.validationRules[ruleKey][0].required = rule.required
      })
    },

    validateField(field: string) {
      this.$refs.formRef.validateField(field)
    }
  }
})
</script>

<style lang="scss" scoped>
.form {
  color: #909399;
  display: flex;
  padding: 25px 0;

  &__right {
    flex: 1;
  }

  &__left {
    flex: 1;
    margin-right: 30px;

    .el-select {
      flex: 1;
    }
  }
}

.form-submit {
  padding: 0 30px;
  text-align: left;

  :deep(.el-form-item__content) {
    margin: 0 !important;
  }
}
</style>
