<template>
  <el-table
    :data="tableData"
    style="width: 100%"
    show-summary
    :summar-method="summaryFn"
    :sum-text="$t('finances.operatingCashEndOfYear')"
  >
    <el-table-column
      v-for="col in columns"
      :key="col"
      :label="forFutureYearsPrependF(col)"
      :prop="col"
      :min-width="col === 'name' ? 200 : 0"
    >
    </el-table-column>
  </el-table>
</template>

<script lang="ts">
import services from '@/core/services'
import { defineComponent } from 'vue'
import { normalizeTableData, forFutureYearsPrependF } from '../utils'

export default defineComponent({
  props: ['reports'],
  data() {
    return {
      tableData: [],
      columns: [],
      summaryFn: null
    }
  },

  async mounted() {
    const { operatingCashEndOfYear, ...reports } = this.reports

    const { tableData, columns } = normalizeTableData(reports)

    this.tableData = tableData
    this.columns = columns
    this.summaryFn = () =>
      [this.$t('finances.operatingCashEndOfYear')].concat(operatingCashEndOfYear)
  },

  methods: { forFutureYearsPrependF }
})
</script>

<style lang="scss" scoped></style>
