
import api from '@/core/api'
import { Contact } from '@/core/models'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    id: { type: String, required: true }
  },

  data() {
    return {
      contact: null as Contact
    }
  },

  computed: {
    activeTab: {
      get(): string {
        return this.$route.name as string
      },
      set(name: string) {
        this.$router.push({ name })
      }
    }
  },

  async mounted() {
    await this.fetchContact(this.$props.id as string)
  },

  methods: {
    async fetchContact(id: string) {
      this.contact = await api.contacts.getById(id)
    }
  }
})
