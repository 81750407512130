<template>
  <el-card>
    <el-tabs v-model="activeTab" stretch>
      <el-tab-pane :disabled="!contact" :label="$t('common.details')" name="AddressBookUserInfo" />
      <el-tab-pane :disabled="!contact" :label="$t('common.invoices')" name="AddressBookInvoices" />
    </el-tabs>
    <div>
      <router-view v-if="contact" :contact="contact" @reFetch="fetchContact($props.id)" />

      <div v-else>
        <div class="d-flex p-30">
          <el-skeleton animated style="width: 240px">
            <template #template>
              <el-skeleton-item
                animated
                variant="image"
                class="mb-20"
                style="width: 124px; height: 124px; border-radius: 50%;"
              />
              <el-skeleton-item animated variant="p" style="width: 50%" />
              <el-skeleton-item animated variant="p" style="width: 50%" />
              <el-skeleton-item animated variant="p" style="width: 50%" />
            </template>
          </el-skeleton>
          <el-skeleton animated />
        </div>
        <el-skeleton animated />
      </div>
    </div>
  </el-card>
</template>

<script lang="ts">
import api from '@/core/api'
import { Contact } from '@/core/models'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    id: { type: String, required: true }
  },

  data() {
    return {
      contact: null as Contact
    }
  },

  computed: {
    activeTab: {
      get(): string {
        return this.$route.name as string
      },
      set(name: string) {
        this.$router.push({ name })
      }
    }
  },

  async mounted() {
    await this.fetchContact(this.$props.id as string)
  },

  methods: {
    async fetchContact(id: string) {
      this.contact = await api.contacts.getById(id)
    }
  }
})
</script>
