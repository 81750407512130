import { DATE_FORMAT } from '@/common/util'
import { i18n } from '@/locales'
import { Exclude, Transform, Type } from 'class-transformer'
import { camelCase, every, reject, snakeCase, sumBy } from 'lodash'
import moment from 'moment'
import { BaseModelWrapper } from '../BaseModel.model'
import { DocumentDetailDescription } from './DocumentDetailDescription.model'

export class DocumentDetail extends BaseModelWrapper<DocumentDetail>() {
  @Type(() => DocumentDetailDescription)
  description = [DocumentDetailDescription.create({})]

  number = ''
  tax = 0
  issued = null
  due = null
  periodical = false

  @Transform(({ value }) => (value ? camelCase(value) : value), { toClassOnly: true })
  @Transform(({ value }) => (value ? snakeCase(value) : value), { toPlainOnly: true })
  period: 'weekly' | 'monthly' | 'three_months' | 'yearly'

  get value() {
    return sumBy(this.description, 'value') || 0
  }

  @Exclude({ toPlainOnly: true })
  get balanceDue() {
    return this.value + this.taxAmount
  }

  @Exclude({ toPlainOnly: true })
  get taxAmount() {
    return (this.value * this.tax) / 100 || 0
  }

  @Exclude({ toPlainOnly: true })
  get totalWithTax() {
    return this.value + this.taxAmount || 0
  }

  @Exclude({ toPlainOnly: true })
  get descriptionsAreValid() {
    return every(this.description, 'isValid')
  }

  @Exclude({ toPlainOnly: true })
  get formattedDueDate() {
    return this.handleInvalidDate(moment(this.due).format(DATE_FORMAT))
  }

  @Exclude({ toPlainOnly: true })
  get formattedIssuedDate() {
    return this.handleInvalidDate(moment(this.issued).format(DATE_FORMAT))
  }

  addDescription() {
    this.description.push(DocumentDetailDescription.create({}))
  }

  removeDescription(id: string) {
    this.description = reject(this.description, { id })
  }

  clearDescriptions() {
    this.description = [DocumentDetailDescription.create({})]
  }

  private handleInvalidDate(date: string) {
    return date === 'Invalid date' ? i18n.global.t('common.notSelected') : date
  }
}
