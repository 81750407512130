import { Estate, EstateOwner, ManagingCompanyFee } from '@/core/models'
import { Reminder } from '@/core/models'

import { CRUD } from './CRUD'
import http from './http'

export default class EstatesEntity extends CRUD<Estate, Paginated<Estate[]>> {
  protected Model = Estate

  feeCreate(estateId: string, fee: ManagingCompanyFee) {
    return http.post(`${this.url}/${estateId}/fee`, fee)
  }

  feeDelete(feeId: string, estateId: string) {
    return http.delete(`${this.url}/${estateId}/fee/${feeId}`)
  }

  ownersGet(estateId: string) {
    return http.get(`${this.url}/${estateId}`)
  }

  ownersCreate(estateId: string, owner: EstateOwner) {
    return http.post(`${this.url}/${estateId}/owner`, owner)
  }

  ownersUpdate({ estateId, ownerId }: Record<string, string>, payload: Partial<EstateOwner>) {
    return http.put(`${this.url}/${estateId}/owner/${ownerId}`, payload)
  }

  getReminders(estateId: string) {
    return http
      .get(`${this.url}/${estateId}/reminder`)
      .then(this.createModelInstance<Paginated<Reminder[]>>(Reminder))
  }

  createReminder(estateId: string, payload) {
    return http.post(`${this.url}/${estateId}/reminder`, payload)
  }

  removeReminder(estateId: string, reminderId: string) {
    return http.delete(`${this.url}/${estateId}/reminder/${reminderId}`)
  }
}
