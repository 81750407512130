<template>
  <div id="image-preview" @click="$store.commit('setPreviewImage', null)">
    <i class="el-icon-close"></i>
    <img :src="image" @click.stop />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    image: { type: String, required: true }
  },

  data() {
    return {
      scrollY: null as number
    }
  },

  mounted() {
    this.disableScrolling()
  },

  unmounted() {
    this.enableScrolling()
  },

  methods: {
    disableScrolling() {
      this.scrollY = window.scrollY
      window.scrollTo(0, 0)

      document.body.setAttribute('style', 'overflow:hidden;')
    },

    enableScrolling() {
      window.scrollTo(0, this.scrollY)
      document.body.setAttribute('style', 'overflow:auto;')
    }
  }
})
</script>

<style lang="scss" scoped>
#image-preview {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  min-width: 600px;
  max-height: 700px;
  max-width: 900px;
  cursor: default;
}
.el-icon-close {
  position: absolute;
  color: white;
  top: 30px;
  right: 30px;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    transform: scale(1.1);
  }
}
</style>
