import { i18n } from '@/locales'
import { Contact, User, Estate, Cost, Invoice, Article, ArticleParams } from '@/core/models'
import router from '@/web-app/router'
import store from '@/core/store'
import { Auth0UserProfile } from 'auth0-js'
import { ElNotification } from 'element-plus'
import { ContactGetParams } from './ContactsEntity'
import { CRUD } from './CRUD'
import http from './http'

export default class AccountsEntity extends CRUD<User, Paginated<User[]>> {
  protected Model = User

  async getByEmail(email: string) {
    return http.get<User>(`${this.url}/email/${email}`).then(this.createModelInstance())
  }

  async getArticles(accountId: string, params?: ArticleParams) {
    return http
      .get<Paginated<Article[]>>(`${this.url}/${accountId}/article`, { params })
      .then(this.createModelInstance(Article))
  }

  async getCompanies(accountId: string) {
    return http
      .get<Contact[]>(`${this.url}/${accountId}/companies`)
      .then(this.createModelInstance(Contact))
  }

  getEstates(accountId: string, params?: IPagination): Promise<Paginated<Estate[]>> {
    return http
      .get(`${this.url}/${accountId}/estate`, { params })
      .then(this.createModelInstance(Estate))
  }

  getReports(accountId, params: { report: ReportType; from_year?: number; forward?: number }) {
    return http.get(`${this.url}/${accountId}/report`, { params })
  }

  getContacts(accountId: string, params: ContactGetParams): Promise<Paginated<Contact[]>> {
    return http
      .get(`${this.url}/${accountId}/contact`, { params })
      .then(this.createModelInstance(Contact))
  }

  getInvoices(accountId: string, params?: IPagination): Promise<Paginated<Invoice[]>> {
    return http
      .get(`${this.url}/${accountId}/invoice`, { params })
      .then(this.createModelInstance(Invoice))
  }

  getCosts(accountId: string, params?: IPagination): Promise<Paginated<Cost[]>> {
    return http
      .get(`${this.url}/${accountId}/cost`, { params })
      .then(this.createModelInstance(Cost))
  }

  async getOrCreate(profile: Auth0UserProfile) {
    let user

    try {
      user = await this.getByEmail(profile.email!)
    } catch (e) {
      if (e.response?.status === 402) {
        const { picture, email_verified, email, nickname, name } = profile

        user = await this.create({
          photo: picture,
          isVerified: email_verified,
          email,
          nickname,
          name: name.split(' ')[0],
          surname: name.split(' ')[1]
        })
      } else {
        router.push({ name: 'SignIn' })
        ElNotification({
          type: 'error',
          title: i18n.global.t('common.error'),
          message: e.message
        })
      }
    } finally {
      if (user) {
        store.commit('setUser', user)
      }
    }
  }
}
