import config from './config.json'
import { Auth0Lock } from 'auth0-lock'
import { App } from 'vue'
import { NavigationGuard } from 'vue-router'
import api from '@/core/api'
import store from '@/core/store'

export class LockAuth {
  public isAuthenticated: boolean | null = null

  private lock = new Auth0Lock(config.auth.clientId, config.auth.domain, {
    container: 'loginContainer',
    auth: {
      redirectUrl: window.location.origin,
      // autoParseHash: false,
      responseType: 'token'
    },
    theme: {
      logo: 'https://rentalsmanagement.herokuapp.com/img/logo.180af3b5.svg',
      primaryColor: '#0c9adb'
    },
    languageDictionary: {
      title: '',
      signUpTitle: ''
    }
  })

  async init() {
    return new Promise<void>((resolve, reject) => {
      this.lock.checkSession({}, async (err, authResult) => {
        if (err) {
          this.isAuthenticated = false
          store.commit('setUser', {})
          console.error(err)

          return reject()
        }

        this.lock.getUserInfo(authResult!.accessToken, async (err, profile) => {
          if (err) {
            console.error(err)
            return resolve()
          }

          this.isAuthenticated = true
          await api.accounts.getOrCreate(profile)

          resolve()
        })
      })
    })
  }

  show() {
    this.lock.show()
  }

  signOut() {
    this.lock.logout({ returnTo: window.location.origin })
  }
}

const auth = new LockAuth()

const authPlugin = {
  install: (app: App<Element>) => {
    app.provide('Auth', auth)
    app.config.globalProperties.$auth = auth
  }
}

const routeGuard: NavigationGuard = (to, from, next) => {
  if (auth.isAuthenticated && !from.name && to.hash) {
    next({ path: '/', replace: true })
  } else if (auth.isAuthenticated || to.name === 'SignIn') {
    next()
  } else {
    next({ name: 'SignIn', replace: true })
  }
}

export default {
  auth,
  authPlugin,
  routeGuard
}
