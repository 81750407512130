<template>
  <el-card class="document">
    <div class="flex-between">
      <div class="document__address">
        <div>
          <b>{{ $t('common.from') }}: </b>
          <span class="link">{{ from?.name }}</span>
        </div>
        <div>
          {{ from.address?.street }}
          {{ from.address?.number }}
        </div>
        <div>
          <span v-if="from.address?.city?.name"> {{ from.address?.city?.name }}, </span>
          {{ from.address?.zip }}
        </div>
        <div>
          {{ from.address?.country?.name }}
        </div>
      </div>
      <div class="document__number-balance text-right">
        <div class="label">{{ label }}</div>

        <div>
          {{ $t('common.document') }} No:
          <span class="small-text"> {{ details.number }} </span>
        </div>
        <div>
          {{ $t('finances.balanceDue') }}:
          <span class="small-text text-underline">
            {{ $f.currency(details.balanceDue) }}
          </span>
        </div>
      </div>
    </div>
    <div class="document__bill-info">
      <div>
        <b> {{ $t('finances.billTo') }}: </b>
        <router-link
          :to="{
            name: $store.getters.isWebApp ? 'AddressBookItem' : 'User',
            params: { id: to.id }
          }"
          class="link"
        >
          {{ to.getFullName() }}
        </router-link>
      </div>

      <div class="flex-between">
        <span>
          {{ to?.address?.street }}
          {{ to?.address?.number }}
        </span>
        <span>
          <span>
            {{ $t('common.invoice') }} {{ $t('common.date') }}:
            <span class="small-text">
              {{ details.formattedIssuedDate }}
            </span>
          </span>
        </span>
      </div>
      <div class="flex-between">
        <span>
          <span v-if="to?.address?.city?.name"> {{ to?.address?.city?.name }}, </span>
          {{ to?.address?.zip }}
        </span>
        <span>
          {{ $t('common.due') }} {{ $t('common.date') }}:
          <span class="small-text">
            {{ details.formattedDueDate }}
          </span>
        </span>
      </div>
      <div>{{ to.address?.country?.name }}</div>
    </div>
    <div class="document__supply-place">
      <div v-if="estate">
        <b> {{ $t('messages.placeOfSupply') }}: </b>

        <span v-if="estate?.address" class="small-text">
          {{ estate?.address.toString() }}
        </span>

        <span v-if="estate?.recordNo" class="small-text">
          - {{ $t('form.recordNumber') }}
          {{ estate.recordNo }}
        </span>
      </div>
      <el-table class="w-100" :data="details.description">
        <el-table-column type="index" label="#" width="20" />

        <el-table-column width="150" :label="`${$t('common.item')} & ${$t('common.description')}`">
          <template #default="{ row }">
            <el-tooltip effect="light" placement="left">
              <template #content>
                <div class="tooltip">{{ row.description }}</div>
              </template>
              <span>{{ row.product }} {{ row.description }}</span>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column prop="quantity" width="60" :label="$t('common.qty')" />

        <el-table-column :label="$t('finances.rate')">
          <template #default="{ row }">
            {{ $f.number(row.rate) }}
          </template>
        </el-table-column>

        <el-table-column :label="$t('finances.vat')">
          <template #default="{row}">
            <div class="position-relative">
              <span>{{ $f.number($calculator.percentOf(row.value, details.tax)) }}</span>
              <span class="percentage">{{ details.tax }}%</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column :label="$t('finances.amount')">
          <template #default="{ row }">
            {{ $f.currency(row.value) }}
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="document__footer">
      <div class="flex-between">
        <span>{{ $t('finances.subTotal') }}</span>
        <span>{{ $f.currency(details.value) }}</span>
      </div>
      <div class="flex-between">
        <span> {{ $t('finances.vat') }}({{ details.tax }}%) </span>
        <span>
          {{ $f.currency(details.taxAmount) }}
        </span>
      </div>
      <div class="flex-between">
        <span>{{ $t('finances.total') }}</span>
        <span> {{ $f.currency(details.totalWithTax) }}</span>
      </div>
      <div class="flex-between">
        <span>{{ $t('finances.balanceDue') }}</span>
        <span>{{ $f.currency(details.balanceDue) }}</span>
      </div>
    </div>
  </el-card>
</template>

<script lang="ts">
import { Address, Estate } from '@/core/models'
import { DocumentDetail } from '@/core/models/Documents/DocumentDetail.model'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'Document',
  props: {
    label: {
      type: String,
      required: true
    },
    from: {
      type: Object as PropType<{
        address: Address
        name: string
      }>,
      required: true
    },
    to: {
      type: Object as PropType<{
        address: Address
        id: string
        getFullName(): string
      }>,
      required: true
    },
    estate: {
      type: Object as PropType<Estate>,
      required: false,
      default: () => null
    },
    details: {
      type: Object as PropType<DocumentDetail>,
      required: true
    }
  }
})
</script>

<style lang="scss" scoped>
.percentage {
  position: absolute;
  font-size: 6px;
  transform: translate(1px, 10px);
}

.document {
  box-sizing: border-box;
  width: 60%;

  :deep(.el-card__body) {
    padding: 20px 30px;
  }

  &__address,
  &__number-balance,
  &__bill-info,
  &__supply-place {
    text-align: left;
    margin-top: 40px;
    font-weight: 450;
    color: rgba(0, 0, 0, 0.75);

    .label {
      margin-bottom: 24px;
    }

    & > div {
      margin-bottom: 16px;
      font-size: 16px;
      line-height: 16px;
    }
  }

  &__number-balance {
    & > div:nth-child(2) {
      color: rgba(0, 0, 0, 0.75);
    }
  }

  &__supply-place {
    :deep(tr) {
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
    }

    :deep(tr > th) {
      font-size: 16px;
      background: #409eff;
      color: white;
    }

    :deep(.cell) {
      white-space: nowrap;
    }
  }

  &__footer {
    width: 220px;
    margin-left: auto;
    margin-top: 16px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 450;

    .flex-between {
      padding: 6px 21px 6px 28px;
      min-width: 200px;
      white-space: nowrap;

      span:first-child {
        margin-right: 10px;
      }
    }

    .flex-between:last-child {
      background: rgba(51, 70, 211, 0.05);
    }
  }
}

.small-text {
  font-size: 14px;
  line-height: 14px;
}

.tooltip {
  max-width: 200px;
}
</style>
