
import services from '@/core/services'
import { defineComponent, PropType } from 'vue'
import { useOnChange } from '@/common/hooks'
import { debounce, find, isEmpty } from 'lodash'
import { not } from '@/common/util'
import { Address, City, Country, Region } from '@/core/models'

export default defineComponent({
  name: 'AddressForm',
  props: {
    modelValue: { type: Object as PropType<Address>, required: true }
  },

  emits: ['update:modelValue', 'requiredFieldChanged', 'validateField'],

  setup(props) {
    const onChange = useOnChange(() => props.modelValue)

    return { onChange }
  },

  data() {
    return {
      countries: [] as Country[],
      regions: [] as Region[],
      cities: [] as City[],
      filteredCities: null as null | City[],
      loadingCities: false
    }
  },

  async mounted() {
    this.countries = await services.country.get()

    if (!isEmpty(this.modelValue['region'])) {
      // get cities and regions
      const { alpha3, regionId } = this.modelValue['region']

      const [regions, cities] = await Promise.all([
        services.region.getByCountryCode(alpha3),
        services.city.get({ regionId, alpha3 })
      ])

      this.regions = regions
      this.cities = cities
    }
  },

  methods: {
    async onCountrySelect(alpha3: string) {
      if (alpha3 === this.modelValue['country']?.alpha3) {
        return
      }

      this.regions = []
      this.cities = []

      if (!alpha3) {
        // country was cleared
        this.onChange({ country: {}, region: {}, city: {} })
        this.$emit('requiredFieldChanged', {
          region: { required: false },
          city: { required: false }
        })

        return
      }

      this.onChange({
        country: find(this.countries, { alpha3 }),
        region: {},
        city: {}
      })

      this.regions = await services.region.getByCountryCode(alpha3)
      this.$emit('requiredFieldChanged', {
        region: { required: not(isEmpty(this.regions)) },
        city: { required: true }
      })

      if (isEmpty(this.regions)) {
        this.cities = await services.city.get({ alpha3 })
      }
    },

    async onRegionSelect(regionId: string) {
      if (regionId === this.modelValue['region']?.regionId) {
        return
      }

      if (!regionId) {
        this.onChange({ region: {}, city: {} })
        this.cities = []
        this.$emit('validateField', 'region')
        return
      }

      const region = find(this.regions, { regionId })
      this.onChange({ region, city: {} })

      this.filteredCities = null
      this.cities = await services.city.get({ regionId, alpha3: region.alpha3 })
    },

    async onCitySelect(id: string) {
      if (id === this.modelValue['city']?.id) {
        return
      }

      if (!id) {
        this.$emit('validateField', 'city')
        return this.onChange({ city: {} })
      }

      const city = find(this.cities, { id })
      const region = find(this.regions, { regionId: city?.regionId })

      this.onChange({ city, region: region || {} })

      if (isEmpty(region)) {
        // city has no region so remove region validation
        this.$emit('requiredFieldChanged', {
          region: { required: false }
        })
      }
    },

    onCitySearch: debounce(async function(query: string) {
      if (!query) {
        return
      }

      // @ts-ignore
      this.loadingCities = true
      // @ts-ignore
      this.cities = await services.city.get({
        // @ts-ignore
        alpha3: this.modelValue.country.alpha3,
        name: query
      })

      // @ts-ignore
      this.loadingCities = false
    }, 500),

    onCityFilter: debounce(async function(query: string) {
      if (!query) {
        // @ts-ignore

        this.filteredCities = null
        return
      }

      // @ts-ignore
      this.filteredCities = this.cities.filter(({ name }) =>
        name.toLowerCase().includes(query.toLowerCase())
      )
    }, 500),

    validateFieldOnSelectClose(field: 'region' | 'city', isClosed) {
      if (isClosed) this.$emit('validateField', field)
    },

    getCityLabel(city: City) {
      if (isEmpty(this.cities)) {
        return
      }

      if (!isEmpty(this.modelValue['region'])) {
        return city.name
      }

      const regionOfCity = find(this.regions, { regionId: city.regionId })

      // some cities doesn't have region
      return regionOfCity ? `${city.name}, ${regionOfCity.name}` : city.name
    }
  }
})
