
import AvatarWithUpload from '@/common/components/AvatarWithUpload.vue'
import TelephoneInput from '@/common/components/TelephoneInput.vue'
import { defineComponent } from 'vue'
import { setLocale } from '@/locales'
import services from '@/core/services'
import api from '@/core/api'
import { find, isEmpty, pick } from 'lodash'
import { mapGetters } from 'vuex'
import { User } from '@/core/models'

export default defineComponent({
  components: {
    AvatarWithUpload,
    TelephoneInput
  },

  data() {
    const user: User = User.create(this.$store.getters.user)

    return { user }
  },
  computed: mapGetters(['languages', 'currencies']),

  async mounted() {
    if (isEmpty(this.languages) || isEmpty(this.currencies)) {
      const [currencies, languages] = await Promise.all([api.currencies.get(), api.languages.get()])
      this.currencies = currencies
      this.languages = languages

      this.$store.commit('setLanguages', languages)
      this.$store.commit('setCurrencies', currencies)
    }
  },

  methods: {
    async saveForm() {
      const payloadKeys = [
        'photo',
        'name',
        'surname',
        'nickname',
        'email',
        'phone',
        'language',
        'currency'
      ]

      const payload = pick(this.user.transformToDbFormat(), payloadKeys)

      await services.users.update(payload, this.user.clone())
    },

    changeLanguage(languageId: string) {
      const shortName = find(this.languages, { id: languageId }).short
      setLocale(shortName)
    },

    async onPhotoUpload(file: IUploadedFile) {
      if (this.user.photo) {
        await services.awsImageUploader.remove(this.user.photo)
      }

      const photoName = `${this.$store.getters.user.id}/${Date.now()}${file.name}`

      this.user.photo = await services.awsImageUploader.upload(photoName, file.raw)

      this.saveForm()
    }
  }
})
